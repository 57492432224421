import React, { useState, useEffect, useCallback } from "react";
import style from "./usersList.module.scss";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UsersList } from "./table/table";
import { Box, Typography } from "@mui/material";
import usersApi from "../../api/usersApi";
import { getUsers } from "../../store/users/usersAction";

import SelectableButton from "../../components/buttons/SelectableButton";

export function UsersTable() {
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [filter, setFilter] = useState("");
  const [staticData, setStatic] = useState([]);
  const [resData, setResData] = useState("");
  const [key, setKey] = useState(0);

  const dealerId = localStorage.getItem("dealerId");
  const dispatch = useDispatch();

  const { data, hasNextPage, totalCount, currentPage, totalpageCount } = useSelector((state) => state?.usersReducers?.usersList || []);

  const navigate = useNavigate();
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const [pagination, setPagination] = useState({
    pageSize: 1,
    pageIndex: 1
  });

  const applyFilter = async () => {
    await dispatch(getUsers({ role: resData }))
  };

  const resetFilter = async () => {
    await dispatch(getUsers())
    setFilter("");
    setResData("");
    setKey(prev => prev + 1); // Force re-render
  };

  const handleSearch = useCallback(() => {
    let filtered = data;

    if (searchText) {
      filtered = filtered.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilterData(filtered);
  }, [searchText, data]);

  useEffect(() => {
    dispatch(getUsers({ page: pagination.pageIndex }))
  }, [dispatch, dealerId, pagination.pageIndex])

  useEffect(() => {
    handleSearch();
  }, [searchText, data, handleSearch]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };

  const getAllRoles = async () => {
    try {
      // const res = await usersApi.getAllRoles();
      const res = [];
      console.log('res =>', res)
      setStatic(res?.data)
    } catch (error) {
      console.log(error?.message)
    }
  }

  useEffect(() => {
    getAllRoles();
  }, [])

  const getApi = (id) => {
    const data = staticData.find(item => item?.id === id);
    setResData(data?.role_slug)
  }

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "15px 0",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Users List ({totalCount})
        </Typography>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <SearchBar
            placeholder={"Search User"}
            handleChange={handleChange}
          />
          <SelectableButton
            key={key}
            trailingIcon={R.ic_arrow_down}
            containerStyle={style["selectable-button-container-style"]}
            handleChange={(e) => {
              getApi(e.target.value);
              setFilter(e.target.value);
            }}
            // items={staticData.map(item => ({
            //   label: item.role_name,
            //   value: item?.id
            // }))}
            hint={"All Roles"}
            selectedItems={filter}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Filter"}
            fontSize={"1rem"}
            onClick={applyFilter}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />
        </div>
        <div className={style["action-buttons-wrapper"]}>
          <PrimaryButton
            containerStyle={style["add-blogs-button"]}
            leadingIcon={R.ic_add_white}
            name={"Add New User"}
            fontSize={"1rem"}
            linkTo={"/create-user"}
            onClick={() => navigate("/create-user")}
          />
        </div>
      </div>
      <UsersList
        value={filterData}
        totalCount={totalCount}
      />
      <div className={style["pagination-action-wrapper"]}>
        <button onClick={handlePreviousPage} disabled={currentPage == 1}>
          Back
        </button>
        <p>
          Page {currentPage} of {totalpageCount}
        </p>
        <button onClick={handleNextPage} disabled={!hasNextPage}>
          Next
        </button>
      </div>
    </div>
  );
}