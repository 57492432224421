import Users from "../../api/usersApi";

export const saveUser = (userData) => {
    return async (dispatch) => {
        try {
            const response = await Users.saveUser(userData);
            dispatch({
                type: "SAVE_USER_SUCCESS",
                payload: response?.data,
            });
            return response?.data;
        } catch (error) {
            dispatch({
                type: "SAVE_USER_FAILURE",
                payload: error,
            });
            throw error;
        }
    };
};

export const getUsers = (params) => {
    return async (dispatch) => {
        try {
            const response = await Users.getUsers(params);
            dispatch({
                type: "GET_USERS_SUCCESS",
                payload: response?.data?.data,
            });
            console.log("response=>>>>>>>>>>>>>>>>>", response?.data?.data);
            return response?.data?.data;
        } catch (error) {
            dispatch({
                type: "GET_USERS_FAILURE",
                payload: error,
            });
            throw error;
        }
    };
};

export const getAllRoles = () => {
    return async (dispatch) => {
        try {
            const response = await Users.getAllRoles();
            dispatch({
                type: "GET_ROLES_SUCCESS",
                payload: response?.data,
            });
            return response?.data;
        } catch (error) {
            dispatch({
                type: "GET_ROLES_FAILURE",
                payload: error,
            });
            throw error;
        }
    };
};

export const removeUser = (userId) => {
    return async (dispatch) => {
        try {
            const response = await Users.removeUser(userId);
            dispatch({
                type: "REMOVE_USER_SUCCESS",
                payload: userId,
            });
            return response?.data;
        } catch (error) {
            dispatch({
                type: "REMOVE_USER_FAILURE",
                payload: error,
            });
            throw error;
        }
    };
};