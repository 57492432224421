import { useEffect, useState } from "react";
import { testimonialsApi } from "../../api/testimonials";

export const UserDealerPermission = (service_id) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPermission = async () => {
            try {
                const res = await testimonialsApi.getDealerPermission();
                setData(res);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchPermission();
    }, [service_id]);

    const checkStatusPermisssion = data.find(item => item?.service_id == service_id);

    return { checkStatusPermisssion, loading, error };
};

