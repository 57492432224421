import style from "../../user-management/usersList.module.scss";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
// import usersApi from "../../../api/usersApi";
import { showToast } from "../../../components/toaster/toaster";
import { getUsers } from "../../../store/users/usersAction";
import { useDispatch } from "react-redux";
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
import { useState } from "react";

export function UsersList({ value = [] }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dialogBox, setDialogBox] = useState({
        id: "",
        titleMessage: "",
        confirmMessage: "",
        handleConfirm: () => { },
    });
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const onEditClick = (data) => {
        navigate("/create-user", { state: { edited: data } })
    }

    const handleDelete = async (id) => {
        try {
            const res = "delete";
            console.log("res =>", res);
            showToast("warning", res?.message);
            await dispatch(getUsers())
            handleClose();
        } catch (error) {
            console.log("err =>", error)
        }
    }

    const onDelete = async (id) => {
        setDialogBox({
            id: id,
            titleMessage: "! Warning",
            confirmMessage: "Are you sure you want to delete this user?",
            handleConfirm: () => handleDelete(id),
        });
        setOpen(true);
    }

    const ActionButtons = ({ data }) => {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => onEditClick(data)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                    <IconButton onClick={() => onDelete(data.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        );
    };

    return (
        <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
        >
            <TableContainer
                component={Paper}
                sx={{ marginTop: '20px' }}
            >
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: "#f5f5f5",
                            "& .MuiTableCell-root": {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Mobile</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Role</TableCell>
                            <TableCell align="left">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value?.map((user, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: "#f5f5f5",
                                    },
                                }}
                            >
                                <TableCell sx={{ ml: 2, width: "20%" }}>
                                    {user?.firstname || "N/A"}
                                </TableCell>
                                <TableCell sx={{ ml: 2, width: "10%" }}>
                                    {user?.phone || "N/A"}
                                </TableCell>
                                <TableCell style={{ width: "30%" }}>
                                    {user?.email || "N/A"}
                                </TableCell>
                                <TableCell style={{ width: "20%" }}>
                                    {user?.user_role_id || "N/A"}
                                </TableCell>
                                <TableCell>
                                    <ActionButtons data={user} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomizedDialogs
                open={open}
                handleClose={handleClose}
                rowSkuId={dialogBox.id}
                titleMessage={dialogBox.titleMessage}
                confirmMessage={dialogBox.confirmMessage}
                handleConfirm={dialogBox.handleConfirm}
            />
        </div>
    );
}