import {
  call,
  customerCalls,
  serviceHistoryCall,
  dealerappUrl,
  selerLead,
  csvList,
  saveCsvList,
  preferenceCall,
  saveCsvFileData,
  updateCsvStatus,
  brandcall,
  dealerfetchCall,
  dealerSubUser,
  enquiryList,
} from ".";

class InspectionService {
  getDSList(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/list-ds-requests",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getUsersDropdown(params) {
    const token = "d9b624d3-ab29-4c2c-b0e5-abcff384aeb6";
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSubUser(
            "get",
            "dealer-app-auth/get-user",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDSServiceList() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/doorsteps-services-lists",
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDSStatusFilter(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "get",
            "dealer-app/ds-service/get-ds-statuses",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  editDSRequest(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "put",
            "dealer-app/ds-service/edit-ds-request",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
  createDSRequest(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await serviceHistoryCall(
            "post",
            "dealer-app/ds-service/create-ds-request",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const insecptionService = new InspectionService();
