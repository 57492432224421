import React, { Fragment, useEffect, useState, useRef } from "react";
import style from "./editCarDetails.module.scss";
import ImageCard from "../add-new-car/components/image-card/ImageCard";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import SelectableButton from "../../components/buttons/SelectableButton";
import InputField from "../../components/input-field/InputField";
import BackButton from "../../components/buttons/BackButton";
import { postData } from "../../components/services/apiServices";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { publicApi } from "../../api/publicApi";
import {
  editCarrDetails,
  getCarList,
  enhancedInventryImages,
  fetchEnhancedInventryImages,
} from "../../store/car/carAction";
import { carApi } from "../../api/carApi";
import MultiSelect from "../../components/buttons/multiSelect";
import DateField from "../../components/input-field/dateField";
import moment from "moment";
import { toast } from "react-toastify";
import SecondaryHeading from "../../components/primary-heading/SecondaryHeading";
import { Box, Grid, Typography } from "@mui/material";
import { ToggleSwitch } from "../../components/ToggleSwitch";
import { showToast } from "../../components/toaster/toaster";
import ConfirmModel from "../../components/confirmModel/confirmModel";

export default function UpdateCarImages() {
  const [loaderTrue, setloaderTrue] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const [alreadyImg, setalreadyImg] = useState([]);
  const [flags, setFlags] = useState([]);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [imageTypeEnum, setimageTypeEnum] = useState([]);
  const [stateData, setStateData] = useState(null);
  const [timer, setTimer] = useState(null);
  const interval = useRef(null);

  // console.info("imageTypeEnum", imageTypeEnum, imagesData, stateData);

  const [dbImages, setDbImages] = useState([]);

  const [img, setImg] = useState();

  const [img_arr, setImgArr] = useState(
    stateData?.data?.img_arr?.length > 0 ? stateData?.data?.img_arr : []
  );

  const [enhanced_img_arr, setEnhancedImgArr] = useState(
    stateData?.data?.enhanced_img_arr?.length > 0
      ? stateData?.data?.enhanced_img_arr
      : []
  );
  const brands = async () => {
    try {
      // const res = await publicApi.brandList();
      // setBrandList(res?.data);
      const res1 = await carApi.imgTypeEnumList();

      setimageTypeEnum(res1);
    } catch (err) {
      console.log(err);
    }
  };

  const [formData, setFormData] = useState({
    vehicleNo: stateData?.data?.vehicle_reg_no || "",
    images: stateData?.data?.images || null,
  });

  const fetchCarImages = () => {
    if (state) {
      getCarList({
        dealerId: Number(localStorage.getItem("dealerId")),
        sku_id: [state],
      }).then((res) => {
        const filteredData = res?.payload?.data?.filter(
          (item) => item.carSkuId === state
        )?.[0];

        setStateData({
          data: filteredData,
        });
        
        setShowEnhancedImage(
          filteredData?.imagesDetails?.some((img) => img.is_enhanced_image) ||
            false
        );
      });
    }
  };
  useEffect(() => {
    fetchCarImages();
  }, []);

  useEffect(() => {
    brands();
    if (stateData && stateData.data) {
      const initialFlags = [];
      setFlags(initialFlags);
    }
  }, [stateData, imagesData]);

  useEffect(() => {
    const img = stateData?.data?.imagesDetails?.find(
      (item) => item?.enhanced_status == "Pending"
    );

    if (img && moment().diff(moment(new Date(img?.updated_date)), "days") < 3) {
      setTimer(
        moment().diff(moment(new Date(img?.updated_date)), "seconds") || 0
      );

      interval.current = setInterval(() => {
        setTimer((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      if (interval?.current) {
        clearInterval(interval?.current);
      }
    };
  }, [stateData?.data?.imagesDetails]);

  // useEffect(() => {
  //   if(dbImages.length){
  //     const result = {
  //       carSkuId: stateData?.data?.carSkuId,
  //       images: dbImages.map((image) => ({
  //         url: image.url,
  //         tagId: image.tagId
  //       }))
  //     };
  //     const response = carApi.saveUploadCarImages(result);
  //     if(response){
  //       toast.success("Image upload successfully");
  //       navigate("/car-management");
  //     } else {
  //       toast.error("Error uploading image");
  //     }
  //   }
  // }, [dbImages])

  useEffect(() => {
    if (dbImages.length) {
      const result = {
        carSkuId: stateData?.data?.carSkuId,
        images: dbImages.map((image) => ({
          url: image.url,
          tagId: image.tagId,
        })),
      };

      // Add a delay before making the API call
      const delayApiCall = setTimeout(async () => {
        try {
          const response = await carApi.saveUploadCarImages(result);
          if (response) {
            toast.success("Image uploaded successfully");
            navigate("/car-management");
          } else {
            toast.error("Error uploading image");
          }
        } catch (error) {
          toast.error("Error uploading image");
          console.error("Error:", error);
        }
      }, 2000); // 2-second delay

      // Cleanup the timeout if dbImages changes before the timeout completes
      return () => clearTimeout(delayApiCall);
    }
  }, [dbImages, navigate, stateData?.data?.carSkuId]);

  const images = {};
  const flagValue = [];
  flags.forEach((item) => {
    flagValue.push(item.value);
  });

  imagesData.forEach((item) => {
    images[item.apikey] = item.file;
  });

  const updateCarImage = async (e) => {
    console.log("called", imagesData);
    setLoading(true);
    setloaderTrue(true);
    e.preventDefault();
    let updateImagePath = [];

    // Check if imagesData exists and has at least one item
    if (imagesData && imagesData.length > 0) {
      console.log("called2");

      // Loop over each item in imagesData and make API call
      await Promise.all(
        imagesData.map(async (item) => {
          // Ensure valid file exists before creating FormData
          if (item?.file) {
            const formData = new FormData(); // Create new FormData for each file
            formData.append("file", item.file); // Append the file
            formData.append("tagId", item?.tagId); // Append additional data
            formData.append("carId", item?.carId); // Append additional data

            try {
              const response = await carApi.uploadCarImages(formData);
              if (response) {
                updateImagePath.push(response);
                setDbImages((prevImages) => [...prevImages, response]);
              }
            } catch (error) {
              console.error("Error uploading image:", error);
            }
          }
        })
      );
    } else {
      console.log("No images to upload");
      navigate("/car-management");
    }

    setLoading(false);
    setloaderTrue(false);
  };

  const updateStateImage = (dbImageId) => {
    const updatedImagesDetails = stateData.data.imagesDetails.filter(
      (img) => img.imageId != dbImageId
    );

    setStateData((prevData) => ({
      ...prevData,
      data: {
        ...prevData.data,
        imagesDetails: updatedImagesDetails,
      },
    }));

    // setImagesData([...imagesData, []]);
  };

  const dispatch = useDispatch();

  const [confirmEnhancedImg, setConfirmEnhancedImg] = useState(false);
  const [showEnhancedImage, setShowEnhancedImage] = useState(false);

  const handleFetchEnhancedImage = async () => {
    setLoading(true);
    dispatch(
      fetchEnhancedInventryImages({
        carId: stateData?.data?.carId,
      })
    )
      .then((res) => {
        setLoading(false);
        console.log(res?.data, "Fetch Enhanced Image Res");
        setImgArr(res?.data?.img_arr);
        setEnhancedImgArr(res?.data?.data?.enhanced_img_arr);
        showToast("success", "Enhanced images fetched successfully");
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setLoading(false);
      });
  };

  const handleEnhancedImage = async () => {
    if (img_arr?.length < 0) {
      showToast("error", "Please upload at least one image");
      return;
    }
    setLoading(true);
    dispatch(
      enhancedInventryImages({
        carId: stateData?.data?.carId,
      })
    )
      .then((res) => {
        setLoading(false);
        console.log(res?.data, "Enhanced Image Res");
        setImgArr(res?.data?.img_arr);
        showToast("success", "Enhanced image request created successfully");
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setLoading(false);
      });
  };

  return (
    <div className={style["container"]}>
      {loaderTrue ? (
        <div class="loading">
          <div class="loader"></div>
        </div>
      ) : null}

      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

          alignItems: "center",

          padding: "15px ",
        }}
      >
        <BackButton
          onClick={() => {
            navigate("/car-management");
          }}
        />
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          {`${stateData?.data?.reg_no || ""} - ${stateData?.data?.make || ""} ${
            stateData?.data?.modalName || ""
          } ${stateData?.data?.varientName || ""} ${
            stateData?.data?.carSkuId ? `|| ${stateData?.data?.carSkuId}` : ""
          }`}
        </Typography>

        <Box >
          {stateData?.data?.imagesDetails?.some(
            (x) => x?.spyne_sku_id && x?.enhanced_status == "Pending"
          ) && (
            <PrimaryButton
              containerStyle={`px-3 mx-3 ${
                showEnhancedImage ? "opacity-50" : ""
              } ${style["add-new-car-button-container-style"]}`}
              leadingIcon={R.ic_clock}
              name={
                moment().diff(
                  moment(
                    new Date(
                      stateData?.data?.imagesDetails?.find(
                        (item) => item?.enhanced_status == "Pending"
                      )?.updated_date
                    )
                  ),
                  "days"
                ) >= 3 ? (
                  <>
                    {moment().diff(
                      moment(
                        new Date(
                          stateData?.data?.imagesDetails?.find(
                            (item) => item?.enhanced_status == "Pending"
                          )?.updated_date
                        )
                      ),
                      "days"
                    )}{" "}
                    days ago
                  </>
                ) : (
                  <>
                    {Math.floor(timer / (60 * 60)) > 9
                      ? Math.floor(timer / (60 * 60))
                      : "0" + Math.floor(timer / (60 * 60)) || "00"}
                    {" : "}
                    {Math.floor((timer / 60) % 60) > 9
                      ? Math.floor((timer / 60) % 60)
                      : "0" + Math.floor((timer / 60) % 60) || "00"}
                    {" : "}
                    {Math.floor(timer % 60) > 9
                      ? Math.floor(timer % 60)
                      : "0" + Math.floor(timer % 60) || "00"}
                  </>
                )
              }
              fontSize={"1rem"}
              onClick={handleFetchEnhancedImage}
              disabled={showEnhancedImage}
            />
          )}
        </Box>
        <Box sx={{ px: 1.5 }}>
          {stateData?.data?.imagesDetails?.some(
            (x) => x?.spyne_sku_id && x?.enhanced_status == "Pending"
          ) && (
            <PrimaryButton
              containerStyle={`px-3 mx-3 ${
                showEnhancedImage ? "opacity-50" : ""
              } ${style["add-new-car-button-container-style"]}`}
              leadingIcon={R.ic_fetch}
              name={"Fetch"}
              fontSize={"1rem"}
              onClick={handleFetchEnhancedImage}
              disabled={showEnhancedImage}
            />
          )}
        </Box>
        <Box sx={{ px: 1.5 }}>
          <PrimaryButton
            containerStyle={`px-3 ${
              stateData?.data?.imagesDetails?.some(
                (x) => x?.spyne_sku_id && x?.enhanced_status == "Pending"
              )
                ? "opacity-50"
                : ""
            } ${style["add-new-car-button-container-style"]}`}
            leadingIcon={R.ic_refresh}
            name={"Remove Background"}
            fontSize={"1rem"}
            onClick={() => setConfirmEnhancedImg(true)}
            disabled={
              stateData?.data?.imagesDetails?.some(
                (x) => x?.spyne_sku_id && x?.enhanced_status == "Pending"
              ) || showEnhancedImage
            }
          />
        </Box>
        <Box>
          <div className="d-flex justify-content-end my-2 align-items-center">
            <p className="mx-2">Show Enhanced Images</p>
            <ToggleSwitch
              enabled={showEnhancedImage}
              onChange={(value) => {
                setShowEnhancedImage(value);
              }}
              className={style["toggle-action"]}
            />
          </div>
        </Box>
      </Box>

      <div className={style["wrapper"]}>
        <div className={style["vehicle-images-container"]}>
          {/* <PrimaryHeading
            title={`${stateData?.data?.make || ""} 
          ${stateData?.data?.modalName ? stateData?.data?.modalName : ""} 
          ${stateData?.data?.varientName ? stateData?.data?.varientName : ""} 
          ${
            stateData?.data?.carSkuId ? `|| ${stateData?.data?.carSkuId}` : ""
          }`}
            fontSize={"1.2rem"}
            fontWeight={500}
          /> */}

          <Grid container spacing={2}>
            {imageTypeEnum &&
              imageTypeEnum.length > 0 &&
              imageTypeEnum.map((eachImageType, index) => {
                const onIndex = [
                  { idx: 0, title: "Exterior" },
                  { idx: 8, title: "Interior" },
                  { idx: 15, title: "Others" },
                ];
                return (
                  <Fragment key={eachImageType.id}>
                    {onIndex.map((items) => (
                      <Fragment key={items.idx}>
                        {items.idx === index && (
                          <Grid item xs={12}>
                            <SecondaryHeading
                              title={items.title}
                              fontSize={"1.5rem"}
                              fontWeight={500}
                            />
                          </Grid>
                        )}
                      </Fragment>
                    ))}

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      {showEnhancedImage ? (
                        <ImageCard
                          text={`${eachImageType.name}`}
                          apiKey={eachImageType.slug}
                          imagesData={imagesData}
                          setImagesData={setImagesData}
                          previewImage={alreadyImg}
                          imagePath={eachImageType?.image_path}
                          dbImagesData={
                            stateData?.data?.imagesDetails.find(
                              (item) =>
                                +item?.tagId === eachImageType?.id &&
                                item.is_enhanced_image
                            )?.imgUrls
                          }
                          dbImageId={Number(
                            stateData?.data?.imagesDetails.find(
                              (item) => +item?.tagId === eachImageType?.id
                            )?.imageId
                          )}
                          tagIds={`${eachImageType.id}`}
                          carId={Number(stateData?.data.carId)}
                          carSkuId={stateData?.data?.carSkuId}
                          updateStateImage={updateStateImage}
                        showEnhancedImage={showEnhancedImage}

                        />
                      ) : (
                        <ImageCard
                          text={`${eachImageType.name}`}
                          apiKey={eachImageType.slug}
                          imagesData={imagesData}
                          setImagesData={setImagesData}
                          previewImage={alreadyImg}
                          imagePath={eachImageType?.image_path}
                          dbImagesData={
                            stateData?.data?.imagesDetails.find(
                              (item) =>
                                +item?.tagId === eachImageType?.id &&
                                !item.is_enhanced_image
                            )?.imgUrls
                          }
                          dbImageId={Number(
                            stateData?.data?.imagesDetails.find(
                              (item) => +item?.tagId === eachImageType?.id
                            )?.imageId
                          )}
                          tagIds={`${eachImageType.id}`}
                          carId={Number(stateData?.data.carId)}
                          carSkuId={stateData?.data?.carSkuId}
                          updateStateImage={updateStateImage}
                          isEnhancedProcess={stateData?.data?.imagesDetails?.some(
                            (x) =>
                              x?.spyne_sku_id && x?.enhanced_status == "Pending"
                          )}
                        />
                      )}
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>

          <div
            className={style["submit-btn"]}
            style={{ marginBottom: "100px" }}
          >
            <PrimaryButton
              name={loading ? "Loading..." : "Save"}
              fontSize={"1rem"}
              containerStyle={style["primary-button-container-style"]}
              onClick={updateCarImage}
            />
          </div>

          {confirmEnhancedImg && (
            <ConfirmModel
              heading="Remove Background"
              message="Are you sure you want to remove the background of these images?"
              onSubmit={() => {
                handleEnhancedImage();
                setConfirmEnhancedImg(false);
              }}
              onClose={() => {
                setConfirmEnhancedImg(false);
              }}
              submitText="Yes"
              cancelText="No"
            />
          )}
        </div>
      </div>
    </div>
  );
}
