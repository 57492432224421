import style from "../../buyer-lead/buyerLead.module.scss";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { Pagination, Stack } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import daynamicpage from "../../../api/daynamicpage";
import { showToast } from "../../../components/toaster/toaster";
import { getFaqsPages } from "../../../store/faqs/faqsAction";
import { useDispatch } from "react-redux";
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
import { useState } from "react";
import { saveFaqsPages } from "../../../store/faqs/faqsAction";
import { ROLE_ID } from "../../../config";

export function FaqsList({ value, hasPermission }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [dialogBox, setDialogBox] = useState({
        id: "",
        titleMessage: "",
        confirmMessage: "",
        handleConfirm: () => { },
    });
    const [open, setOpen] = useState(false);
    const [orderData, setOrderData] = useState({});
    const handleClose = () => {
        setOpen(false);
    };

    const onEditClick = (data) => {
        navigate("/create-faqs", { state: { edited: data } })
    }


    const saveOrder = async (e, id) => {
        e.preventDefault();
        const finalOrder = orderData[id]?.order?.trim() || value.find(car => car.id === id)?.display_order;

        if (!finalOrder) {
            showToast("error", "Order cannot be empty.");
            return;
        }

        const payload = {
            display_order: +finalOrder,
            id: id
        };

        let res = await dispatch(saveFaqsPages(payload));
        console.log("response =>", res?.data);
        showToast("success", res?.data?.message);
        setOrderData(prevState => ({
            ...prevState,
            [id]: { order: finalOrder }
        }));

    };

    const handleOrderChange = (e, id) => {
        const newOrder = e.target.value;
        setOrderData(prevState => ({
            ...prevState,
            [id]: { order: newOrder }
        }));
    };


    const handleDelete = async (id) => {
        try {
            const res = await daynamicpage.removePage({ id: id });
            console.log("res =>", res);
            showToast("warning", res?.message);
            await dispatch(getFaqsPages())
            handleClose();
        } catch (error) {
            console.log("err =>", error)
        }
    }

    const onDelete = async (id) => {
        setDialogBox({
            id: id,
            titleMessage: "! Warning",
            confirmMessage: "Are you sure you want to delete this FAQs",
            handleConfirm: () => handleDelete(id),
        });
        setOpen(true);

    }


    const ActionButtons = ({ data }) => {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => onEditClick(data)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                    <IconButton onClick={() => onDelete(data.id)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Stack>
        );
    };


    return (
        <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
        >
            <TableContainer
                component={Paper}
                sx={{ marginTop: '20px' }}
            >
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: "#f5f5f5",
                            "& .MuiTableCell-root": {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell align="left">Page Id</TableCell>
                            <TableCell align="left">FAQ Question</TableCell>
                            <TableCell align="left">Page Name</TableCell>
                            <TableCell align="left">Page Order</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {value?.map((car, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: "#f5f5f5",
                                    },
                                }}
                            >
                                <TableCell align="left">
                                    {car?.id || "N/A"}
                                </TableCell>
                                <TableCell align="left">
                                    {car?.question ? (car.question.length > 50 ? car.question.substring(0, 50) + "..." : car.question) : "N/A"}
                                </TableCell>
                                <TableCell align="left" >
                                    {car?.page_name || "N/A"}
                                </TableCell>
                                <TableCell align="left">
                                    <form onSubmit={(e) => saveOrder(e, car?.id)}>
                                        <input
                                            type="text"
                                            style={{ width: "100px" }}
                                            value={orderData[car?.id]?.order ?? car?.display_order ?? ""}
                                            name="display_order"
                                            onChange={(e) => handleOrderChange(e, car?.id)}
                                        />
                                        <input type="submit" value="Save" />
                                    </form>
                                </TableCell>
                                {(hasPermission("Edit") || localStorage.getItem("roleId") == ROLE_ID) && (
                                    <TableCell align="right" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <ActionButtons data={car} />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <CustomizedDialogs
                open={open}
                handleClose={handleClose}
                rowSkuId={dialogBox.id}
                titleMessage={dialogBox.titleMessage}
                confirmMessage={dialogBox.confirmMessage}
                handleConfirm={dialogBox.handleConfirm}
            />

        </div>
    );
}
