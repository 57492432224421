import {
    Grid,
    Box,
    Button,
    Typography,
    TextField,
    Link,
    IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/buttons/BackButton";
import JoditEditor from "jodit-react";
import { Formik, Form } from "formik";
import InputField from "../../../components/input-field/InputField";
import style from "./style.module.scss";
import MultiSelect from "../../../components/buttons/multiSelect";
import { useEffect, useRef, useState } from "react";
import { carApi } from "../../../api/carApi";
import { useDispatch, useSelector } from "react-redux";
import { saveDynamicPage } from "../../../store/faqs/faqsAction";
import daynamicpage from "../../../api/daynamicpage";
import { ROLE_ID } from "../../../config";
import usersApi from "../../../api/usersApi";
import { UserDealerPermission } from "../../../components/permissions";

export function CreateDaynamicPages() {
    const dispatch = useDispatch();
    const [state, setState] = useState([]);
    const [city, setCity] = useState([]);
    const [data, setData] = useState([]);
    const [permission, sePermission] = useState([]);
    const location = useLocation();
    const { edited } = location.state || {};
    const { checkStatusPermisssion, error } = UserDealerPermission(20);
    const findData = permission?.filter(item => item?.slug === "dynamic_pages");


    const initialValues = {
        id: edited?.id || null,
        page_id: edited?.page_id || 9,
        title_slug: edited?.title_slug || "",
        title: edited?.title || "",
        content: edited?.content || "",
        city_id: edited?.city_id || "",
        state_id: edited?.state_id || "",
        status: edited?.status || "1"
    };

    const addFaqs = (values) => {
        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );
        dispatch(saveDynamicPage(filteredValues))
            .then((res) => {
                navigate("/daynamic-page-list")
            })
            .catch((error) => {
                console.log("Error =>", error);
            });
    };
    const editor = useRef(null);
    const navigate = useNavigate();


    const getState = async () => {
        try {
            const res = await carApi.stateList();
            setState(res?.data);
            if (edited?.state_id) {
                getCity(edited?.state_id);
            };
        } catch (error) {
            console.log(error);
        }
    }

    const getCity = async (id) => {
        try {
            const res = await carApi.cityList(id);
            setCity(res?.data)
        } catch (error) {
            console.log(error);
        }
    }

    const getAllPages = async () => {
        try {
            const res = await daynamicpage.getAllPages();
            console.log('res =>', res)
            setData(res?.data)
        } catch (error) {
            console.log(error?.message)
        }
    }

    useEffect(() => {
        getState();
        getAllPages();
    }, [])

    const formateSpacesWithDash = (str) => {
        if (!str) return "";
        return str.trim().replace(/\s+/g, "-").toLowerCase();
    };

    const hasPermission = (action) => {
        return findData?.some((perm) => perm?.actions?.split(",").includes(action));
    };

    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }
    const res = async () => {
        try {
            const res = await usersApi.permissionRoles();
            sePermission(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        res();
    }, []);


    return (
        <>
            {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
                <div style={{ padding: '10px' }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            marginBottom: "20px",
                            padding: "15px ",
                        }}
                    >
                        <BackButton
                            onClick={() => {
                                navigate("/daynamic-page-list");
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: 600, px: 2 }}
                        >
                            {edited?.id ? "Edit Pages" : "Add Pages"}
                        </Typography>
                    </Box>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={addFaqs}

                    >
                        {({
                            values,
                            errors,
                            touched,
                            onChange,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <Form>
                                <div className={style['header-wrapper']}>
                                    <div>
                                        <div className={style["first-heading"]}>
                                            <div className={style['select-wraapper']}>
                                                <label>Title</label>
                                                <div className={style['sel-wrapper']}>
                                                    <InputField
                                                        type={"text"}
                                                        name="title"
                                                        handleChange={(e) => {
                                                            const newvalue = e.target.value;
                                                            setFieldValue("title", newvalue)
                                                            setFieldValue("title_slug", formateSpacesWithDash(newvalue));
                                                        }}
                                                        value={values.title}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>

                                            <div className={style['select-wraapper']}>
                                                <label>Slugs</label>
                                                <div className={style['sel-wrapper']}>
                                                    <InputField
                                                        type={"text"}
                                                        name="title_slug"
                                                        value={values.title_slug}
                                                        onBlur={handleBlur}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className={style["first-heading"]}>
                                            <div className={style['select-wraapper']}>
                                                <label>State</label>
                                                <div className={style['sel-wrapper']}>
                                                    <MultiSelect
                                                        selectedItems={values.state_id}
                                                        handleChange={(e) => {
                                                            getCity(e.value);
                                                            setFieldValue("state_id", e.value);
                                                        }}
                                                        items={state?.map(item => ({
                                                            label: item?.stateName,
                                                            value: item?.stateListId
                                                        }))}
                                                        hint={"Select state"}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className={style['select-wraapper']}>
                                                <label>Parent page</label>
                                                <div className={style['sel-wrapper']}>
                                                    <MultiSelect
                                                        selectedItems={values.page_id}
                                                        handleChange={(e) => {
                                                            setFieldValue("page_id", e.value);
                                                        }}
                                                        items={data?.map(item => ({
                                                            label: item?.page_name,
                                                            value: item?.id
                                                        }))}
                                                        hint={"Select page"}
                                                        isSearchable={true}
                                                    />
                                                </div>
                                            </div>

                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>City</label>
                                            <div className={style['sel-wrapper']}>
                                                <MultiSelect
                                                    selectedItems={values.city_id}
                                                    handleChange={(e) => {
                                                        setFieldValue("city_id", e.value);
                                                    }}
                                                    items={city?.map(item => ({
                                                        label: item?.cityName,
                                                        value: item?.city_id
                                                    }))}
                                                    hint={"Select city"}
                                                    isSearchable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style['input-wrapper']}>
                                        <label className={style['ans']}>Content</label>
                                        <JoditEditor
                                            ref={editor}
                                            name="descriptions"
                                            value={values.content}
                                            onBlur={(newContent) =>
                                                setFieldValue("content", newContent)
                                            }
                                            config={{
                                                height: 400,
                                                maxHeight: 600,
                                                disablePlugins: "paste"
                                            }}

                                        />
                                    </div>
                                    <div className={style['action-wrapper']}>
                                        <Button
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={style["form-published"]}
                                        >
                                            submit
                                        </Button>

                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </div >
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>

            )}
        </>
    )
}
