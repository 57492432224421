import {
    Grid,
    Box,
    Button,
    Typography,
    TextField,
    Link,
    IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/buttons/BackButton";
import JoditEditor from "jodit-react";
import { Formik, Form } from "formik";
import InputField from "../../../components/input-field/InputField";
import style from "./style.module.scss";
import MultiSelect from "../../../components/buttons/multiSelect";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveFaqsPages } from "../../../store/faqs/faqsAction";
import daynamicpage from "../../../api/daynamicpage";
import usersApi from "../../../api/usersApi";
import { UserDealerPermission } from "../../../components/permissions";

export function CreateFaqs() {
    const [permission, setPermission] = useState([]);
    const findData = permission?.filter(item => item?.slug === "faqs");
    const dispatch = useDispatch();
    const location = useLocation();
    const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);

    const { edited } = location.state || {};
    const initialValues = {
        id: edited?.id || null,
        question: edited?.question || "",
        answer: edited?.answer || "",
        page_id: edited?.page_id || 9,
        status: '1'
    }
    const addFaqs = (values) => {
        const filteredValues = Object.fromEntries(
            Object.entries(values).filter(([key, value]) => value !== undefined && value !== null && value !== "")
        );
        dispatch(saveFaqsPages(filteredValues)).then((res) => {
            navigate("/faqs-listing")
        }).catch((err) => {
            console.log("error", err)
        })
    }
    const editor = useRef(null);
    const navigate = useNavigate();
    const [data, setData] = useState([]);


    const getAllPages = async () => {
        try {
            const res = await daynamicpage.getAllPages();
            setData(res?.data)
        } catch (error) {
            console.log(error?.message)
        }
    }

    useEffect(() => {
        getAllPages();
    }, [])


    const hasPermission = (action) => {
        return findData?.some((perm) => perm?.actions?.split(",").includes(action));
    }

    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }
    const res = async () => {
        try {
            const res = await usersApi.permissionRoles();
            setPermission(res)
        } catch (error) {
            console.log(error?.message)
        }
    }
    useEffect(() => {
        res()
    }, [])

    return (
        <>
            {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == 57 && checkStatus("active"))) ? (
                <div style={{ padding: "8px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            padding: "15px ",
                            marginBottom: "20px",
                        }}
                    >
                        <BackButton
                            onClick={() => {
                                navigate("/faqs-listing");
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: 600, px: 2 }}
                        >
                            {edited?.id ? "Edit Faqs" : "Add Faqs"}
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={addFaqs}

                    >
                        {({
                            values,
                            errors,
                            touched,
                            onChange,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                            isSubmitting,
                        }) => (
                            <Form>
                                <div className={style['header-wrapper']}>
                                    <div className={style["first-heading"]}>
                                        <div className={style['select-wraapper']}>
                                            <label>Question</label>
                                            <div className={style['sel-wrapper']}>
                                                <InputField
                                                    type={"text"}
                                                    name="question"
                                                    handleChange={handleChange}
                                                    value={values.question}
                                                    onBlur={handleBlur}
                                                />
                                            </div>
                                        </div>
                                        <div className={style['select-wraapper']}>
                                            <label>Select Page</label>
                                            <div className={style['sel-wrapper']}>
                                                <MultiSelect
                                                    selectedItems={values.page_id}
                                                    handleChange={(e) =>
                                                        setFieldValue("page_id", e.value)
                                                    }
                                                    items={data.map(item => ({
                                                        label: item?.page_name,
                                                        value: item?.id
                                                    }))}
                                                    hint={"Select page"}
                                                    isSearchable={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={style['input-wrapper']}>
                                        <label className={style['ans']}>Answer</label>
                                        <JoditEditor
                                            ref={editor}
                                            name="answer"
                                            value={values.answer}
                                            onChange={handleChange}
                                            onBlur={(newContent) => setFieldValue("answer", newContent)}
                                            config={{
                                                height: 400,  // Sets the height
                                                maxHeight: 600,  // Sets the max height
                                                disablePlugins: "paste"
                                            }}

                                        />
                                    </div>
                                    <div className={style['action-wrapper']}>
                                        <Button
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                            className={style["form-published"]}
                                        >
                                            submit
                                        </Button>

                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>

            )}
        </>
    )
}
