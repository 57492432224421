import axios from "axios";
import axiosInstance, {
  API_URL,
  DEALER_URL,
  NEST_API_URL,
  ADMIN_APIKEY,
  DC_APIKEY,
  DEALER_APP_URL,
  SERVICE_HISTORY_URL,
  INVENTRY_SERVICES_URL,
  TRUE_PRICE,
  ADMIN_API_URL
} from "../config";
// import axios from "axios";
import { dealerId } from "../constants/resources";
//const accessToken = localStorage.getItem('accessToken');

export const csvList = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });
  return result;
};

export const leadCall = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });
  return result;
};

export const saveCsvFileData = async (method, url, data, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: DEALER_APP_URL + url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const saveCsvList = async (method, url, formData, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: DEALER_APP_URL + url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const updateCsvStatus = async (method, url, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: DEALER_APP_URL + url,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const enquiryList = async (method, url, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: API_URL + url,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const call = async (method, url, params = {}, data = {}) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      apikey: ADMIN_APIKEY,
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const brandcall = async (method, url, params = {}, data = {}, token) => {
  const result = await axios({
    method,
    url: NEST_API_URL + url,
    params: { ...params }, // make and is_group_model will be sent as query params
    headers: {
      "Content-Type": "application/json",
      apikey: ADMIN_APIKEY,
    },
    responseType: "json",
  });

  return result;
};

export const customerCalls = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  const result = await axios({
    method,
    url: DEALER_APP_URL + url,
    params,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    data: { ...data },
    responseType: "json",
  });
  return result;
};

export const preferenceCall = async (method, url, params, token) => {
  // const fullUrl = `${DEALER_APP_URL}${url}/${params}`;
  const fullUrl = `${DEALER_APP_URL}${url}?leadId=${params}`;
  const result = await axios({
    method,
    url: fullUrl,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  return result;
};

export const dealerSubUser = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  const result = await axiosInstance({
    method,
    url: DEALER_URL + url,
    params,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      // apikey: ADMIN_APIKEY,
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const selerLead = async (method, url, params = {}, data = {}, token) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: API_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const dealerfetchCall = async (method, url, params = {}, data = {}) => {
  const result = await axiosInstance({
    method,
    url: DEALER_URL + url,
    params,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      // apikey: ADMIN_APIKEY,
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const dealerappUrl = async (method, url, params, token) => {
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    params,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const carCall = async (method, url, params = {}, data, token) => {
  const result = await axiosInstance({
    method,
    url: NEST_API_URL + url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
      apikey: DC_APIKEY,
    },

    // timeout: 60000,
  });
  return result;
};

export const carCallImage = async (method, url, params = {}, data, token) => {
  const result = await axiosInstance({
    method,
    url: NEST_API_URL + url,
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
      "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
      apikey: DC_APIKEY,
    },

    // timeout: 60000,
  });
  return result;
};

export const dealerSaveInventory = async (
  method,
  url,
  params = {},
  data,
  token
) => {
  const result = await axiosInstance({
    method,
    url: NEST_API_URL + url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      apikey: DC_APIKEY,
      Authorization: token ? `Bearer ${token}` : "",
    },

    // timeout: 60000,
  });
  return result;
};

export const addCarReccomended = async (
  method,
  url,
  params = {},
  data,
  token
) => {
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    data: data,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": "en",
      apikey: DC_APIKEY,
      Authorization: token ? `Bearer ${token}` : "",
    },

    // timeout: 60000,
  });
  return result;
};

export const blogCall = async (method, url, params = {}, data = {}) => {
  // Determine the Content-Type based on data
  const isFormData = data instanceof FormData;
  const contentType = isFormData ? "multipart/form-data" : "application/json";

  try {
    const result = await axiosInstance({
      method,
      url: process.env.REACT_APP_DEALER_URL + "dealer-app-auth/" + url,
      params,
      data,
      headers: {
        "Content-Type": contentType,
      },
      responseType: "json",
    });
    return result;
  } catch (error) {
    console.error("Error in blogCall:", error);
    throw error;
  }
};

export const saveBlogs = async (method, url, params = {}, data = {}) => {
  // Determine the Content-Type based on data
  const isFormData = data instanceof FormData;
  const contentType = isFormData ? "multipart/form-data" : "application/json";

  try {
    const result = await axiosInstance({
      method,
      url: process.env.REACT_APP_DEALER_URL + "admin-dealer-auth/" + url,
      params,
      data,
      headers: {
        "Content-Type": contentType,
      },
      responseType: "json",
    });
    return result;
  } catch (error) {
    console.error("Error in blogCall:", error);
    throw error;
  }
};

export const getBlogs = async (method, url, params = {}, data = {}) => {
  const result = await axiosInstance({
    method,
    url: process.env.REACT_APP_DEALER_URL + "admin-dealer-auth/" + url,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result;
};

export const getState = async (method, url) => {
  const result = await axiosInstance({
    method,
    url: `${NEST_API_URL}inventoryService/public/${url}`,
    headers: {
      apikey: ADMIN_APIKEY,
    },
  });
  return result;
};

export const getCity = async (method, url, { params }) => {
  console.log(params);
  const result = await axiosInstance({
    method,
    url: `${NEST_API_URL}inventoryService/public/${url}`,
    headers: {
      apikey: ADMIN_APIKEY,
    },
    params,
  });
  return result;
};

export const dealerAppAuth = async (method, url, params = {}, data = {}, token) => {
  const result = await axiosInstance({
    method,
    url: process.env.REACT_APP_DEALER_URL + url,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });
  return result;
};


export const addManageStore = async (method, url, data, params = {}) => {
  const config = {
    method,
    url: DEALER_URL + "admin-dealer-auth/" + url,
    params,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  };

  // Only add the `data` field if the method is not GET
  if (method.toLowerCase() !== "get") {
    config.data = data; // Attach data to POST, PUT, etc.
  } else {
    config.params = { ...params, ...data }; // Attach data as query params for GET
  }

  const result = await axiosInstance(config);
  return result;
};

export const dealerPrefrencesCall = async (method, url, data, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: SERVICE_HISTORY_URL + url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    console.log("reslt ", result);
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const dealerCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: DEALER_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const uploadedDocumentDealerApi = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axiosInstance({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};


export const getDealerCallPrefrence = async (method, url, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: SERVICE_HISTORY_URL + url,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    // console.log("url ", url);
    // console.log("reslt for get pre", result.data)
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const getRtoAgentsCall = async (method, url, params = {}, data, token) => {
  try {
    const result = await axiosInstance({
      method,
      url: SERVICE_HISTORY_URL + url,
      params,
      data,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      responseType: "json",
    });
    // console.log("url ", url);
    // console.log("reslt for get pre", result.data)
    return result.data;
  } catch (error) {
    console.error("Error saving CSV list:", error);
    throw error;
  }
};

export const serviceHistoryCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: DEALER_APP_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};


export const serviceRtoCall = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  data = { ...data };
  const result = await axiosInstance({
    method,
    url: SERVICE_HISTORY_URL + url,
    params,
    data: { ...data },
    headers: {
      "Content-Type": "application/json",
      //   "Accept-Language": "en",
      authorization: token ? `Bearer ${token}` : "",
    },
    // timeout: 60000,
    responseType: "json",
  });

  return result;
};

export const UpdateDealerUploadDocument = async (
  method,
  url,
  params = {},
  data = {}
) => {
  console.log("data ", data);
  const result = await axiosInstance({
    method,
    url: DEALER_URL + `admin-dealer-auth${url}`,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};


export const customerCall = async (method, url, params = {}, data = {}) => {
  console.log("data ", data);
  const result = await axiosInstance({
    method,
    url: "http://localhost:8000/api/v1" + url,
    params,
    data,
    responseType: "json",
  });
  console.log("customer url", url);
  return result;
};
export const getTruePrice = async (method, url, params = {}, data = {}, token) => {
  console.log("Authorize token", token);
  
  const result = await axios({
    method,
    url: TRUE_PRICE + url,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
      "Authorization": token ? `Bearer ${token}` : "", // Fixed template literal
    },
    responseType: "json",
  });

  return result;
};

export const testimonials = async (
  method,
  url,
  params = {},
  data = {},
  token
) => {
  const result = await axiosInstance({
    method,
    url: DEALER_URL + url,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });

  return result;
};

export const adminApiCall = async (method, url, params = {}, data = {}, token) => {
  const result = await axiosInstance({
    method,
    url: ADMIN_API_URL + url,
    params,
    data,
    headers: {
      "Content-Type": "application/json",
      authorization: token ? `Bearer ${token}` : "",
    },
    responseType: "json",
  });

  return result;
};




export const dealerAppDocumentUpload = async (method, url, params = {}, data = {}) => {
  // Determine the Content-Type based on data
  const isFormData = data instanceof FormData;
  const contentType = isFormData ? "multipart/form-data" : "application/json";

  try {
    const result = await axiosInstance({
      method,
      url: process.env.REACT_APP_DEALER_URL + url,
      params,
      data,
      headers: {
        "Content-Type": contentType,
      },
      responseType: "json",
    });
    return result;
  } catch (error) {
    console.error("Error in blogCall:", error);
    throw error;
  }
};
