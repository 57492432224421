import { R } from "../../constants/resources";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { CustomizedDialogs } from "./DetailTable";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Pagination, Stack } from "@mui/material";
import style from "../../screens/buyer-lead/buyerLead.module.scss";
import { useNavigate } from "react-router-dom";
import { ToggleSwitch } from "../toogleSwitch";
import userApi from "../../api/usersApi";
import { useDispatch, useSelector } from "react-redux";
import { fetchDealerRoles } from "../../store/roles/roleAction";

function UserTable({ data }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roleList  = useSelector((state) => state?.roleReducers?.roleList?.data || []);

    const [usersData, setUsersData] = useState(data);


    const onEditClick = (data) => {
        navigate("/create-user", { state: data })
    }

    useEffect(() => {
        setUsersData(data);
    }, [data]);

    const handleStatusChange = async (userId, newStatus) => {
        try {
            await userApi.isActive({ id: userId, newActiveState: newStatus });

            setUsersData((prevData) =>
                prevData.map((user) =>
                    user.id === userId ? { ...user, isActive: newStatus } : user
                )
            );
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    useEffect(() => {
        dispatch(fetchDealerRoles());
    }, [dispatch])

    const getRole = (id) => {
        const res = roleList.find(res => res?.id == id);
        return res?.role_name || "N/O"
    }




    const ActionButtons = ({ data, onStatusChange }) => {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => onEditClick(data)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <ToggleSwitch
                    enabled={data.isActive === 1}
                    onChange={() => onStatusChange(data.id, data.isActive === 1 ? 0 : 1)}
                    className={style["toggle-action"]}
                />

            </Stack>
        );
    };

    const StatusLabel = ({ status }) => {

        let label;
        let backgroundColor; // Declare it here

        switch (status) {
            case 1:
                label = "Active";
                backgroundColor = "#E6FFD9";
                break;
            case 0:
                label = "Inactive";
                backgroundColor = "#FFE3E1";
                break;
            default:
                label = "Unknown";
                backgroundColor = "#F0F0F0";
        }

        return (
            <span
                style={{
                    backgroundColor: backgroundColor,
                    padding: "0px 10px",
                    borderRadius: "20px",
                    color: "black",
                    textAlign: "center",
                    marginBottom: "0px",
                }}
            >
                {label}
            </span>
        );
    };

    return (
        <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
        >
            <TableContainer
                component={Paper}
                sx={{ marginTop: '20px' }}
            >
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: "#f5f5f5",
                            "& .MuiTableCell-root": {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell align="left">S.No</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Phone</TableCell>
                            <TableCell align="left">Role</TableCell>
                            <TableCell align="left">Status</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {usersData?.map((user, index) => (
                            <TableRow key={user.id}>
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align="left">{`${user?.firstname || ""} ${user?.lastname || ""}` || "N/A"}</TableCell>
                                <TableCell align="left">{user?.email || "N/A"}</TableCell>
                                <TableCell align="left">{user?.phone || "N/A"}</TableCell>
                                <TableCell align="left">{getRole(user?.user_role_id) || "N/A"}</TableCell>
                                <TableCell align="left">
                                    <StatusLabel status={user?.isActive} />
                                </TableCell>
                                <TableCell align="left" style={{ display: "flex", justifyContent: "center" }}>
                                    <ActionButtons data={user} onStatusChange={handleStatusChange} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>
        </div>

    )
}
export default UserTable