import axios from "axios";
import {
  isImage,
  get_url_extension,
  generateServicePrice,
} from "../../../utils/General.js";
import { Form, Formik } from "formik";
import { MdGetApp } from "react-icons/md";
import debounce from "lodash/debounce";
import style from "./style.module.scss";
import { RiResetRightFill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Regex } from "../../../constants/regex";
import { R } from "../../../constants/resources";
import { useCallback, useEffect, useState } from "react";
import { dealerApi } from "../../../api/dealerApi";
import { Button } from "../../../components/buttons";
import { Loading } from "../../../components/Loading";
import { rtoCasesReqApi } from "../../../api/rtoCasesApi";
import { InputField } from "../../../components/InputField";
import validationSchema from "../../../constants/validations";
import { showToast } from "../../../components/toaster/toaster.js";
import { Autocomplete, TextField } from "@mui/material";
import { addRtoCases } from "../../../store/rto-services/rtoCases/rtoCasesAction";
import { VahanCheckRequestApi } from "../../../api/vahanCheckApi.js";
import { DEFAULT_DEALER_ID } from "../../../config.js";
import { fontSize, padding } from "@mui/system";
import Select from "react-select";
import BackButton from "../../../components/buttons/BackButton.jsx";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading.jsx";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const AddRTOCase = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeList, setCodeList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [dealerCoins, setDealerCoins] = useState({});
  const { admin } = useSelector((state) => state.auth);
  const [codeListAuthTo, setCodeListAuthTo] = useState([]);
  const [additionalTaskList, setAdditionalTaskList] = useState([]);
  const [taskValue, setTaskValue] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [agents, setAgents] = useState([]); // To store dealers from API
  const [isDealerModalOpen, setDealerModalOpen] = useState(false);
  const [isAgentModalOpen, setAgentModalOpen] = useState(false); // Modal state
  const [newDealerName, setNewDealerName] = useState("");
  const [newAgentName, setNewAgentName] = useState("");

  const [selectedDealer, setSelectedDealer] = useState(null);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [rtoPrice, setRtoPrice] = useState(0);
  const [rtoAdditionalCharge, setRtoAdditionalCharge] = useState(0);
  const [rtoDiscountAmount, setRtoDiscountAmount] = useState(0);
  const [groupToRtoId, setGroupToRtoId] = useState(0);
  const [groupFromRtoId, setGroupFromRtoId] = useState(0);
  const [fromFilteredRecord, setFromFilteredRecord] = useState("");
  const [filteredCodeListAuthTo, setFilteredCodeListAuthTo] = useState([]);
  const [rtoTaskPrice, settaskRtoPrice] = useState(0);
  const [stateList, setStateList] = useState(0);
  const [cityList, setCityList] = useState([]);
  const [regNo, setRegNo] = useState({});
  const [rtpApiRes, setRtpApiRes] = useState(false);
  const [suggestedTask, setSuggestedTask] = useState({});
  const [checkRtoCode, setCheckRtoCode] = useState(0);
  const [cityId, setCityId] = useState(0);
  const [dropCityList, setDropCityList] = useState([]);
  const [fetchBtnClick, setFetchBtnClick] = useState(false);
  const [rtoCode, setRtoCode] = useState(null);

  const [dealerMobile, setDealerMobile] = useState("");
  const [dealerName, setDealerName] = useState("");

  const [fromRtoCityList, setFromRtoCityList] = useState([]);
  const [additionalTaskAmount, setAdditionalTaskAmount] = useState(0);
  const [amountAgent, setAmountAgent] = useState(0);
  const [recivelAmount, setRecivealAmount] = useState(0);
  const [feeAmount, setFeeAmount] = useState(0);
  const [finalAmont, setFinalAmount] = useState(0);
  console.log("finalAmont ==>", finalAmont);

  const dealer_id = localStorage.getItem("dealerId");

  const initialValues = {
    rc_no: "",
    dealer_id: "" || dealer_id,
    auth_from: "",
    auth_to: "",
    buyer_name: "",
    buyer_phone: "",
    seller_name: "",
    seller_phone: "",
    task_id: "",
    add_task_id: "",
    remarks: "",
    amount: "",
    use_coins: true,
    amount: "",
    payable_amount_agent: "",
    discount_amount: "",
    hpt_type: "",
    noc_type: "",
    hpa_type: "",
    noc_only_type: "" || "no",
    city_id: "" || 0,
    rto_task_amount: 0,
    rto_additional_task_amount: 0,
    receivable_status: "",
    payable_status: "",
    contact_agent_id: "",
    contact_dealer_id: "",
    profit_amount: "",
    receivable_amount_dealer: "",
    manage_by: "SELF",
  };

  const getTaskList = async () => {
    const res = await rtoCasesReqApi.getRtoTaskList();
    setTaskList(res?.data);
  };

  const getAdditionalRTOTask = async () => {
    const res = await rtoCasesReqApi.getRtoAdditionalTaskList();
    setAdditionalTaskList(res?.data);
  };

  const getStateList = async () => {
    const res = await rtoCasesReqApi.getStateList();
    setStateList(res);
  };

  const fetchCityList = async () => {
    try {
      const response = await rtoCasesReqApi.getCityWiseList({
        city_id: cityId,
      });
      setDropCityList(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cityId) {
      fetchCityList();
    }
    getFromRtoCodeList();
  }, [cityId]);

  const getFromRtoCodeList = async () => {
    const res = await rtoCasesReqApi.getFromRtoCode();
    setFromRtoCityList(res?.data);
  };

  const getCodeList = async (reqData, regNo, fromRto) => {
    const res = await rtoCasesReqApi.getRtoCodeList(reqData, fromRto);

    setCodeList(res?.data);
    return res?.data;
  };

  const getCodeListAuthTo = async (reqData) => {
    if (reqData) {
      const res = await rtoCasesReqApi.getRtoCodeList(reqData);
      setCodeListAuthTo(res?.data);
      return res?.data;
    } else {
      return [];
    }
  };

  const getCityByCodeListAuthTo = async (cityList, reqData) => {
    if (reqData) {
      const filteredCityRecord =
        cityList && cityList.length > 0
          ? cityList?.filter((item) => +item.city_id === +reqData)[0]
              ?.rto_codes_mapping
          : "";
      let matchingRecords = "";
      if (JSON.parse(filteredCityRecord)?.length == 1) {
        matchingRecords = codeListAuthTo.filter(
          (record) => JSON.parse(filteredCityRecord)[0] == record.regNo
        );
      } else {
        matchingRecords = codeListAuthTo.filter((record) =>
          filteredCityRecord?.includes(record.regNo)
        );
      }

      matchingRecords
        ? setFilteredCodeListAuthTo(matchingRecords)
        : setCodeListAuthTo(codeListAuthTo);
    }
  };

  const getCityList = async (reqData) => {
    if (reqData) {
      const res = await rtoCasesReqApi.getCityList(reqData);
      setCityList(res);
      return res;
    } else {
      return [];
    }
  };

  useEffect(() => {
    getTaskList();
    getAdditionalRTOTask();
    getStateList();
  }, []);
  const token = localStorage.getItem("accessToken");


  const getAuthFromGroupId = async (codeListAuthToData) => {
    const item = codeList.find(
      (element) => element.id === codeListAuthToData?.auth_from
    );
    setGroupFromRtoId(item?.rto_group_id);
  };



  const addRtoCasesRequest = (values, { setSubmitting }) => {

    if (values?.hpa_type === "") {
      showToast("error", "HPA shoud be yes or no!");
      return;
    }

    if (values?.noc_type === "") {
      showToast("error", "NOC shoud be yes or no!");
      return;
    }

    setLoading(true);
    const payload = {
      ...values,
      profit_amount: finalAmont, // Ensure disabled fields are added explicitly
    };
  
    dispatch(addRtoCases(payload))
      .then((res) => {
        setSubmitting(false);
        setLoading(false);
        navigate("/rto-management");
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setSubmitting(false);
        setLoading(false);
      });
  };
  const [searchDealer, setSearchDealer] = useState("");
  const getDealerCoins = async (dealer_id) => {
    try {
      const res = await dealerApi.getDealerCoins(dealer_id, 2);
      setDealerCoins(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    let tempObj = {
      pagesize: 10,
    };
    if (searchDealer) {
      tempObj["searchName"] = searchDealer;
    }
  }, [searchDealer]);

  const debouncedChangeHandler = useCallback(
    debounce((id, value) => {
      if (id === "dealer_id") {
        setSearchDealer(value);
      }
    }, 700),
    []
  );

  const extractRTOCode = async (vehicleNumber) => {
    try {
      const match = vehicleNumber.match(/^([A-Z]+\d{1,2})/);

      if (match) {
        return match[1];
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  };

  const viewVahanCheck = async (values, setFieldvalue, refresh) => {
    try {
      if (values == "") {
        showToast("error", "Vehicle No required for fetch rto details.");
        return;
      } else if (values.length < 8) {
        showToast("error", "Vehicle Number should be valid.");
        return;
      }
      let requestBody = {};
      requestBody = { dealer_id: DEFAULT_DEALER_ID, rc_no: values };
      if (refresh) {
        requestBody = {
          dealer_id: DEFAULT_DEALER_ID,
          rc_no: values,
          refresh: true,
        };
      }

      const response =
        await VahanCheckRequestApi.getvehicleDetailsWithoutDealer(
          requestBody
        ).catch((error) => {
          showToast(
            "error",
            "We are facing some issue at this time, please try again later"
          );
          return;
        });
      if (response?.status) {
        let hpt = "yes";
        if (
          response?.data?.rcFinancer == null ||
          response?.data?.rcFinancer == "N/A" ||
          response?.data?.rcFinancer == "NA" ||
          response?.data?.rcFinancer == "On Cash" ||
          response?.data?.rcFinancer == ""
        ) {
          hpt = "no";
        }
        setFieldvalue("hpt_type", hpt);
        const modifiedString = response?.data?.rtoCode.slice(0, 2);
        const updatedCodeList = await getCodeList(
          modifiedString,
          response?.data?.rtoCode,
          "yes"
        );

        const setRtoItem = fromRtoCityList.find(
          (ele) =>
            ele?.rto_office_name?.toLowerCase() ===
            response?.data?.regAuthority?.toLowerCase()
        );

        const setItem = updatedCodeList.find((ele) =>
          setRtoItem
            ? ele?.regNo === setRtoItem?.rto_code
            : ele?.regNo === response?.data?.rtoCode
        );
        setRtoCode(response?.data?.rtoCode || null);
        setFieldvalue("auth_from", setItem?.id);
        setCheckRtoCode(setItem?.id);

        setFieldvalue("chassis_no", response?.data?.chassis);
        setFieldvalue("seller_name", response?.data?.owner);
        setRtpApiRes(true);
      }
    } catch (err) {
      showToast("error", "Vehicle Number should be valid." + err);
      return;
    }
  };

  const autoTaskSuggestion = async (
    requestBody,
    currentClick,
    setFieldValue,
    type
  ) => {
    const item = codeList.find((element) => element.id == checkRtoCode);
    setGroupFromRtoId(item?.rto_group_id);
    await getAuthFromGroupId({ auth_from: checkRtoCode });
    let fromMatchingRecord = codeList.find(
      (item) => item.id == checkRtoCode
    )?.regNo;
    let toMatchingRecord = codeListAuthTo.find(
      (item) => item.id == requestBody.auth_to
    )?.regNo;
    let reqBody = {
      currentRTO: fromMatchingRecord || "",
      destinationRTO: toMatchingRecord || "",
      noc: type === "noc" ? currentClick : requestBody?.noc_type || "",
      hpa: type === "hpa" ? currentClick : requestBody?.hpa_type || "",
      hpt: type === "hpt" ? currentClick : requestBody?.hpt_type || "",
      noc_only:
        type === "noc_only" ? currentClick : requestBody?.noc_only_type || "",
      state: requestBody?.state_id,
    };
    const response = await rtoCasesReqApi
      .getAutoTaskSuggestion(reqBody)
      .catch((error) => {
        console.error("Something went wrong", error);
        return null;
      });
    if (response?.data.id != 0) {
      // await getRtoAmount(response?.data?.id, "main", item?.rto_group_id);
      setFieldValue("task_id", response?.data?.id);
      setSuggestedTask(response?.data);
    } else {
      // setFieldValue("amount", 0);
      setFieldValue("additional_charge", 0);
      setFieldValue("discount_amount", 0);
      setFieldValue("agreed_amount", 0);
    }
  };



  const resetRtoToChange = (setFieldValue) => {
    setFieldValue("hpa_type", "");
    setFieldValue("noc_type", "");
  };

  const fetchRTODealerList = async () => {
    const agent_id = localStorage.getItem("dealerId");
    const type = "dealer";
    try {
      const res = await dealerApi.getDealersList(agent_id, type);
      console.log("response-data dealer ===>", res);
      setDealers(res);
    } catch (error) {
      console.error("Error fetching dealers:", error);
    }
  };

  useEffect(() => {
    fetchRTODealerList();
  }, []);

  const fetchRTOAgentList = async () => {
    const agent_id = localStorage.getItem("dealerId");
    const type = "agent";
    try {
      const res = await dealerApi.getDealersList(agent_id, type);
      console.log("response-data agent ===>", res);
      setAgents(res);
    } catch (error) {
      console.error("Error fetching dealers:", error);
    }
  };

  useEffect(() => {
    fetchRTOAgentList();
  }, []);

  const handleAddNewDealer = async () => {
    try {
      setLoading(true);
      const res = await dealerApi.saveDealerAgent({
        name: newDealerName,
        type: "dealer",
        agent_id: localStorage.getItem("dealerId"),
      });

      if (res?.statusCode === 200 && res.data) {
        setDealers((prev) => [...prev, res.data]);
        setNewDealerName(""); // Reset input
      } else {
        console.error("Error adding user:", res);
      }
      await fetchRTODealerList();
      setDealerModalOpen(false);
    } catch (error) {
      console.error("API Error:", error);
      alert("Failed to add user. Please try again.");
      setDealerModalOpen(false);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleAddNewAgent = async () => {
    if (newAgentName.trim()) {
      try {
        setLoading(true);
        const res = await dealerApi.saveDealerAgent({
          name: newAgentName,
          type: "agent",
          agent_id: localStorage.getItem("dealerId"),
        });

        if (res?.statusCode === 200 && res.data) {
          setAgents((prev) => [...prev, res.data]); // Add new user to the dropdown
          setNewAgentName(""); // Reset input
          setAgentModalOpen(false); // Close modal
        } else {
          console.error("Error adding user:", res);
        }
        await fetchRTOAgentList();
      } catch (error) {
        console.error("API Error:", error);
        alert("Failed to add user. Please try again.");
      } finally {
        setLoading(false); // Stop loading
      }
    } else {
      alert("Name cannot be empty!");
    }
  };

  const getDropdownItems = (
    dropCityList,
    filteredCodeListAuthTo,
    codeListAuthTo
  ) => {
    if (dropCityList?.length > 0 && dropCityList[0]?.rto_codes_mapping) {
      try {
        const parsedData = JSON.parse(dropCityList[0].rto_codes_mapping);
        return parsedData.map((rto) => ({
          label: rto,
          value: rto,
        }));
      } catch (error) {
        console.error("Invalid JSON in rto_codes_mapping:", error);
        return [];
      }
    }
    const sourceList =
      filteredCodeListAuthTo?.length > 0
        ? filteredCodeListAuthTo
        : codeListAuthTo;
    return sourceList.map((ele) => ({
      label: ele?.regNo,
      value: ele?.id,
    }));
  };

  const handleAuthToChange = (e, codeList, setFieldValue) => {
    const repdata = e.target.value;

    const isString = typeof repdata === "string";
    const valueToSet = isString
      ? codeList.find((ele) => ele?.regNo === repdata)?.id
      : repdata;

    if (valueToSet) {
      setFieldValue("auth_to", valueToSet);
    }
  };

  const calculateValue = (
    amountAgent,
    recivelAmount,
    feeAmount,
    setFieldValue
  ) => {
    const AgentAmount = parseInt(+amountAgent);
    const recivaeAmount = parseInt(+recivelAmount);
    const AmountFee = parseInt(+feeAmount);
    const TotalAmount = recivaeAmount - AmountFee - AgentAmount;
    console.log("TotalAmount ===>", TotalAmount)
    setFinalAmount(TotalAmount);
  };

  useEffect(() => {
    calculateValue(amountAgent, recivelAmount, feeAmount);
  }, [amountAgent, recivelAmount, feeAmount]);

  return (
    <div className={style["container"]}>
      <div className={style["header-wrapper"]}>
        <BackButton onClick={() => navigate("/rto-management")} />
        <PrimaryHeading
          title={"RTO Management"}
          fontSize={"1.4rem"}
          fontWeight={600}
        />
      </div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema.addRtoCases}
        onSubmit={addRtoCasesRequest}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          errors,
          setFieldValue,
          touched,
          setTouched,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => {
          return (
            <Form className={style["form-container"]} onSubmit={handleSubmit}>
              <div className={style["wrapper"]}>
                <div className={style["autocomplete-wrapper"]}>
                  <label
                    style={{
                      color: "black",
                      width: "200px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Dealership
                  </label>
                  <Autocomplete
                    label="Select Dealer"
                    className={style["dropdown-multiple"]}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    // Adjust this to match your CSS if required
                    value={selectedDealer}
                    onChange={(event, newValue) => {
                      setFieldValue("contact_dealer_id", newValue?.id);
                      if (newValue?.id === "add_new") {
                        setDealerModalOpen(true); // Open modal for new user input
                      } else {
                        setSelectedDealer(newValue);
                      }
                    }}
                    options={[
                      ...dealers,
                      { name: "Add New User", id: "add_new" },
                    ]} // Add 'Add New User' option
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Dealer" />
                    )}
                    renderOption={(props, option) =>
                      option.name === "Add New User" ? (
                        <li
                          {...props}
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          + {option.name}
                        </li>
                      ) : (
                        <li {...props}>{option.name}</li>
                      )
                    }
                    isOptionEqualToValue={(option, value) =>
                      typeof option === "string"
                        ? option === value
                        : option.id === value?.id
                    }
                  />
                  <Dialog
                    open={isDealerModalOpen}
                    onClose={() => setDealerModalOpen(false)}
                  >
                    <DialogTitle>Add New Dealer</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newDealerName}
                        onChange={(e) => setNewDealerName(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <button
                        className={style["reset-button"]}
                        onClick={() => setDealerModalOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={handleAddNewDealer}
                        variant="contained"
                        color="primary"
                        className={style["reset-button"]}
                      >
                        Save Dealer
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div className={style["reset-field"]} style={{ gridRow: "2" }}>
                  <InputField
                    type={""}
                    label={"Vehicle No"}
                    placeholder={"Enter Vehicle Number"}
                    name="rc_no"
                    value={values.rc_no}
                    onBlur={handleBlur}
                    onChange={(value) => {
                      setFieldValue("rc_no", value.toUpperCase());

                      setRegNo(value);
                    }}
                    disabled={regNo?.length === 10 ? true : false}
                    className={style["inputfield"]}
                    error={touched?.rc_no && errors?.rc_no}
                    required
                  />
                  <div className={style["button-wrapper"]}>
                    <button
                      type="button"
                      onClick={() => {
                        resetForm();
                        setRegNo("");
                        setFromFilteredRecord("");
                        setRtoPrice();
                        setGroupToRtoId();
                        setRtpApiRes(false);
                        setTaskValue([]);
                        setSuggestedTask({});
                        setCodeList([]);
                        setCodeListAuthTo([]);
                        setFieldValue("state_id", "");
                      }}
                      className={style["reset-buttonnew"]}
                    >
                      Reset
                    </button>
                    <button
                      className={style["reset-buttonnew"]}
                      type="button"
                      onClick={() => {
                        setFetchBtnClick(true);
                        viewVahanCheck(regNo, setFieldValue, rtpApiRes);
                      }}
                    >
                      {loading
                        ? "Loading..."
                        : rtpApiRes
                        ? "Refresh"
                        : "Fetch Details"}
                    </button>
                  </div>
                </div>

                {/* State city list */}
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Buyer State"}
                    placeholder={"Select State"}
                    name="state_id"
                    value={values?.state_id}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value) {
                        setFieldValue("state_id", value);
                        getCodeListAuthTo(value);
                        getCityList(value);
                      } else {
                        setFieldValue("state_id", "");
                        setFieldValue("city_id", "");
                        setFieldValue("auth_to", "");
                        getCityList([]);
                        getCodeListAuthTo([]);
                        setCityId(null);
                        getCityByCodeListAuthTo(null, "");
                      }

                      console.log("target", value);
                    }}
                    required
                    onBlur={handleBlur}
                    error={touched?.state_id && errors?.state_id}
                    items={stateList?.data?.map((ele) => ({
                      label: ele?.state_list_name,
                      value: ele?.rto_code,
                    }))}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={""}
                    label={"Buyer Name"}
                    placeholder={"Enter Buyer Name"}
                    name="buyer_name"
                    value={values.buyer_name}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("buyer_name", value)}
                    error={touched?.buyer_name && errors?.buyer_name}
                    required
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Buyer Phone No"}
                    placeholder={"Enter Mobile Number"}
                    name="buyer_phone"
                    value={values.buyer_phone}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("buyer_phone", value)}
                    error={touched?.buyer_phone && errors?.buyer_phone}
                    length={10}
                    required
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  {" "}
                  <div className={style["additional-wrapper"]}>
                    <label
                      style={{
                        color: "black",
                        width: "200px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Authority From
                    </label>
                    <Select
                      className={style["dropdown-multiple"]}
                      value={codeList
                        .filter((ele) => ele.id === values?.auth_from)
                        .map((ele) => ({
                          label: ele?.regNo,
                          value: ele?.id,
                        }))}
                      name="auth_from"
                      options={codeList?.map((ele) => ({
                        label: ele?.regNo,
                        value: ele?.id,
                      }))}
                      onChange={(e) => {
                        setFieldValue("auth_from", e.value);
                        setCheckRtoCode(e.target.value);
                      }}
                      isDisabled={rtoCode}
                      onBlur={handleBlur}
                    />
                    {touched.auth_from && errors.auth_from && (
                      <div className={style["error-message"]}>
                        {errors.auth_from}
                      </div>
                    )}
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label
                      style={{
                        color: "black",
                        width: "200px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Authority To
                    </label>
                    <Select
                      className={style["dropdown-multiple"]}
                      value={
                        values.city_id && dropCityList.length === 0
                          ? null
                          : dropCityList && dropCityList.length > 0
                          ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .filter((ele) => ele.id === values.auth_to)
                              .map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                          : codeListAuthTo
                              ?.filter((ele) => ele.id === values.auth_to)
                              ?.map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                      }
                      name="auth_to"
                      options={
                        values.city_id && dropCityList.length === 0
                          ? [] // When city_id is found but dropCityList is empty, set items to an empty array
                          : dropCityList && dropCityList.length > 0
                          ? codeListAuthTo
                              .filter((ele) => dropCityList.includes(ele.regNo))
                              .map((ele) => ({
                                label: ele?.rto_name
                                  ? ele?.regNo + ` - ${ele?.rto_name}`
                                  : ele?.regNo,
                                value: ele?.id,
                              }))
                          : codeListAuthTo?.map((ele) => ({
                              label: ele?.rto_name
                                ? ele?.regNo + ` - ${ele?.rto_name}`
                                : ele?.regNo,
                              value: ele?.id,
                            }))
                      }
                      onChange={(e) => {
                        console.info("");
                        setFieldValue("auth_to", e.value);
                        // setCheckRtoCode(e.target.value);
                        resetRtoToChange(setFieldValue);
                      }}
                      disabled={rtoCode}
                      onBlur={handleBlur}
                    />
                    {touched.auth_to && errors.auth_to && (
                      <div className={style["error-message"]}>
                        {errors.auth_to}
                      </div>
                    )}
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"text"}
                    label={"Seller Name"}
                    placeholder={"Enter Seller Name"}
                    name="seller_name"
                    required
                    value={values?.seller_name}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("seller_name", value)}
                    error={touched?.seller_name && errors?.seller_name}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Seller Phone No"}
                    placeholder={"Enter Seller Number"}
                    name="seller_phone"
                    value={values.seller_phone}
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("seller_phone", value)}
                    error={touched?.seller_phone && errors?.seller_phone}
                    length={10}
                    required
                  />
                </div>
                {/* Add new column*/}
                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>HPT</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpt_radio"
                          checked={values?.hpt_type == "yes"}
                          onChange={() => {
                            setFieldValue("hpt_type", "yes");
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "hpt"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpt_radio"
                          checked={values?.hpt_type == "no"}
                          onChange={() => {
                            setFieldValue("hpt_type", "no");
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "hpt"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>NOC</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="noc_radio"
                          checked={values?.noc_type == "yes"}
                          onChange={() => {
                            setFieldValue("noc_type", "yes");
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "noc"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="noc_radio"
                          checked={values?.noc_type == "no"}
                          onChange={() => {
                            setFieldValue("noc_type", "no");
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "noc"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <div className={style["radio-input-container"]}>
                    <p className={style["radio-wrapper-text"]}>HPA</p>
                    <div className={style["radio-input"]}>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpa_radio"
                          checked={values?.hpa_type == "yes"}
                          onChange={() => {
                            setFieldValue("hpa_type", "yes");
                            autoTaskSuggestion(
                              values,
                              "yes",
                              setFieldValue,
                              "hpa"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>Yes</p>
                      </div>
                      <div className={style["radio-sub-input"]}>
                        <input
                          type="radio"
                          name="hpa_radio"
                          checked={values?.hpa_type == "no"}
                          onChange={() => {
                            setFieldValue("hpa_type", "no");
                            autoTaskSuggestion(
                              values,
                              "no",
                              setFieldValue,
                              "hpa"
                            );
                          }}
                          style={{
                            padding: "10px",
                            width: "15px",
                            height: "15px",
                          }}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>
                {values?.noc_type === "yes" && (
                  <div className={style["input-wrapper"]}>
                    <div className={style["radio-input-container"]}>
                      <p className={style["radio-wrapper-text"]}>Noc only</p>
                      <div className={style["radio-input"]}>
                        <div className={style["radio-sub-input"]}>
                          <input
                            type="radio"
                            name="noc_only"
                            checked={values?.noc_only_type == "yes"}
                            onChange={() => {
                              setFieldValue("noc_only_type", "yes");
                              autoTaskSuggestion(
                                values,
                                "yes",
                                setFieldValue,
                                "noc_only"
                              );
                            }}
                            style={{
                              padding: "10px",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                          <p>Yes</p>
                        </div>
                        <div className={style["radio-sub-input"]}>
                          <input
                            type="radio"
                            name="noc_only"
                            checked={values?.noc_only_type == "no"}
                            onChange={() => {
                              setFieldValue("noc_only_type", "no");
                              autoTaskSuggestion(
                                values,
                                "no",
                                setFieldValue,
                                "noc_only"
                              );
                            }}
                            style={{
                              padding: "10px",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                          <p>No</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* END*/}

                <div className={style["input-wrapper"]}>
                  <InputField
                    label={"Task"}
                    placeholder={"Select Tasks"}
                    type={"select"}
                    name="task_id"
                    value={suggestedTask ? suggestedTask.id : values?.task_id}
                    onChange={(e) => {
                      setFieldValue("task_id", e.target.value);
                    }}
                    onBlur={handleBlur}
                    error={touched?.task_id && errors?.task_id}
                    required
                    items={taskList?.map((ele) => ({
                      label: ele?.name,
                      value: ele?.id,
                    }))}
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <div className={style["additional-wrapper"]}>
                    <label
                      style={{
                        color: "black",
                        width: "200px",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      Additional Tasks
                    </label>
                    <Select
                      isMulti
                      className={style["dropdown-multiple"]}
                      value={taskValue}
                      name="add_task_id"
                      options={additionalTaskList?.map((ele) => ({
                        label: ele?.name,
                        value: ele?.id,
                      }))}
                      onChange={(selectedOption) => {
                        setTaskValue(selectedOption);
                        const selectedIds = selectedOption.map(
                          (option) => option.value
                        );
                        const selectedIdsString = selectedIds.join(",");
                        setFieldValue("add_task_id", selectedIdsString);
                        // getRtoAmount(selectedIdsString, "additional");
                      }}
                    />
                    {touched.add_task_id && errors.add_task_id && (
                      <div className={style["error-message"]}>
                        {errors.add_task_id}
                      </div>
                    )}
                  </div>
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    label={"Remarks"}
                    placeholder={"Enter Remarks"}
                    name="remarks"
                    value={values.remarks}
                    required
                    onBlur={handleBlur}
                    onChange={(value) => setFieldValue("remarks", value)}
                    error={touched?.remarks && errors?.remarks}
                    disabled={false}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Receivable Amount From Dealer"}
                    placeholder={"Enter Amount"}
                    name="receivable_amount_dealer"
                    value={values.receivable_amount_dealer}
                    onChange={(value) => {
                      setFieldValue("receivable_amount_dealer", value);
                      setRecivealAmount(value);
                    }}
                    // value={rtoPrice}
                    onBlur={handleBlur}
                    error={
                      touched?.receivable_amount_dealer &&
                      errors?.receivable_amount_dealer
                    }
                    required
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Receivable Payment Status"}
                    placeholder={"Select Status"}
                    name="receivable_status"
                    value={values?.receivable_status}
                    onChange={(e) => {
                      console.log(e.target.value, "Receivable Payment Status");
                      setFieldValue("receivable_status", e.target.value);
                    }}
                    required
                    onBlur={handleBlur}
                    error={
                      touched?.receivable_status && errors?.receivable_status
                    }
                    items={[
                      { label: "Pending", value: 1 },
                      { label: "Received", value: 2 },
                      { label: "On Hold", value: 3 },
                    ]}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Fee Amount"}
                    placeholder={"Enter Amount"}
                    value={values?.amount}
                    onChange={(value) => {
                      setFieldValue("amount", value);
                      setFeeAmount(value);
                    }}
                    name="amount"
                    onBlur={handleBlur}
                    error={touched?.amount && errors?.amount}
                    required
                  />
                </div>

                <div className={style["autocomplete-wrapper"]}>
                  <label
                    style={{
                      color: "black",
                      width: "200px",
                      fontSize: "14px",
                      fontWeight: 500,
                    }}
                  >
                    Dealership
                  </label>
                  <Autocomplete
                    label="Select Dealer"
                    className={style["dropdown-multiple"]}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 45,
                      },
                    }}
                    value={selectedAgent}
                    onChange={(event, newValue) => {
                      console.log("newValue", newValue?.id);
                      setFieldValue("contact_agent_id", newValue?.id);
                      if (newValue?.id === "add_new") {
                        setAgentModalOpen(true);
                      } else {
                        setSelectedAgent(newValue);
                      }
                    }}
                    options={[
                      ...agents,
                      { name: "Add New User", id: "add_new" },
                    ]}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Agent" />
                    )}
                    renderOption={(props, option) =>
                      option.name === "Add New User" ? (
                        <li
                          {...props}
                          style={{ color: "blue", fontWeight: "bold" }}
                        >
                          + {option.name}
                        </li>
                      ) : (
                        <li {...props}>{option.name}</li>
                      )
                    }
                    isOptionEqualToValue={(option, value) =>
                      typeof option === "string"
                        ? option === value
                        : option.id === value?.id
                    }
                  />
                  <Dialog
                    open={isAgentModalOpen}
                    onClose={() => setAgentModalOpen(false)}
                  >
                    <DialogTitle>Add New Agent</DialogTitle>
                    <DialogContent>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={newAgentName}
                        onChange={(e) => setNewAgentName(e.target.value)}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className={style["reset-button"]}
                        onClick={() => setAgentModalOpen(false)}
                      >
                        Cancel
                      </Button>
                      <button
                        className={style["reset-button"]}
                        onClick={handleAddNewAgent}
                        variant="contained"
                        color="primary"
                      >
                        Save Agent
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={"Payable Amount To Agent"}
                    value={values.payable_amount_agent}
                    placeholder={"Enter Amount"}
                    name="payable_amount_agent"
                    onChange={(value) => {
                      setFieldValue("payable_amount_agent", value);
                      setAmountAgent(value);
                    }}
                    onBlur={handleBlur}
                    error={
                      touched?.payable_amount_agent &&
                      errors?.payable_amount_agent
                    }
                    required
                  />
                </div>
                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"select"}
                    label={"Payable Amount Status"}
                    placeholder={"Select Status"}
                    name="payable_status"
                    value={values?.payable_status}
                    onChange={(e) => {
                      console.log("Payable Amount Status", e.target.value);
                      setFieldValue("payable_status", e.target.value);
                    }}
                    required
                    onBlur={handleBlur}
                    error={touched?.payable_status && errors?.payable_status}
                    items={[
                      { label: "Pending", value: 1 },
                      { label: "Received", value: 2 },
                      { label: "On Hold", value: 3 },
                    ]}
                  />
                </div>

                <div className={style["input-wrapper"]}>
                  <InputField
                    type={"number"}
                    label={" Profit Amount"}
                    placeholder={"Profit Amount"}
                    name="profit_amount"
                    value={ finalAmont }
                    onBlur={handleBlur}
                    error={touched?.profit && errors?.profit}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className={style["action-container"]}>
                <Link
                  to="/rto-management"
                  className={style["cancel-container"]}
                >
                  Cancel
                </Link>

                <button type="submit" className={style["reset-button"]}>
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddRTOCase;
