import { ADD_ROLE, EDIT_ROLE, GET_PERMISSION, GET_ROLE, GET_ROLE_PERMISSION, GET_PARENT_ROLE } from "../types";

const initalState = {
    roleList: [],
    permissionList: [],
    rolePermissionList: [],
    parentRoleList: []
}


export default function (state = initalState, action) {
    const { type, payload } = action;
    switch (type) {
        case ADD_ROLE:
            return {
                ...state, roleList: [payload, ...state.roleList]
            }
        case EDIT_ROLE:
            const updateRoleList = state?.roleList?.data?.map((item) => {
                if (item?.id === payload?.role_id) {
                    return {
                        ...item,
                        status: item?.status === "active" ? "inactive" : "active",
                    };
                }
                return item;
            })
            return {
                ...state,
                roleList: updateRoleList,
            };

        case GET_ROLE:
            return {
                ...state, roleList: payload?.data
            }
        case GET_PERMISSION:
            return {
                ...state, permissionList: payload
            }
        case GET_ROLE_PERMISSION:
            return {
                ...state, rolePermissionList: payload
            }
        case GET_PARENT_ROLE:
            return {
                ...state, parentRoleList: payload
            }    
        default:
            return state;


    }
}