import style from "./style.module.scss";
import { Form, Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import validationSchema from "../../../constants/validations.js";
import { useDispatch, useSelector } from "react-redux";
import MultiSelect from "../../../components/buttons/multiSelect.jsx";
import { getUserDropdown } from "../../../store/inspection/inspectionService.js";
import { insecptionService } from "../../../api/insecptionApi.js";
import { addDoorStep } from "../../../store/inspection/inspectionService.js";
import { showToast } from "../../../components/toaster/toaster.js";
import moment from "moment";
import debounce from "lodash/debounce";
import { publicApi } from "../../../api/publicApi.js";
import axios from "axios";
import InputField from "../../../components/input-field/InputField.jsx";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import { buyerApi } from "../../../api/buyerLeadApi.js";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import BackButton from "../../../components/buttons/BackButton.jsx";
import { carApi } from "../../../api/carApi.js";
import usersApi from "../../../api/usersApi.js";
import { ROLE_ID } from "../../../config.js";
import { UserDealerPermission } from "../../../components/permissions/index.js";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const AddDoorService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [time, setTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [inputType, setInputType] = useState();
  const [brandsList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [serviceId, setService] = useState("");
  const [userListDropdown, setUserListDropdown] = useState([]);
  const { dealerList, setDealerList } = useState([]);
  const [storeList, setStoreList] = useState([]);
  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "manage_inspection");
  const { checkStatusPermisssion, error } = UserDealerPermission(15);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchUserDropdown = async () => {
      try {
        const response = await buyerApi.getDealerSubUser({
          roleId: 66
        });
        setUserListDropdown(response.data);
      } catch (error) {
        console.error("Error fetching user dropdown:", error);
      }
    };

    fetchUserDropdown();
  }, [serviceId]);
  const getServiceList = async () => {
    // const res = await insecptionService.getDSServiceList();
    // setServiceList(res?.data);
  };

  const initialValues = {
    inputType: inputType,
    ds_service_id: 3,
    remarks: "",
    pocName: "",
    pocMobile: "",
    schDateTime: "",
    prefDateTime: "",
    prefSlotId: "",
    schSlotId: "",
    execAssigned: "",
    store: "",
    makeId: "",
    vehicleNo: "",
    modelId: "",
    regAddress: {
      address: "",
      pincode: "",
      state: "",
      city: "",
    },
    inspectionAt: "",
    service_dealer_id: "",
  };

  const models = async (makeId) => {
    try {
      const res = await publicApi.modelList({
        make: makeId,
      });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   if (initialValues.makeId) {
  //     models();
  //   }
  // }, [initialValues.makeId]);

  useEffect(() => {
    getServiceList();
    getSlotList();
  }, []);

  useEffect(() => {
    getUserDropdown(inputType == 3 ? { role: 51 } : { pageSize: 100 });
    setInputType((e) => e);
  }, [inputType]);

  const getSlotList = async () => {
    const res = await publicApi.getSlots();
    setSlotList(res?.data);
  };

  useEffect(() => {
    brands();
  }, []);

  const addPickupRequest = (values, { setSubmitting }) => {

    console.log("Hi Janmejay ", values);


    // if (parseInt(values?.agreed_amount) < parseInt(values?.amount)) {
    //   showToast("error", "Agreed Amount should be greater than fee amount");
    //   return;
    // }

    setLoading(true);
    const selectedDate = new Date(values.schDateTime);
    const selectedTime = new Date(`1970-01-01T${time}`);
    selectedDate.setHours(selectedTime.getHours());
    selectedDate.setMinutes(selectedTime.getMinutes());

    addDoorStep({
      ds_service_id: [values.ds_service_id],
      service_dealer_id: values.service_dealer_id,
      service_id: values.ds_service_id == 3 ? 15 : undefined,
      showroom_id: values.store,
      estCasesNo: values.estCasesNo,
      remarks: values.remarks,
      pocName: values.pocName,
      pocMobile: values.pocMobile,
      schDateTime: values.schDateTime,
      execAssigned: values.execAssigned,
      regAddress: values.regAddress,
      schSlotId: values.schSlotId,
      prefSlotId: values.prefSlotId,
      prefDateTime: values.prefDateTime,
      makeId: values.makeId,
      modelId: values.modelId,
      vehicleNo: values.vehicleNo,
    })
      .then((res) => {
        setSubmitting(false);
        setLoading(false);
        navigate("/inspection-service");
      })
      .catch((err) => {
        console.log(err);
        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setSubmitting(false);
        setLoading(false);
      });
  };

  const store = async () => {
    try {
      const res = await carApi.dealerAddress();
      setStoreList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    store();
  }, []);

  const [searchDealer, setSearchDealer] = useState("");

  useEffect(() => {
    let tempObj = {
      pagesize: 10,
    };
    if (searchDealer) {
      tempObj["searchName"] = searchDealer;
    }
    // dispatch(getDealerList(tempObj));
  }, [searchDealer]);

  const debouncedChangeHandler = useCallback(
    debounce((id, value) => {
      if (id === "dealer_id") {
        setSearchDealer(value);
      }
    }, 700),
    []
  );

  const [locationData, setLocationData] = useState();
  const token = localStorage.getItem("accessToken");
  const getLocationData = async (dealer_id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEALER_URL}dealer-app/get-dealer-address?dealer_id=${dealer_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data?.status) {
        setLocationData(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  console.log(locationData);
  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.error("Error fetching user dropdown:", error);
    }
  }

  useEffect(() => {
    res();
  }, []);

  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }



  return (
    <>
      {(hasPermission("Add") || hasPermission("Edit") || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["containerMain"]}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

              alignItems: "center",

              padding: "15px ",
            }}
          >
            <BackButton
              onClick={() => {
                navigate("/inspection-service");
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Add New Inspection
            </Typography>
          </Box>
          <div
            style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
            className={style["container"]}
          >
            <Formik
              enableReinitialize
              initialValues={initialValues}
              // validationSchema={validationSchema.addPickupRequest}
              onSubmit={addPickupRequest}
            >
              {({
                values,
                errors,
                setFieldValue,
                touched,
                handleBlur,
                handleSubmit,
              }) => (
                <Form className={style["form-container"]} onSubmit={handleSubmit}>
                  <div className={style["wrapper"]}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Location <span style={{ color: "red" }}>*</span>
                          </p>
                          <MultiSelect
                            name="Store"
                            handleChange={(selectedOption) => {
                              setFieldValue("store", selectedOption.value);
                              // const selectedStore = storeList?.find((ele) => ele.id == selectedOption.value);
                              // console.log("selectedStore ", selectedStore);
                            }}
                            items={storeList?.map((ele) => ({
                              label: ele?.store_name,
                              value: ele?.id,
                            }))}
                            selectedItems={values?.store}
                            placeholder={"Select Location"}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Make <span style={{ color: "red" }}>*</span>
                          </p>

                          <MultiSelect
                            name="makeId"
                            handleChange={(selectedOption) => {
                              setFieldValue("makeId", selectedOption.value);
                              models(selectedOption.value);
                            }}
                            items={brandsList?.map((ele) => ({
                              label: ele?.makeName,
                              value: ele?.makeId,
                            }))}
                            selectedItems={values?.makeId || ""}
                            placeholder={"Select Make"}
                            isSearchable={true}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                            required={true}
                            onBlur={() => handleBlur("makeId")}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Model <span style={{ color: "red" }}>*</span>
                          </p>

                          <MultiSelect
                            name="modelId"
                            handleChange={(selectedItems) =>
                              setFieldValue("modelId", selectedItems.value)
                            }
                            items={modelList?.map((ele) => ({
                              label: ele?.modelName,
                              value: ele?.modelId,
                            }))}
                            selectedItems={values?.modelId || ""}
                            placeholder={"Select Model"}
                            isSearchable={true}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                            required={true}
                            onBlur={handleBlur}
                          />

                          {/* {touched?.modelId && errors?.modelId && (
                        <ErrorMessage errorMessage={errors?.modelId} />
                      )} */}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={style["line"]}></div>
                  <p style={{ marginBottom: "15px" }} className={style["label"]}>
                    POC Details
                  </p>
                  <div
                    style={{ marginBottom: "15px" }}
                    className={style["wrapper"]}
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <InputField
                            value={values.vehicleNo}
                            // onChange={(value) =>
                            //   setFieldValue("vehicleNo", value.toUpperCase())

                            // }
                            handleChange={(e) =>
                              setFieldValue(
                                "vehicleNo",
                                e.target.value.toUpperCase()
                              )
                            }
                            onBlur={handleBlur}
                            label={"Vehicle Number"}
                            placeholder={"Enter Vehicle Number"}
                            name={"vehicleNo"}
                            // required
                            length={10}
                          // error={touched?.pocMobile && errors?.pocMobile}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <InputField
                            type={"text"}
                            label={"Name"}
                            placeholder={"Enter Name"}
                            value={values.pocName}
                            onBlur={handleBlur}
                            handleChange={(e) =>
                              setFieldValue("pocName", e.target.value)
                            }
                            error={touched?.pocName && errors?.pocName}
                            required
                            name={"pocName"}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <InputField
                            value={values.pocMobile}
                            handleChange={(e) =>
                              setFieldValue("pocMobile", e.target.value)
                            }
                            onBlur={handleBlur}
                            label={"Mobile Number"}
                            placeholder={"Enter Mobile Number"}
                            name={"pocMobile"}
                            required
                            length={10}
                            error={touched?.pocMobile && errors?.pocMobile}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>{" "}
                  <div className={style["line"]}></div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={"date"}
                          name={"schDateTime"}
                          value={values?.schDateTime}
                          handleChange={(e) => {
                            const value = e.target.value;
                            if (
                              moment(value).isBefore(moment().format("YYYY-MM-DD"))
                            ) {
                              setFieldValue(
                                "schDateTime",
                                moment().format("YYYY-MM-DD")
                              );
                            } else {
                              setFieldValue("schDateTime", value);
                            }
                          }}
                          onBlur={handleBlur}
                          label={"Date of Schedule"}
                          placeholder={"Select Date"}
                          error={touched?.schDateTime && errors?.schDateTime}
                          required
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <p
                          style={{
                            marginBottom: "5px",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }}
                        >
                          Scheduled Slot <span style={{ color: "red" }}>*</span>
                        </p>
                        <MultiSelect
                          name="schSlotId"
                          handleChange={(selectedItems) => {
                            setFieldValue("schSlotId", selectedItems?.value);
                          }}
                          items={
                            slotList?.map((ele) => ({
                              label: ele?.slot,
                              value: ele?.id,
                            })) || []
                          }
                          selectedItems={values?.schSlotId}
                          placeholder="Select Scheduled Slots"
                          isSearchable={true}
                          containerStyle={
                            style["selectable-button-container-style"]
                          }
                          selectClass={style["filterDropDown"]}
                          required={true}
                          onBlur={() => handleBlur("schSlotId")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={"date"}
                          name={"prefDateTime"}
                          value={values?.prefDateTime}
                          handleChange={(e) => {
                            const value = e.target.value;
                            if (
                              moment(value).isBefore(moment().format("YYYY-MM-DD"))
                            ) {
                              setFieldValue(
                                "prefDateTime",
                                moment().format("YYYY-MM-DD")
                              );
                            } else {
                              setFieldValue("prefDateTime", value);
                            }
                          }}
                          onBlur={handleBlur}
                          label={"Preferred Date"}
                          placeholder={"Select Date"}
                          error={touched?.prefDateTime && errors?.prefDateTime}
                          required
                          min={moment().format("YYYY-MM-DD")}
                        // onChange={(value) => setFieldValue("schDateTime", value)}
                        // min={new Date()}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p
                        style={{
                          marginBottom: "5px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }}
                      >
                        Preferred Slot <span style={{ color: "red" }}>*</span>
                      </p>
                      <MultiSelect
                        name="prefSlotId"
                        handleChange={(selectedItems) => {
                          setFieldValue("prefSlotId", selectedItems?.value);
                        }}
                        items={slotList?.map((ele) => ({
                          label: ele?.slot,
                          value: ele?.id,
                        }))}
                        selectedItems={values?.prefSlotId}
                        placeholder="Select Preferred Slots"
                        isSearchable={true}
                        containerStyle={style["selectable-button-container-style"]}
                        selectClass={style["filterDropDown"]}
                        required={true}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        {" "}
                        <p
                          style={{
                            marginBottom: "5px",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }}
                        >
                          Evaluator <span style={{ color: "red" }}>*</span>
                        </p>
                        <MultiSelect
                          name="execAssigned"
                          handleChange={(selectedItems) => {
                            console.log(
                              "selectedItems selectedItems ",
                              selectedItems?.value
                            );
                            setFieldValue("execAssigned", selectedItems?.value);
                          }}
                          items={userListDropdown?.map((status) => ({
                            label: status.firstName,
                            value: status.id,
                          }))}
                          selectedItems={values?.execAssigned || ""}
                          placeholder="Enter Evaluator Name"
                          isSearchable={true}
                          containerStyle={
                            style["selectable-button-container-style"]
                          }
                          selectClass={style["filterDropDown"]}
                          error={touched?.execAssigned && errors?.execAssigned}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={""}
                          label={"Remarks"}
                          placeholder={"Enter Remarks"}
                          value={values.remarks}
                          onBlur={handleBlur}
                          handleChange={(e) =>
                            setFieldValue("remarks", e.target.value)
                          }
                          error={touched?.remarks && errors?.remarks}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl
                        error={touched.inspectionAt && Boolean(errors.inspectionAt)}
                      >
                        <FormLabel id="demo-row-radio-buttons-group-label">
                          Inspection at <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={values.inspectionAt}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            setFieldValue("inspectionAt", selectedValue);

                            if (selectedValue === "self") {
                              const dealerId = localStorage.getItem("dealerId");
                              setFieldValue("service_dealer_id", dealerId || "");
                            } else if (selectedValue === "motorpedia") {
                              setFieldValue("service_dealer_id", "162");
                            }
                          }}
                          onBlur={handleBlur}
                        >
                          <FormControlLabel
                            value="self"
                            control={<Radio />}
                            label="Self"
                          />
                          <FormControlLabel
                            value="motorpedia"
                            control={<Radio />}
                            label="Motorpedia"
                          />
                        </RadioGroup>
                        {touched.inspectionAt && errors.inspectionAt && (
                          <FormHelperText>{errors.inspectionAt}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>

                    {/* <Grid size={{ xs: 12, md: 6, xl: 4 }}>
                    <Card
                      sx={{
                        p: 2,
                        borderRadius: "8px",
                        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                        background: "#fff",
                      }}
                    >
                      <Stack spacing={2}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box>
                            <Typography variant="h6" color="black">
                              Upload Image
                            </Typography>
                          </Box>
                        </Box>
  
                       
                        <Stack
                          sx={{ width: "250px" }}
                          direction="row"
                          spacing={2}
                          alignItems="center"
                        >
                          <Button
                            sx={{
                              backgroundColor: "#007ABE",
                              height: "35px",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#007ABE",
                                color: "white",
                              },
                            }}
                            fullWidth
                            component="label"
                            role={undefined}
                            variant="contained"
                          >
                            Upload File
                            <VisuallyHiddenInput
                              type="file"
                              onChange={(e) => handleFileUpload(e, fieldName)}
                            />
                          </Button>
  
                      
                          <>
                            <IconButton
                              color="primary"
                              onClick={() =>
                                window.open(fileExe[fieldName], "_blank")
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                            <IconButton
                              color="error"
                              onClick={() =>
                                handleOpenModal("delete", fieldName)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid> */}
                  </Grid>
                  <div className={style["action-container"]} style={{ display: 'flex', justifyContent: 'flex-end' }}>



                    <Button
                      variant="contained"
                      alignItems="end"
                      sx={{
                        itemAlign: "end",
                        backgroundColor: "#121b5b",
                        "&:hover": {
                          backgroundColor: "#121b5b",
                        },
                      }}
                      type="submit"
                    >
                      {loading ? "Loading ..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>

      )}
    </>

  );
};

export default AddDoorService;
