import {
    Box,
    Button,
    Typography,
} from "@mui/material";
import BackButton from "../../../components/buttons/BackButton";
import { useNavigate, useLocation } from "react-router-dom";
import { Formik, Form } from "formik";
import style from "./style.module.scss";
import InputField from "../../../components/input-field/InputField";
import { useState, useEffect } from "react";
import { CheckBox } from "../../../components/checkbox";
import { useDispatch, useSelector } from "react-redux";
import validationSchema from "../../../components/validation/validationSchema";
import MultiSelect from "../../../components/buttons/multiSelect";
import { showToast } from "../../../components/toaster/toaster";
import { addDealerRole, getPermissions, getRolePermissions, editRole, fetchParentRole } from "../../../store/roles/roleAction";
import { roleApi } from "../../../api/roleApi";
import { ROLE_ID } from "../../../config";
import usersApi from "../../../api/usersApi";
import { UserDealerPermission } from "../../../components/permissions";


function CreateRoles() {
    const [accordionOpen, setAccordionOpen] = useState(null);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);
    const { permissionList, rolePermissionList, parentRoleList } = useSelector(
        (state) => state.roleReducers
    );
    const [stpermission, setStpermission] = useState([]);
    const findData = stpermission?.filter(item => item?.slug === "user");

    useEffect(() => {
        dispatch(getPermissions());
        if (state?.id) dispatch(getRolePermissions({ role_id: state?.id }));
        dispatch(fetchParentRole());
    }, []);

    useEffect(() => {
        initialValues.permissions =
            rolePermissionList?.map((p) => p?.permission_id) || [];
    }, [rolePermissionList]);


    const { state } = useLocation();
    const initialValues = {
        name: state?.role_name || "",
        status: state?.status || 1,
        parent_role_id: state?.parent_role_id || "",
        permissions: state
            ? rolePermissionList?.map((p) => p?.permission_id) || []
            : [],
    };

    const addRoles = (values) => {
        if (state) {
            dispatch(editRole({ role_id: state?.id, ...values }))
                .then((res) => {
                    showToast("success", res?.message)
                    navigate("/dealer-user-role");
                })
                .catch((err) => {
                    showToast("error", err?.response?.data?.message);
                });
        } else {
            dispatch(addDealerRole(values))
                .then((res) => {
                    navigate("/dealer-user-role");
                    showToast("success", res?.message)
                })
                .catch((err) => {
                    showToast("error", err?.response?.data?.message);
                });
        }
    };






    const toggleAccordion = (id) => {
        if (id === accordionOpen) setAccordionOpen(null);
        else setAccordionOpen(id);
    };


    // const handlePermissionCheck = (id, values, setFieldValue) => {
    //     let updatedPermissions = values.permissions.includes(id)
    //         ? values.permissions.filter((v) => v !== id)
    //         : [...values.permissions, id];

    //     setFieldValue("permissions", updatedPermissions);
    // };

    const handlePermissionCheck = (id, values, setFieldValue, action, modulePermissions) => {
        let updatedPermissions = [...values.permissions];

        const isYesNoPermission = action === "Yes" || action === "No";

        if (updatedPermissions.includes(id)) {
            updatedPermissions = updatedPermissions.filter((v) => v !== id);
        } else {
            if (isYesNoPermission) {
                modulePermissions.forEach((perm) => {
                    if ((perm.action === "Yes" || perm.action === "No") && perm.id !== id) {
                        updatedPermissions = updatedPermissions.filter((v) => v !== perm.id);
                    }
                });
            }
            // Add the new selection
            updatedPermissions.push(id);
        }

        setFieldValue("permissions", updatedPermissions);
    };



    const fetchDefaultPermission = async (parenRoleId, setFieldValue) => {
        console.log("parent role id", parenRoleId);
        try {
            const res = await roleApi.defaultPermissions({ parenRoleId });
            if (!state) {
                setFieldValue("permissions", res.map((p) => p.permission_id));
            }
        } catch (error) {
            console.log("error", error);
        }
    }


    const res = async () => {
        try {
            const res = await usersApi.permissionRoles();
            setStpermission(res);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        res();
    }, []);

    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }

    return (
        <>
            {(localStorage.getItem("roleId") == ROLE_ID && checkStatus("active")) ? (
                <div style={{ padding: "10px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            padding: "15px ",
                            marginBottom: "7px",
                        }}
                    >
                        <BackButton
                            onClick={() => {
                                navigate("/dealer-user-role");
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: 600, px: 2 }}
                        >
                            {state?.id ? "Edit Role" : "Add Role"}
                        </Typography>
                    </Box>

                    <Formik
                        initialValues={initialValues}
                        onSubmit={addRoles}
                        enableReinitialize={true}
                    >
                        {({
                            values,
                            errors,
                            setFieldValue,
                            touched,
                            handleChange
                        }) => (
                            <Form>
                                <div className={style["container"]}>
                                    <div className={style["content-container"]}>
                                        <div className={style["input-wrapper"]}>
                                            <label>Role Name</label>
                                            <InputField
                                                value={values.name}
                                                name="name"
                                                className={style["input"]}
                                                hint="Enter role name"
                                                required
                                                handleChange={handleChange}
                                                error={touched?.role_name && errors?.role_name}
                                            />
                                        </div>
                                        <div className={style["input-wrapper"]}>
                                            <label>Parent Roles</label>
                                            <MultiSelect
                                                name="parent_role_id"
                                                handleChange={(e) => {
                                                    fetchDefaultPermission(e.value, setFieldValue);
                                                    setFieldValue("parent_role_id", e.value);
                                                }}
                                                items={parentRoleList.map(item => ({
                                                    label: item?.name,
                                                    value: item?.id
                                                }))}
                                                // showCrossIcon={false}
                                                minWidth="150px"
                                                selectedItems={values?.parent_role_id}
                                                hint={"parent role"}
                                                containerStyle={style["selectable-button-container-style"]}
                                                selectClass={style["filterDropDown"]}
                                            />
                                        </div>
                                        <div className={style["input-wrapper"]}>
                                            {state?.status && (
                                                <>
                                                    <p className={style["radio-wrapper-text"]}>
                                                        Status{" "}
                                                        <span className={style["radio-wrapper-sub-text"]}>*</span>
                                                    </p>
                                                    <div className={style["radio-input"]}>
                                                        <div className={style["radio-sub-input"]}>
                                                            <input
                                                                type="radio"
                                                                checked={
                                                                    values.status === "active" || values.status == 1
                                                                }
                                                                onChange={() => setFieldValue("status", 1)}
                                                                name="status"
                                                                id="active"
                                                            />
                                                            <p>Active</p>
                                                        </div>
                                                        <div className={style["radio-sub-input"]}>
                                                            <input
                                                                type="radio"
                                                                checked={
                                                                    values.status === "inactive" || values.status == 0
                                                                }
                                                                onChange={() => setFieldValue("status", 0)}
                                                                name="status"
                                                                id="inactive"
                                                            />
                                                            <p>Inactive</p>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                    </div>

                                    <div className={style["accordion-container"]}>
                                        <div className={style["heading-wrapper"]}>
                                            <p className={style["heading-text"]}>Modules</p>
                                            <p className={style["heading-text"]}>Permissions</p>
                                        </div>
                                        {Object.entries(permissionList).map(([key, value], index) => (
                                            <div
                                                className={`${style["accordion-wrapper"]} accordion accordion-flush`}
                                                id="accordionFlushExample"
                                            >
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="flush-headingOne">
                                                        <button
                                                            className={`accordion-button ${accordionOpen == null && "collapsed"
                                                                }`}
                                                            type="button"
                                                            style={{
                                                                backgroundColor: "#cfe2ff", outline: "none", outline: "none",
                                                                boxShadow: "none"
                                                            }}
                                                            data-bs-toggle="collapse"
                                                            data-bs-target="#flush-collapseOne"
                                                            aria-expanded="false"
                                                            aria-controls="flush-collapseOne"
                                                            onClick={() => toggleAccordion(index)}
                                                        >
                                                            <p className={style["heading"]}>{key}</p>
                                                        </button>
                                                    </h2>
                                                    <div
                                                        id="flush-collapseOne"
                                                        className={`accordion-collapse collapse ${accordionOpen == index && "show"
                                                            }`}
                                                        aria-labelledby="flush-headingOne"
                                                        data-bs-parent="#accordionFlushExample"
                                                    >
                                                        <div className={`accordion-body`}>
                                                            {value?.submodules?.map((ele, index) => {
                                                                return (
                                                                    <div className={style["check-box-container"]}>
                                                                        <p className={style["check-box-text"]}>
                                                                            {ele?.name}
                                                                        </p>
                                                                        <div className={style["check-box-sub-container"]}>
                                                                            {ele?.permissions?.map((element, index) => {
                                                                                return (
                                                                                    <div className={style["check-box"]}>
                                                                                        <CheckBox
                                                                                            checked={values?.permissions?.includes(
                                                                                                element?.id
                                                                                            )}
                                                                                            onChange={() =>
                                                                                                handlePermissionCheck(
                                                                                                    element?.id,
                                                                                                    values,
                                                                                                    setFieldValue,
                                                                                                    element?.action,
                                                                                                    ele?.permissions
                                                                                                )
                                                                                            }
                                                                                        ></CheckBox>
                                                                                        <p>{element?.action}</p>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                            {!value?.submodules?.length && (
                                                                <div className={style["check-box-nocontainer"]}>
                                                                    <p
                                                                        className={`${style["check-box-notext"]} text-danger text-center font-weight-bold `}
                                                                    >
                                                                        No Submodule Found.
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className={style["action-container"]}>
                                        <Button
                                            type="button"
                                            onClick={() => navigate("/dealer-user-role")}
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}

                                        >
                                            Back
                                        </Button>

                                        <Button
                                            sx={{
                                                backgroundColor: "#121b5b",
                                                color: "#fff",
                                                "&:hover": {
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                },
                                            }}
                                            type="submit">Save</Button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>

            )}
        </>
    );
};

export default CreateRoles;
