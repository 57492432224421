import { Box, Typography } from "@mui/material";
import style from "../faqs-management/faqsList.module.scss";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import RoleTable from "../../components/tables/RolesTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchDealerRoles } from "../../store/roles/roleAction";
import { ROLE_ID } from "../../config";
import usersApi from "../../api/usersApi";
import { UserDealerPermission } from "../../components/permissions";

export const DealerUsersRoles = () => {
    const dispatch = useDispatch();
    const { data, totalCount } = useSelector((state) => state?.roleReducers?.roleList || []);
    const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);
    const navigate = useNavigate();
    const [filter, setFilter] = useState({
        role_name: "",
        status: ""
    })
    const [permission, setPermission] = useState([]);
    const findData = permission?.filter(item => item?.slug === "user");

    const getDealerusersRoles = async () => {
        try {
            let payload = Object.fromEntries(Object.entries(filter).filter(([key, value]) => value !== ""));
            await dispatch(fetchDealerRoles(payload));
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getDealerusersRoles();
    }, [])



    const applyFilter = async () => {
        await getDealerusersRoles();
    };

    const resetFilter = async () => {
        setFilter({ status: "", role_name: "" });
        await dispatch(fetchDealerRoles());
    };

    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }



    return (
        <>
            {(localStorage.getItem("roleId") == ROLE_ID && checkStatus("active")) ? (
                <div style={{ padding: "10px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "15px 0",
                        }}
                    >
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: 600, px: 2 }}
                        >
                            Roles ({totalCount})
                        </Typography>
                    </Box>

                    <div className={style["filters-container"]}>
                        <div className={style["filters-wrapper"]}>
                            <div className={style['search-baar']}>
                                <SearchBar
                                    placeholder={"Search Role Name"}
                                    value={filter?.role_name}
                                    handleChange={(e) => setFilter((prev) => ({ ...prev, role_name: e.target.value }))}
                                />
                            </div>
                            <div className={style['selectable-button']}>
                                <SelectableButton
                                    trailingIcon={R.ic_arrow_down}
                                    containerStyle={style["selectable-button-container-style"]}
                                    handleChange={(e) => {
                                        setFilter({ ...filter, status: e.target.value });
                                    }}
                                    items={[
                                        { label: "Active", value: "1" },
                                        { label: "Inactive", value: "0" },
                                    ]}
                                    hint={"status"}
                                    value={filter.status || ""}
                                />
                            </div>

                            <PrimaryButton
                                containerStyle={style["add-new-car-button-container-style"]}
                                name={" Filter"}
                                fontSize={"1rem"}
                                onClick={applyFilter}
                            />

                            <PrimaryButton
                                containerStyle={style["add-new-car-button-container-style"]}
                                name={"Reset"}
                                fontSize={"1rem"}
                                onClick={resetFilter}
                            />
                        </div>
                        <div className={style["action-buttons-wrapper"]}>
                            <PrimaryButton
                                containerStyle={style["add-blogs-button"]}
                                leadingIcon={R.ic_add_white}
                                name={"Add New Role"}
                                fontSize={"1rem"}
                                linkTo={"/create-role"}
                                onClick={() => navigate("/create-role")}
                            />
                        </div>
                    </div>
                    <RoleTable
                        data={data}
                    />
                </div>
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>
            )}
        </>
    )
}