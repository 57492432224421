import { dealerAppAuth, dealerAppDocumentUpload } from ".";
class DocumentVaultApi {
    getDealerUploadDocument(param) {
        const token = localStorage.getItem("acccessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app/get-dealer-document-list",
                        param,
                        null,
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }

    masterDocument() {
        const token = localStorage.getItem("acccessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app/dealers-master-document",
                        null,
                        null,
                        token
                    )
                    resolve(res);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }

    dealerDocumentUpload(data) {
        const token = localStorage.getItem("acccessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppDocumentUpload(
                        "post",
                        "dealer-app/dealers-upload-documents",
                        null,
                        data,
                        token
                    )
                    resolve(res);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }

    dropDocument(data) {
        const token = localStorage.getItem("acccessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "put",
                        "dealer-app/deleteDocuments",
                        null,
                        data,
                        token
                    )
                    resolve(res);
                } catch (error) {
                    reject(error)
                }
            })();
        })
    }

    motorpediaTemplate(param) {
        const token = localStorage.getItem("acccessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppDocumentUpload(
                        "get",
                        "dealer-app/sample-document-template",
                        param,
                        null,
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }


}

export default new DocumentVaultApi();