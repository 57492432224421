import React, { useState, useEffect } from "react";
import style from "./carManagement.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
// import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import CarDetails from "./car-details/CarDetails";
import DetailTable from "../../components/tables/DetailTable";
// import getData from "../../components/services/apiServices";
import { useDispatch, useSelector } from "react-redux";
import { getCarList } from "../../store/car/carAction";
import { publicApi } from "../../api/publicApi";
import { useNavigate } from "react-router-dom";
import MultiSelect from "../../components/buttons/multiSelect";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import { carApi } from "../../api/carApi";
import { showToast } from "../../components/toaster/toaster";
import { ROLE_ID } from "../../config.js";
import usersApi from "../../api/usersApi.js";
import { UserDealerPermission } from "../../components/permissions/index.js";

export default function CarManagement() {
  // const dispatch = useDispatch();

  const [filter, setFilter] = useState({
    vehicle_id: "",
    make: "",
    model_id: "",
    varientName: "",
    store: "",
    status: "1",
    dealerId: localStorage.getItem("dealerId"),
    is_website: 0,
    is_motorpedia: 0,
    is_not_listed: 0,
  });


  const [brandList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [varientList, setVarientList] = useState([]);
  const [reset, setReset] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState();
  const [totalCount, setTotalCount] = useState();
  const [hasNextPage, setHasNextPage] = useState();
  const [storeList, setStoreList] = useState([]);
  const [carList, setCarList] = useState([]);
  const [permission, setPermission] = useState([]);
  const { checkStatusPermisssion, error } = UserDealerPermission(16);
  const [modelArrayList, setmodelArrayList] = useState([]);
  const dispatch = useDispatch();

  const findData = permission?.filter(item => item?.slug === "manage_inventory");
  const ManagePnl = permission?.filter(item => item?.slug === "mange_pnl");


  const [data, setData] = useState([]);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const store = async () => {
    try {
      const res = await carApi.dealerAddress({
        dealer_id: localStorage.getItem("dealerId"),
      });
      setStoreList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const models = async () => {
    try {
      const res = await publicApi.modelList({ make: filter?.make, is_group_model: 1 });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  const varients = async () => {
    try {
      const res = await publicApi.varientList({ model: filter?.model_id });
      setVarientList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };


  const getModelData = async () => {
    try {
      if (filter?.model_id) {
        const res = await publicApi.getModelData({ group_id: filter?.model_id });
        setmodelArrayList(res?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchFilteredCarList(page);
  };

  const fetchFilteredCarList = (page) => {
    const makeArray = Array.isArray(filter?.make) ? filter.make : [filter.make].filter(Boolean);
    const modelArray = filter.model_id ? Array.isArray(modelArrayList) ? modelArrayList : modelArrayList.split(',').map(Number) : "";

    const storeArray = Array.isArray(filter?.store)
      ? filter.store
      : [filter.store].filter(Boolean);

    getCarList({
      reg_no: filter?.vehicle_id || "",
      make: makeArray.length ? makeArray : undefined,
      model_id: modelArray.length ? modelArray : undefined,
      store: storeArray.length ? storeArray : undefined,
      is_website: filter?.is_website,
      is_motorpedia: filter?.is_motorpedia,
      is_not_listed: filter?.is_not_listed,
      page: page ? page : currentPage,
      car_status: filter?.status || "1",
      dealerId: +filter?.dealerId || "",
      showroom_id: filter?.store,

    })
      .then((response) => {
        setCarList(response.payload.data);
        setCurrentPage(response.payload.currentPage);
        setTotalPageCount(response.payload.totalPageCount);
        console.log("totalPageCount", response.payload.totalPageCount);
        setTotalCount(response.payload.totalCount);
        setHasNextPage(response.payload.hasNextPage);
      })
      .catch((error) => {
        console.error("Error fetching filtered car list:", error);
      });
  };

  useEffect(() => {
    brands();
    store();
  }, []);

  useEffect(() => {
    if (filter?.make) models();
  }, [filter?.make]);

  useEffect(() => {
    if (filter?.model_id) getModelData();
  }, [filter?.model_id]);

  useEffect(() => {
    setData(carList);
  }, [carList]);

  const applyFilter = () => {
    if (Object.values(filter).some((value) => value !== "")) {
      fetchFilteredCarList();
    }
  };

  const resetFilter = () => {
    setmodelArrayList();
    setFilter({
      vehicle_id: "",
      make: "",
      model_id: "",
      varientName: "",
      store: "",
      status: "all",
      showroom_id: "",
      dealerId: localStorage.getItem("dealerId"),
    });
    fetchFilteredCarList();
    setReset(true);
  };

  useEffect(() => {
    if (reset) fetchFilteredCarList();
  }, [reset]);

  useEffect(() => {
    fetchFilteredCarList();
  }, []);

  const [isDetailPageVisible, setIsDetailPageVisible] = useState(false);
  const navigate = useNavigate();

  const handleCheckboxChange = (checkboxValue) => {
    setFilter((prev) => {
      let updatedFilter = { ...prev };

      if (checkboxValue === "Website") {
        updatedFilter.is_website = updatedFilter.is_website === 0 ? 1 : 0;
        if (updatedFilter.is_website === 1) updatedFilter.is_not_listed = 0;
      }

      if (checkboxValue === "Motorpedia") {
        updatedFilter.is_motorpedia = updatedFilter.is_motorpedia === 0 ? 1 : 0;
        if (updatedFilter.is_motorpedia === 1) updatedFilter.is_not_listed = 0;
      }

      if (checkboxValue === "Not Listed") {
        updatedFilter.is_not_listed = updatedFilter.is_not_listed === 0 ? 1 : 0;
        if (updatedFilter.is_not_listed === 1) {
          updatedFilter.is_website = 0;
          updatedFilter.is_motorpedia = 0;
        }
      }

      return updatedFilter;
    });
  };


  const hasPermission = (action) => {
    return findData?.some(perm => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }
  const hasRolePermission = (action) => {
    return ManagePnl?.some((perm) => perm?.actions?.split(",").includes(action));
  }

  const handleClick = () => {
    if (hasPermission("Add") || localStorage.getItem("roleId") == ROLE_ID) {
      navigate("/add-new-car");
    } else {
      showToast("error", "You have not permission add Inventory")
    }
  };

  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log("Error:", error.message);
    }
  }

  useEffect(() => {
    res();
  }, []);

  return (
    <>
      {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["container"]}>
          {isDetailPageVisible && (
            <CarDetails
              onClick={() => setIsDetailPageVisible(!isDetailPageVisible)}
            />
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Inventory ({totalCount})
            </Typography>
            <Box sx={{ px: 1.5 }}>
              <PrimaryButton
                containerStyle={style["add-new-car-button-container-style"]}
                leadingIcon={R.ic_add_white}
                name={"Add New Car"}
                fontSize={"1rem"}
                // linkTo={"/add-new-car"}
                onClick={handleClick}
              />
            </Box>
          </Box>
          <div className={style["filters-container"]}>
            <div className={style["filters-wrapper"]}>
              <SearchBar
                placeholder={"Reg No / SKU Id"}
                value={filter?.vehicle_id}
                handleChange={(e) =>
                  setFilter({ ...filter, vehicle_id: e.target.value })
                }
                name="vehicle_id"
              />

              <MultiSelect
                name="make"
                handleChange={(e) =>
                  setFilter({ ...filter, make: e?.value, model_id: null })
                }
                items={brandList?.map((ele) => ({
                  label: ele?.makeName,
                  value: ele?.makeId,
                }))}
                selectedItems={filter?.make}
                hint={"All Brand"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />

              <MultiSelect
                name="model_id"
                handleChange={(e) => setFilter({ ...filter, model_id: e?.value })}
                items={modelList?.map((ele) => ({
                  label: ele?.groupModelName,
                  value: ele?.groupId,
                }))}
                selectedItems={filter?.model_id}
                hint={"All Model"}
                isSearchable={true}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />

              {/* <MultiSelect
            name="varientName"
            handleChange={(e) =>
              setFilter({ ...filter, varientName: e?.value })
            }
            items={varientList?.map((ele) => ({
              label: ele?.variantName,
              value: ele?.variantId,
            }))}
            selectedItems={filter?.varientName}
            hint={"All Variant"}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          /> */}

              <MultiSelect
                name="status"
                handleChange={(e) => setFilter({ ...filter, status: e?.value })}
                items={[
                  { label: "All", value: "all" },
                  { label: "Active", value: "1" },
                  { label: "Inactive", value: "0" },
                  { label: "Booked", value: "2" },
                  { label: "Sold", value: "3" },
                ]}
                selectedItems={filter?.status}
                hint={"All Status"}
                containerStyle={style["selectable-button-container-style"]}
                selectClass={style["filterDropDown"]}
              />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            onClick={() => applyFilter()}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => resetFilter()}
          />
        </div>

        <div></div>

        <div className={style["listed-box"]}>
          <div className={style["filters-wrapper"]}>
            <MultiSelect
              name="Store"
              handleChange={(e) => setFilter({ ...filter, store: e?.value })}
              items={storeList?.map((ele) => ({
                label: ele?.store_name,
                value: ele?.id,
              }))}
              selectedItems={filter?.store}
              placeholder={"All Outlets"}
              containerStyle={style["selectable-button-container-style"]}
              selectClass={style["filterDropDown"]}
            />

            <div className={style["listed-on-container"]}>
              <span className={style["listed-on-label"]}>Listed On:</span>
              <div className={style["checkbox-container"]}>
                <label>
                  <input
                    type="checkbox"
                    value="Website"
                    checked={filter.is_website === 1}
                    onChange={() => handleCheckboxChange("Website")}
                  />
                  <span className={style["checkbox-label"]}>Website</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    value="Motorpedia"
                    checked={filter.is_motorpedia === 1}
                    onChange={() => handleCheckboxChange("Motorpedia")}
                  />
                  <span className={style["checkbox-label"]}>Motorpedia</span>
                </label>

                <label>
                  <input
                    type="checkbox"
                    value="Not Listed"
                    checked={filter.is_not_listed === 1}
                    onChange={() => handleCheckboxChange("Not Listed")}
                  />
                  <span className={style["checkbox-label"]}>Not Listed</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

          <DetailTable data={data} hasPermission={hasPermission} hasRolePermission={hasRolePermission} setData={setData} />

          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={"20px"}
          >
            <Pagination
              count={totalPageCount}
              page={currentPage}
              onChange={(event, page) => handlePageChange(page)}
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>

      )}


    </>
  );
}
