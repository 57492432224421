export const INITIALIZE_THE_LOADER = "INITIALIZE_THE_LOADER";
export const STOP_THE_LOADER = "STOP_THE_LOADER";
export const INITIALIZE_THE_PAGE_LOADER = "INITIALIZE_THE_PAGE_LOADER";
export const STOP_THE_PAGE_LOADER = "STOP_THE_PAGE_LOADER";
export const INIT_SUCCESS = "INIT_SUCCESS";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const EDIT_BUYER_LEAD = "EDIT_BUYER_LEAD";
export const GET_BUYER_LEAD = "GET_BUYER_LEAD";
export const EDIT_SELLER_LEAD = "EDIT_SELLER_LEAD";
export const GET_SELLER_LEAD = "GET_SELLER_LEAD";
export const GET_CAR_LIST = "GET_CAR_LIST";
export const CREATE_CAR_DETAIL = "CREATE_CAR_DETAIL";
export const EDIT_CAR_DETAIL = "EDIT_CAR_DETAIL";
export const ADD_RTO_CASES_REQUEST = "ADD_RTO_CASES_REQUEST";
export const EDIT_RTO_CASES_REQUEST = "EDIT_RTO_CASES_REQUEST";
export const GET_RTO_CASES_REQUEST = "GET_RTO_CASES_REQUEST";
export const ADD_BASIC_DETAILS = "ADD_BASIC_DETAILS";
export const ADD_BANK_DETAILS = "ADD_BANK_DETAILS";
export const ADD_CREDIT_DETAILS = "ADD_CREDIT_DETAILS";
export const ADD_KYC_DETAILS = "ADD_KYC_DETAILS";
export const GET_DEALER_LIST = "GET_DEALER_LIST";
export const UPDATE_BASIC_DETAILS = "UPDATE_BASIC_DETAILS";
export const GET_CREDIT_DETAILS = "GET_CREDIT_DETAILS";
export const GET_WALLET_AMOUNT = "GET_WALLET_AMOUNT";
export const GET_WALLET_COIN = "GET_WALLET_COIN";
export const RESET_DEALER = "RESET_DEALER";
export const GET_DEALER_QR_CODE = "GET_DEALER_QR_CODE";
export const ADD_DEALER_QR_CODE = "ADD_DEALER_QR_CODE";
export const UPDATE_DEALER_QR_CODE = "UPDATE_DEALER_QR_CODE";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const GET_USER = "GET_USER";
export const GET_USER_DROPDOWN = "GET_USER_DROPDOWN";
export const EDIT_INQUIRE_LIST = "EDIT_INQUIRE_LIST";
export const GET_DEALER_ADDRESS = "GET_DEALER_ADDRESS";
export const GET_DEALER_USERS = "GET_DEALER_USERS";
export const GET_ALL_DEALERS = "GET_ALL_DEALERS";
export const GET_ALL_PURCHESAR = "GET_ALL_PURCHESAR";
export const GET_ALL_SOURCE = "GET_ALL_SOURCE";
export const ADD_FAQS_PAGE="ADD_FAQS_PAGE";
export const GET_FAQS_LIST = "GET_FAQS_LIST";
export const RESET_FAQS="RESET_FAQS";
export const ADD_DYNAMIC_PAGE="ADD_DYNAMIC_PAGE";
export const GET_DYNAMIC_PAGE="GET_DYNAMIC_PAGE";
export const GET_USERS="GET_USERS";
export const SAVE_USERS="SAVE_USERS";
export const GET_BLOGS = "GET_BLOGS";
export const SAVE_BLOGS = "SAVE_BLOGS";
export const GET_ROLE = "GET_ROLE";
export const ADD_ROLE = "ADD_ROLE";
export const EDIT_ROLE = "EDIT_ROLE";
export const GET_PERMISSION = "GET_PERMISSION";
export const GET_ROLE_PERMISSION="GET_ROLE_PERMISSION";
export const GET_PARENT_ROLE="GET_PARENT_ROLE";
export const GET_USER_PERMISSION="GET_USER_PERMISSION";





export const DOCUMENT_TYPE_LIST = "DOCUMENT_TYPE_LIST";
export const UPLOAD_DEALER_DOCUMENT_LIST = "UPLOAD_DEALER_DOCUMENT_LIST";
export const RESET_UPLOADED_LIST = "RESET_UPLOADED_LIST";
export const GET_TEMPLATE_LIST = "GET_TEMPLATE_LIST";


