import { Formik, Form } from "formik";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import style from "./style.module.scss";
import { carApi } from "../../../../api/carApi";
import { toast } from "react-toastify";
import { GetPnlData } from "../../../../store/car/carAction";
import { useEffect, useState } from "react";
import MultiSelect from "../../../../components/buttons/multiSelect";
import InputField from "../../../../components/input-field/InputField";
import { formatIndianCurrency, formatIndianOnlyProfit, formatDate } from "../../../../utils/PrivateRoutes"
import validationSchema from "../../../../components/validation/validationSchema";
import ErrorMessage from "../../../../components/input-field/ErrorMessage";
import { getBuyerList } from "../../../../store/buyerLead/buyerLeadAction";
import { fetchDealerAddress, fetchPurchesar, fetchSource, fetchDealerUsers, fetchAllDealers } from "../../../../store/car/carAction";
import { useDispatch, useSelector } from "react-redux";
export function SoldManagement(
  {
    openSold,
    handleSoldClose,
    soldCarId,
    sqlId,
    setData,
    leadOpenPnl,
    purchaseSeen,
    setPurchaseValue,
    setRefValu,
    setOtherValue,
    setSoldAmountValue,
    profit,
    soldValue,
  }
) {
  const dispatch = useDispatch();

  const { dealerAddress, dealerUsers, carDealers, purchaser, source } =
    useSelector((state) => state?.car || []);

  const buyerLeadList = useSelector(
    (state) => state?.buyerLead?.buyerLeadList || []
  );

  useEffect(() => {
    dispatch(fetchDealerAddress());
    dispatch(fetchPurchesar());
    dispatch(fetchAllDealers());
    dispatch(fetchSource());
    dispatch(fetchDealerUsers());
    dispatch(getBuyerList({ leadStatus: `[${[1, 10].join(",")}]` }));
  }, [dispatch])


  const [soldData, setSoldData] = useState([]);
  const users = localStorage.getItem('userid');

  const initialValues = {
    sales_poc: soldData?.sales_poc || 0,
    sales_store_id: soldData?.sales_store_id || 0,
    sold_date: soldData?.sold_date || "",
    sold_amount: soldData?.sold_amount || "",
    sold_to: soldData?.sold_to || "",
    sold_lead_id: soldData?.sold_lead_id || 0,
    contact_dealer_id: soldData?.contact_dealer_id || 0,
    purchaser: soldData?.purchaser || 0,
    source: soldData?.source || 0,
    purchase_amount: soldData?.purchase_amount || 0,
    purchase_date: soldData?.purchase_date || "",
    others_cost: soldData?.others_cost || 0,
    booking_amount: soldData?.booking_amount || 0,
    refurbisment_cost: soldData?.refurbisment_cost || 0,
    created_by: +(users) || 0,
    updated_by: +(users) || 0,
    booking_date: soldData?.booking_date || ""
  };


    async function handleSubmit(values) {
        try {
            let payload;
            const { sold_lead_id, sold_amount, purchase_amount, booking_amount, others_cost, refurbisment_cost, ...restValues } = values;
            const numericSoldAmount = sold_amount ? parseFloat(sold_amount.toString().replace(/[^0-9.-]/g, '')) : null;
            const numericBookingAmount = booking_amount ? parseFloat(booking_amount.toString().replace(/[^0-9.-]/g, '')) : null;
            const numaricPurchaseAmount = purchase_amount ? parseFloat(purchase_amount.toString().replace(/[^0-9.-]/g, '')) : null;
            const numaricOtherCoast = others_cost ? parseFloat(others_cost.toString().replace(/[^0-9.-]/g, '')) : null;
            const numaricRefCoast = refurbisment_cost ? parseFloat(refurbisment_cost.toString().replace(/[^0-9.-]/g, '')) : null;

      if (sold_lead_id) {
        const selectedBuyerLead = buyerLeadList.find(
          (lead) => lead.leadId === sold_lead_id
        );

                if (!selectedBuyerLead) {
                    toast.error("Buyer lead not found.");
                    return;
                }

                delete restValues.contact_dealer_id;

        payload = {
          ...restValues,
          car_id: soldCarId,
          customer_id: selectedBuyerLead.customerId,
          sold_lead_id: selectedBuyerLead.leadId,
          sold_cutomer_name: selectedBuyerLead.name,
          customer_mobile: selectedBuyerLead.mobile,
          sold_amount: numericSoldAmount,
          booking_amount: numericBookingAmount,
          purchase_amount: numaricPurchaseAmount,
          others_cost: numaricOtherCoast,
          refurbisment_cost: numaricRefCoast,
        };
        if (soldValue) {
          payload.action = soldValue === "2" ? "booking" : "sold";
        }
      } else {
        payload = {
          ...restValues,
          car_id: soldCarId,
          sold_amount: numericSoldAmount,
          purchase_amount: numaricPurchaseAmount,
          others_cost: numaricOtherCoast,
          refurbisment_cost: numaricRefCoast,
          booking_amount: numericBookingAmount,
        };
        if (soldValue) {
          payload.action = soldValue === "2" ? "booking" : "sold";
        }
      }



      payload = Object.fromEntries(
        Object.entries(payload).filter(([key, value]) => value !== undefined && value !== null && value !== '')
      );


            let response = await carApi.saveSoldDetails(payload);
            if (response?.status === false) {
                return toast.error(response?.message[0])
            }

      if ((soldValue === "3" || soldValue === "2") && response?.status === true) {
        setData((prevData) =>
          prevData.map((car) =>
            car.carSkuId === sqlId ? { ...car, car_status: soldValue } : car
          )
        );
      }
      handleSoldClose();
      toast.success("Record updated successfully!");
    } catch (error) {
      console.error(error.message);
      toast.error(error.message);
    }
  }


    async function panelData() {
        try {
            const updateData = {
                car_id: soldCarId
            }
            const response = await GetPnlData(updateData);
            setSoldData(response?.data)
        } catch (error) {
            console.log(error.message)
        }
    }

    useEffect(() => {
        if (soldCarId) {
            panelData();
        }
    }, [soldCarId])


  return (
    <Dialog
      open={openSold}
      onClose={handleSoldClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#121b5b",
          color: "white",
          fontWeight: "600",
          marginBottom: "20px",
        }}
        id="responsive-dialog-title"
        color="purple"
      >
        {leadOpenPnl === "1" ? "Sales Entry" : "Purchase Entry"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchema.soldManagement(
              leadOpenPnl || purchaseSeen || soldValue
            )}
            onSubmit={handleSubmit}
          >
            {({
              values,
              handleChange,
              handleBlur,
              setFieldValue,
              errors,
              touched,
            }) => {
              console.log("values", values);
              setPurchaseValue(
                Number(values?.purchase_amount?.toString().replace(",", ""))
              );
              setRefValu(
                Number(values?.refurbisment_cost?.toString().replace(",", ""))
              );
              setOtherValue(
                Number(values?.others_cost?.toString().replace(",", ""))
              );
              setSoldAmountValue(
                Number(values?.sold_amount?.toString().replace(",", ""))
              );
              return (
                <Form>
                  <div className={style["header-wrapper"]}>
                    <div className={style["left-side-wrapper"]}>
                      <div className={style["select-wrapper"]}>
                        <label>Purchaser</label>

                        <MultiSelect
                          name="purchaser"
                          items={purchaser.map((ele) => ({
                            label: ele.name,
                            value: ele.id
                          }))}
                          selectedItems={values.purchaser}
                          handleChange={(e) => {
                            setFieldValue('purchaser', e.value);
                          }}
                          hint="purchaser"
                          isSearchable={true}
                          className={style['select-desin']}
                          isDisabled={leadOpenPnl == "1"}
                        />

                      </div>
                      <div className={style['select-wrapper']}>
                        <label>Purchase Date</label>
                        <input
                          type="date"
                          name="purchase_date"
                          placeholder="MM/DD/YYYY"
                          value={formatDate(values.purchase_date)}
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ max: new Date().toISOString().split("T")[0] }}
                          onChange={(e) => {
                            setFieldValue("purchase_date", e.target.value);
                          }}
                          className={style['date-formater']}
                          isDisabled={leadOpenPnl == "1"}

                        />
                      </div>
                      <div className={style['select-wrapper']}>
                        <label>Other Cost</label>
                        <InputField
                          name="others_cost"
                          type="text"
                          length={10}
                          fullWidth
                          value={values.others_cost ? formatIndianCurrency(values.others_cost) : ""}
                          handleChange={handleChange}
                          margin="normal"
                          disabled={leadOpenPnl == "1"}
                        />
                      </div>

                      <div className={style['select-wrapper']}>
                        <label>Booking Amount (Advance Payment)</label>
                        <InputField
                          name="booking_amount"
                          type="text"
                          length={10}
                          fullWidth
                          value={values.booking_amount ? formatIndianCurrency(values.booking_amount) : ""}
                          handleChange={handleChange}
                          margin="normal"
                          disabled={leadOpenPnl == "1"}
                        />
                      </div>

                                            {purchaseSeen !== "1" && (
                                                <>
                                                    <div className={style['select-wrapper']}>
                                                        <label>Sold Date <span className={style['star']}>*</span></label>
                                                        <input
                                                            type="date"
                                                            name="sold_date"
                                                            value={formatDate(values.sold_date)}
                                                            onChange={(e) => {
                                                                setFieldValue("sold_date", e.target.value);
                                                            }}
                                                            className={style['date-formater']}
                                                        />
                                                        <ErrorMessage
                                                            errorMessage={errors?.sold_date && errors?.sold_date}
                                                            requiredError={
                                                                errors?.sold_date && errors?.sold_date
                                                            }
                                                        />
                                                    </div>

                                                    <div className={style['select-wrapper']}>
                                                        <label>Source</label>
                                                        <MultiSelect
                                                            name="source"
                                                            items={source.map((ele) => ({
                                                                label: ele.name,
                                                                value: ele.id
                                                            }))}
                                                            selectedItems={values.source}
                                                            handleChange={(e) => {
                                                                setFieldValue('source', e.value);
                                                            }}
                                                            placeholder="source"
                                                            className={style['select-desin']}
                                                            isSearchable={true}
                                                        />
                                                    </div>


                          <div className={style['select-wrapper']}>
                            <label>Sold To <span className={style['star']}>*</span></label>
                            <MultiSelect
                              name="sold_to"
                              items={[
                                { label: "customer", value: "customer" },
                                { label: "dealer", value: "dealer" },
                                ...(localStorage.getItem("dealerId") === "1324" ? [{ label: "Hyperlocal Customer", value: "Hyperlocal Customer" }] : [])
                              ]}
                              selectedItems={values.sold_to}
                              handleChange={(e) => {
                                const selectedValue = e.value;
                                setFieldValue("sold_to", selectedValue);
                                if (selectedValue === "customer") {
                                  setFieldValue("contact_dealer_id", "");
                                } else if (selectedValue === "dealer") {
                                  setFieldValue("sold_lead_id", "");
                                }
                              }}
                              hint="Select sold To"
                              isSearchable={true}
                              containerStyle={style["selectable-button-container-style"]}
                              selectClass={style["filterDropDown"]}
                              className={style['select-desin']}
                            />
                            <ErrorMessage
                              errorMessage={errors?.sold_to && errors?.sold_to}
                              requiredError={
                                errors?.sold_to && errors?.sold_to
                              }
                            />

                          </div>

                          {leadOpenPnl === "1" && (
                            <div className={style['select-wrapper']}>
                              <label>PnL Amount </label>
                              <InputField
                                name="profit"
                                hint="Select sold To"
                                value={profit ? formatIndianOnlyProfit(profit) : ""}
                                disabled={true}
                              />
                            </div>
                          )}

                        </>
                      )}
                    </div>
                    <div className={style['right-side-wrapper']}>
                      <div className={style['select-wrapper']}>
                        <label>Purchase Amount </label>
                        <InputField
                          name="purchase_amount"
                          type="text"
                          length={10}
                          fullWidth
                          value={values.purchase_amount ? formatIndianCurrency(values.purchase_amount) : ""}
                          handleChange={handleChange}
                          margin="normal"
                          className={style['text-wrapper']}
                          disabled={leadOpenPnl == "1"}
                        />
                      </div>

                                            <div className={style['select-wrapper']}>
                                                <label>Refurbishment Amount </label>
                                                <InputField
                                                    name="refurbisment_cost"
                                                    type="text"
                                                    length={10}
                                                    fullWidth
                                                    value={values.refurbisment_cost ? formatIndianCurrency(values.refurbisment_cost) : ""}
                                                    handleChange={handleChange}
                                                    margin="normal"
                                                    disabled={leadOpenPnl == "1"}
                                                />
                                            </div>

                      {purchaseSeen !== "1" && (
                        <>
                          <div className={style['select-wrapper']}>
                            <label>Booking Date</label>
                            <input
                              type="date"
                              name="booking_date"
                              value={formatDate(values.booking_date)}
                              onChange={(e) => {
                                setFieldValue("booking_date", e.target.value);
                              }}
                              className={style['date-formater']}
                            />
                          </div>
                          <div className={style['select-wrapper']}>
                            {soldValue == 2 ? "Deal Amount" : "Sold Amount"}
                            <InputField
                              name="sold_amount"
                              type="text"
                              length={10}
                              fullWidth
                              value={values.sold_amount ? formatIndianCurrency(values.sold_amount) : ""}
                              handleChange={handleChange}
                              margin="normal"
                              error={touched?.sold_amount && errors?.sold_amount}
                              helperText={errors?.sold_amount && errors?.sold_amount}
                            />
                            <ErrorMessage
                              errorMessage={errors?.sold_amount && errors?.sold_amount}
                              requiredError={
                                errors?.sold_amount && errors?.sold_amount
                              }
                            />

                          </div>
                          <div className={style['select-wrapper']}>
                            <label>Select user</label>

                            <div className={style['multi-slect']}>
                              <MultiSelect
                                name="sales_poc"
                                items={dealerUsers.map((ele) => ({
                                  label: `${ele.firstName} (${ele.roleName})`,
                                  value: ele.id
                                }))}
                                selectedItems={values.sales_poc}
                                handleChange={(e) => {
                                  setFieldValue('sales_poc', e.value);
                                  console.log("hiiii ==>", e.value)
                                }}
                                hint="Select user"
                                isSearchable={true}
                                className={style['slected-itms']}
                              />
                            </div>

                          </div>
                          <div className={style['select-wrapper']}>
                            <label>Outlet</label>
                            <MultiSelect
                              name="sales_store_id"
                              items={dealerAddress.map((ele) => ({
                                label: ele.store_name,
                                value: ele.id
                              }))}
                              selectedItems={values.sales_store_id}
                              handleChange={(e) => {
                                setFieldValue('sales_store_id', e.value);
                              }}
                              hint="Select store name"
                              isSearchable={true}
                              className={style['select-desin']}
                            />
                          </div>

                          {values.sold_to === "customer" && (
                            <div className={style['select-wrapper']}>
                              <label>Customer</label>
                              <MultiSelect
                                name="sold_lead_id"
                                items={buyerLeadList.filter(item => item?.mobile && item?.name).map(res => ({
                                  label: `${res?.mobile} - ${res?.name}`,
                                  value: res?.leadId
                                }))}
                                selectedItems={values.sold_lead_id}
                                handleChange={(e) => {
                                  setFieldValue('sold_lead_id', e.value);
                                }}
                                hint="Select Customer"
                                isSearchable={true}
                                className={style['select-desin']}
                              />
                            </div>
                          )}
                          {values.sold_to === "dealer" && (
                            <div className={style['select-wrapper']}>
                              <label>Dealer</label>
                              <MultiSelect
                                name="contact_dealer_id"
                                items={carDealers.map((ele) => ({
                                  label: `(${ele.dealership_name} - ${ele.contact_mobile}) `,
                                  value: ele.id
                                }))}
                                selectedItems={values.contact_dealer_id}
                                handleChange={(e) => {
                                  setFieldValue('contact_dealer_id', e.value);
                                }}
                                hint="Select Dealer "
                                isSearchable={true}
                                className={style['select-desin']}
                              />
                            </div>
                          )}
                        </>
                      )}

                    </div>
                  </div>

                                    <DialogActions>
                                        <Button
                                            autoFocus
                                            className={style["save-button"]}
                                            onClick={handleSoldClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            type="submit"
                                            className={style["save-button"]}
                                            autoFocus
                                        >
                                            Save
                                        </Button>
                                    </DialogActions>
                                </Form>
                            );
                        }}
                    </Formik>
                </DialogContentText>
            </DialogContent>
        </Dialog>
    );
}
