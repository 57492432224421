import React, { useEffect, useRef, useState } from "react";
import style from "./style.module.scss";
import JoditEditor from "jodit-react";
import BackButton from "../../../components/buttons/BackButton";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading";
import InputField from "../../../components/input-field/InputField";
import { IoEyeSharp, IoCloseSharp } from "react-icons/io5";
import { blogs } from "../../../api/blogs";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import axios from "axios";
import { saveBlogs } from "../../../store/blogs/blogsAction";
import { ROLE_ID } from "../../../config";
import usersApi from "../../../api/usersApi";
import { UserDealerPermission } from "../../../components/permissions";



function CreateBlogs() {
  const [fileName, setFileName] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  const [bannerUrl, setBannerUrl] = useState(null);

  const { total } = useSelector((state) => state.buyerLead);
  const dealerId = localStorage.getItem("dealerId");
  const dealer_id = dealerId;
  const location = useLocation();
  const { state } = location.state || {};
  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "blogs");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const editor = useRef(null);
  const { checkStatusPermisssion, error } = UserDealerPermission(20);
  const [formData, setFormData] = useState({
    id: state?.id || null,
    title: state?.title || "",
    author: state?.author || "",
    dealer_id: dealerId,
    tags: state?.tags || "",
    description: state?.description || "",
    blog_banner: state?.blog_banner || "",
    blog_logo: state?.blog_logo || "",
    slugs: state?.slugs || "",
  });


  const handleFileChange = async (e) => {
    try {
      const token = localStorage.getItem("accessToken");
      const file = e.target.files[0];
      const name = e.target.name;

      if (file) {
        const formDataUpload = new FormData();
        formDataUpload.append("file", file);
        formDataUpload.append("container", "blog");
        formDataUpload.append("id", dealer_id);

        const res = await axios.post(
          `${process.env.REACT_APP_SERVICE_HISTORY_URL}app/uploadFiles`,
          formDataUpload,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (res?.data?.status) {
          const uploadedImageUrl = res?.data?.fileUploadedLinks[0];

          setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: uploadedImageUrl,
          }));

          if (name === "thumbnail") {
            setFileName(file.name);
            setThumbnailUrl(uploadedImageUrl);
          } else if (name === "banner") {
            setBannerName(file.name);
            setBannerUrl(uploadedImageUrl);
          }
        }
      }
    } catch (err) {
      console.error("File Upload Error:", err);
      toast.error("File upload failed. Please try again.");
    }
  };


  const handleRemoveFile = (fileType) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fileType]: null,
    }));

    if (fileType === "thumbnail") {
      setFileName("");
      setThumbnailUrl(null);
    } else if (fileType === "banner") {
      setBannerName("");
      setBannerUrl(null);
    }
  };

  const formateSpacesWithDash = (str) => {
    if (!str) return "";
    return str.trim().replace(/\s+/g, "-").toLowerCase();
  };

  const handleSaveDraft = async (e) => {
    e.preventDefault();
    const filteredValues = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== undefined && value !== null && value !== "")
    );
    const payload = {
      ...filteredValues,
      status: "2"
    }

    dispatch(saveBlogs(payload)).then((res) => {
      navigate("/dealers-blogs");
    }).catch((err) => {
      console.log(err);
    })

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "slugs") {
      formattedValue = formateSpacesWithDash(value);
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: formattedValue,
    }));

    if (name === "title") {
      setFormData((prevData) => ({
        ...prevData,
        slugs: formateSpacesWithDash(value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filteredValues = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => value !== undefined && value !== null && value !== "")
    );
    const published_date = new Date()
      .toISOString()
      .slice(0, 19)
      .replace("T", " ");


    const payload = {
      ...filteredValues,
      status: "1",
      published_date: published_date
    }


    dispatch(saveBlogs(payload)).then((res) => {
      navigate("/dealers-blogs");
    }).catch((err) => {
      console.log(err);
    })

  };

  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }
  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    res();
  }, [])


  return (
    <>
      {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["container"]}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

              alignItems: "center",

              padding: "15px ",
            }}
          >
            <BackButton
              onClick={() => {
                navigate("/dealers-blogs");
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              {state?.id ? "Edit Blog" : "Add Blog"}
            </Typography>
          </Box>

          <Box className={style["form-wrapper"]}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Title"
                    variant="outlined"
                    name="title"
                    placeholder="Enter the title"
                    onChange={handleChange}
                    value={formData.title}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <TextField
                    size="small"
                    fullWidth
                    label="Slug"
                    variant="outlined"
                    name="slugs"
                    placeholder="Enter the slug"
                    onChange={handleChange}
                    value={formData.slugs}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    marginTop: "20px",
                  }}
                  sm={6}
                  md={6}
                >
                  <TextField
                    size="small"
                    fullWidth
                    label="Author"
                    variant="outlined"
                    name="author"
                    placeholder="Enter the author"
                    onChange={handleChange}
                    value={formData.author}
                  />
                </Grid>
              </Grid>
              <Grid
                sx={{
                  marginTop: "20px",
                }}
                item
                xs={12}
              >
                <Typography variant="h6">Description</Typography>
                <JoditEditor
                  ref={editor}
                  config={{ width: "100%", height: "420px" }}
                  value={formData.description}
                  onBlur={(newContent) =>
                    setFormData((val) => ({ ...val, description: newContent }))
                  }
                />
              </Grid>
              <Grid
                container
                marginTop={2}
                justifyContent={"space-between"}
                spacing={2}
              >
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: "300px",
                      width: "100%",
                      border: "2px dashed #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f9f9f9",
                      position: "relative",
                      transition: "all 0.3s",
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                        borderColor: "#007bff",
                      },
                    }}
                  >
                    {!formData?.blog_logo ? (
                      <>
                        <label
                          htmlFor="thumbnailInput"
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            sx={{ mb: 1 }}
                          >
                            Upload Thumbnail
                          </Typography>
                          <Button
                            variant="contained"
                            component="span"
                            sx={{
                              backgroundColor: "#121b5b",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#121b5b" },
                            }}
                          >
                            Browse File
                          </Button>
                        </label>
                        <input
                          id="thumbnailInput"
                          type="file"
                          onChange={(e) => handleFileChange(e)}
                          name="blog_logo"
                          style={{
                            display: "none",
                          }}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={formData?.blog_logo}
                          alt="Thumbnail Preview"
                          sx={{
                            maxHeight: "150px",
                            maxWidth: "100%",
                            borderRadius: "5px",
                            objectFit: "contain",
                          }}
                        />
                        <Box mt={2} display="flex" gap={1}>
                          <IconButton
                            component="a"
                            href={formData?.blog_logo}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#0056b3" },
                            }}
                          >
                            <IoEyeSharp size={20} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleRemoveFile("blog_logo")}
                            sx={{
                              backgroundColor: "#dc3545",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#c82333" },
                            }}
                          >
                            <IoCloseSharp size={20} />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      height: "300px",
                      width: "100%",
                      border: "2px dashed #ccc",
                      borderRadius: "5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: "#f9f9f9",
                      position: "relative",
                      transition: "all 0.3s",
                      "&:hover": {
                        backgroundColor: "#f1f1f1",
                        borderColor: "#007bff",
                      },
                    }}
                  >
                    {!formData?.blog_banner ? (
                      <>
                        <label
                          htmlFor="bannerInput"
                          style={{ cursor: "pointer", textAlign: "center" }}
                        >
                          <Typography
                            variant="h6"
                            color="textSecondary"
                            sx={{ mb: 1 }}
                          >
                            Upload Banner
                          </Typography>
                          <Button
                            variant="contained"
                            component="span"
                            sx={{
                              backgroundColor: "#121b5b",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#121b5b" },
                            }}
                          >
                            Browse File
                          </Button>
                        </label>
                        <input
                          id="bannerInput"
                          type="file"
                          onChange={handleFileChange}
                          name="blog_banner"
                          style={{
                            display: "none",
                          }}
                        />
                      </>
                    ) : (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <Box
                          component="img"
                          src={formData?.blog_banner || ""}
                          alt="Banner Preview"
                          sx={{
                            maxHeight: "150px",
                            maxWidth: "100%",
                            borderRadius: "5px",
                            objectFit: "contain",
                          }}
                        />
                        <Box mt={2} display="flex" gap={1}>
                          <IconButton
                            component="a"
                            href={formData?.blog_banner}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              backgroundColor: "#007bff",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#0056b3" },
                            }}
                          >
                            <IoEyeSharp size={20} />
                          </IconButton>
                          <IconButton
                            onClick={() => handleRemoveFile("blog_banner")}
                            sx={{
                              backgroundColor: "#dc3545",
                              color: "#fff",
                              "&:hover": { backgroundColor: "#c82333" },
                            }}
                          >
                            <IoCloseSharp size={20} />
                          </IconButton>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>

              <Grid sx={{ marginTop: "20px" }} item xs={12} sm={6} md={4}>
                <TextField
                  size="small"
                  fullWidth
                  label="Tags"
                  variant="outlined"
                  name="tags"
                  placeholder="Enter the tags"
                  onChange={handleChange}
                  value={formData.tags}
                />
              </Grid>
              <Grid
                sx={{ marginTop: "10px" }}
                item
                xs={12}
                container
                justifyContent="flex-end"
                spacing={2}
              >
                <Grid item>
                  <Button
                    sx={{
                      color: "#121b5b",
                      border: "1px solid #121b5b",
                      "&:hover": {
                        backgroundColor: "#121b5b",
                        color: "#fff",
                      },
                    }}
                    variant="outlined"
                    onClick={handleSaveDraft}
                    className={style["form-saved"]}
                  >
                    Save as Draft
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    sx={{
                      backgroundColor: "#121b5b",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#121b5b",
                        color: "#fff",
                      },
                    }}
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={style["form-published"]}
                  >
                    Save & Publish
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>
      )}
    </>
  );
}

export default CreateBlogs;
