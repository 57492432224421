import style from "../style.module.scss";
import MultiSelect from "../../../components/buttons/multiSelect";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { motorpediaTemplateList, fetchDealerTypeDropdown } from "../../../store/documentUpload/documentAction";
import {
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Box,
    Typography,
    Stack,
    Paper,
    Tooltip,
    IconButton,
    Pagination
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { get_url_extension, downloadFile } from "../../../utils/helpar";
import VisibilityIcon from '@mui/icons-material/Visibility';


function TemplateDownload() {
    const { documentCategory, templateList } = useSelector((state) => state?.documentReducers || []);

    const [documentType, setDocumentType] = useState({
        docType: "",
        docSubType: "",
    })
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 10,
        totalPages: templateList?.totalPageCount || 1,
    });



    const [filteredSubTypes, setFilteredSubTypes] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchDealerTypeDropdown());
    }, [])

    useEffect(() => {
        if (appliedFilter !== null) {
            dispatch(motorpediaTemplateList({ ...appliedFilter, page: pagination.pageIndex }));
        } else {
            dispatch(motorpediaTemplateList({ page: pagination.pageIndex }));
        }
    }, [dispatch, pagination.pageIndex, appliedFilter])


    const documentSubTypeCategory = (value) => {
        const findData = documentCategory?.data?.find(
            (category) => category.document_type === value
        )
        setFilteredSubTypes(findData?.document_sub_type || [])
    }

    const applyFilter = async () => {
        setAppliedFilter(documentType)
        const payload = {
            page: pagination.pageIndex,
            ...documentType
        }
        await dispatch(motorpediaTemplateList(payload));
    }

    const resetFilter = async () => {
        setAppliedFilter(null);
        await dispatch(motorpediaTemplateList());
        setDocumentType({
            docType: "",
            docSubType: "",
        });
    }
    return (
        <div className={style["container"]}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 0",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 600, px: 2 }}
                >
                    Sample Document {`(${templateList?.totalCount})`}
                </Typography>
            </Box>

            <div className={style["filters-container"]}>
                <div className={style["filters-wrapper"]}>
                    <div className={style["filter-header"]}>
                            <div style={{ width: '300px' }}>
                                <MultiSelect
                                    selectedItems={documentType?.docType || ""}
                                    name="document_type"
                                    items={documentCategory?.data?.map((item) => ({
                                        label: item.document_type,
                                        value: item.document_type,
                                    }))}
                                    handleChange={(e) => {
                                        setDocumentType(prev => ({ ...prev, docType: e.value }))
                                        documentSubTypeCategory(e.value)
                                    }
                                    }
                                    hint={"Documents category"}
                                />
                            </div>
                            <div style={{ width: "300px" }}>
                                <MultiSelect
                                    selectedItems={documentType?.docSubType || ""}
                                    items={filteredSubTypes.map((subType) => ({
                                        label: subType.name,
                                        value: subType.name,
                                    }))}
                                    name="document_sub_type"
                                    handleChange={(e) => {
                                        setDocumentType(prev => ({ ...prev, docSubType: e.value }))
                                    }}
                                    hint={"Documents sub category"}
                                />
                            </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", gap: '10px' }}>
                        <PrimaryButton
                            containerStyle={style["add-new-car-button-container-style"]}
                            name={" Filter"}
                            fontSize={"1rem"}
                            onClick={applyFilter}
                        />

                        <PrimaryButton
                            containerStyle={style["add-new-car-button-container-style"]}
                            name={"Reset"}
                            fontSize={"1rem"}
                            onClick={resetFilter}
                        />
                    </div>
                </div>
            </div>
            <TemplateTable
                data={templateList?.data || []}
            />

            <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop={"20px"}
            >
                <Pagination
                    count={templateList?.totalPageCount}
                    page={pagination.pageIndex}
                    onChange={(event, page) => {
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: page
                        }));
                    }}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>

        </div>
    )
}
export default TemplateDownload;



export function TemplateTable({ data }) {

    const handleDownload = async (data) => {
        const url = data?.doc_path;
        const extension = get_url_extension(data?.doc_path);
        await downloadFile(url, extension);
    };


    const ActionButtons = ({ data }) => (
        <div className={style["action-container"]}>
            <Stack direction="row" spacing={1} justifyContent="center">
                <Tooltip title="view" onClick={() => window.open(data?.doc_path, "_blank")} >
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Download" onClick={() => handleDownload(data)} >
                    <IconButton>
                        <FileDownloadIcon />
                    </IconButton>
                </Tooltip>

            </Stack>
        </div>
    );

    return (
        <List>
            <TableContainer
                component={Paper}
                sx={{ width: "99%", margin: "0px 10px 0px 6px" }}
            >
                <Table>
                    <TableHead
                        sx={{
                            backgroundColor: "#f5f5f5",
                            "& .MuiTableCell-root": {
                                fontWeight: "bold",
                            },
                        }}
                    >
                        <TableRow>
                            <TableCell align="left" >Document Category</TableCell>
                            <TableCell align="left">Document Sub Category</TableCell>
                            <TableCell align="left">Created Date</TableCell>
                            <TableCell align="center" >Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.map((car, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    "&:hover": {
                                        cursor: "pointer",
                                        backgroundColor: "#f5f5f5",
                                    },
                                }}
                            >
                                <TableCell>
                                    {car?.doc_category}
                                </TableCell>
                                <TableCell>{car?.doc_name}</TableCell>
                                <TableCell>
                                    {car?.created_at
                                        ? new Date(car?.created_at).toLocaleDateString()
                                        : "-"}
                                </TableCell>
                                <TableCell align="center">
                                    <ActionButtons data={car} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </List>
    )
}