import style from "./style.module.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Step1 from "./step1/index.jsx";
import Step2 from "./step2/index.jsx";
import { Box, Tabs, Tab } from "@mui/material";
import Step0 from "./step0/index.jsx";
import { Typography } from "@mui/material";
import { ROLE_ID } from "../../config.js";
import usersApi from "../../api/usersApi.js";
import { use } from "react";
import { UserDealerPermission } from "../../components/permissions/index.js";

const Preference = () => {
  const id = useParams();
  console.log("id ==>", id.path);
  const navigate = useNavigate();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { checkStatusPermisssion, error } = UserDealerPermission(17);

  const [currentStep, setCurrentStep] = useState(state?.currentStep || 0);
  const [dealerId, setDealerId] = useState(state?.dealerId || null);

  useEffect(() => {
    if (state == null) return;
    if (state && state?.currentStep) {
      dispatch(Step0(currentStep));
      dispatch(Step1(currentStep));
      dispatch(Step2(currentStep));
    } else {
      dispatch(Step0(state));
      dispatch(Step1(state));
      dispatch(Step2(state));
      setDealerId(state);
    }

    // return () => {
    //   window.history.replaceState(null, "");
    //   dispatch(resetDealer());
    // };
  }, [state]);

  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "manage_lead");


  const steps = [
    { id: "customer_preference", title: "Customer Status" },
    { id: "customer_preferences", title: "Customer Preferences" },
    // { id: "assign_dealers", title: "Assign Dealer" },
  ];

  const handleChange = (event, newValue) => {
    setCurrentStep(newValue);
  };

  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action))
  }

  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    res();
  }, []);


  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }



  return (
    <>
      {(hasPermission("Add") || hasPermission("Edit") || (localStorage.getItem("roleId") == ROLE_ID) && checkStatus("active")) ? (
        <div className={style.container}>
          {/* <Box
        sx={{
          width: "100%",
          backgroundColor: "white",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <Tabs value={currentStep} textColor="#121b5b" onChange={handleChange}>
          {steps.map((step) => (
            <Tab
              sx={{
                "&.Mui-selected": {
                  color: "#121b5b",
                },
                "&:hover": {
                  color: "#121b5b",
                },
              }}
              key={step.id}
              label={step.title}
            />
          ))}
        </Tabs>
      </Box> */}
          <div className={style["form-container"]}>
            {currentStep === 0 && <Step0 setCurrentStep={setCurrentStep} id={id} />}
            {currentStep === 1 && <Step1 setCurrentStep={setCurrentStep} id={id} />}
            {/* {currentStep === 2 && (
          <Step2
            setCurrentStep={setCurrentStep}
            id= {id}
          />
        )} */}
          </div>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>
      )}
    </>

  );
};

export default Preference;
