import { GET_USERS, SAVE_USERS,GET_USER_PERMISSION } from "../types";
import usersApi from "../../api/usersApi";

export const getDealerusers = (params) => (dispatch) => {
  return usersApi.getUsers(params).then(
    (response) => {
      dispatch({
        type: GET_USERS,
        payload: {
          data: response?.data,
          total: response?.total,
          currentPage: response?.currentPage,
          hasNexPage: response?.hasNextPage,
          totalCount: response?.totalCount || 0,
          totalPageCount: response?.totalPageCount
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const saveDealerUsers = (payload) => (dispatch) => {
  return usersApi.saveUsers(payload).then(
    (response) => {
      dispatch({
        type: SAVE_USERS,
        payload: {
          data: response?.data,
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const userPermissionRole = () => (dispatch) => {
  return usersApi.permissionRoles().then(
    (response) => {
      dispatch({
        type: GET_USER_PERMISSION,
        payload : response || []
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
