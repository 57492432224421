import { Box, Typography, Pagination, Stack } from "@mui/material";
import style from "./user.module.scss";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import UserTable from "../../components/tables/userTable";
import { useDispatch, useSelector } from "react-redux";
import { getDealerusers } from "../../store/users/userAction";
import { fetchDealerRoles } from "../../store/roles/roleAction";
import { ROLE_ID } from "../../config";
import { UserDealerPermission } from "../../components/permissions";
export const DealerRoles = () => {
    const dispatch = useDispatch();
    const { data, totalCount, totalPageCount } = useSelector(
        (state) => state?.userReducers?.userList || []
    );
    const roleList = useSelector((state) => state?.roleReducers?.roleList?.data || []);
    const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);
    const navigate = useNavigate();

    const [filter, setFilter] = useState({
        isActive: "",
        user_role_id: "",
    });
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [filteredData, setFilteredData] = useState([]);

    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 10,
        totalPages: totalPageCount || 1,
    });

    const applyFilter = async () => {
        setAppliedFilter(filter);
        setPagination((prev) => ({ ...prev, pageIndex: 1 }));
        await dispatch(getDealerusers({ ...filter, page: 1 }));
    };

    const resetFilter = async () => {
        setFilter({ isActive: "", user_role_id: "" });
        setSearchText("");
        setAppliedFilter(null);
        setPagination({ pageIndex: 1, pageSize: 10, totalPages: totalPageCount || 1 });
        await dispatch(getDealerusers());
    };

    useEffect(() => {
        if (appliedFilter !== null) {
            dispatch(getDealerusers({ ...appliedFilter, page: pagination.pageIndex }));
        } else {
            dispatch(getDealerusers({ page: pagination.pageIndex }));
        }
    }, [dispatch, pagination.pageIndex, appliedFilter]);

    useEffect(() => {
        dispatch(fetchDealerRoles());
    }, [dispatch]);

    const handleSearch = () => {
        let filtered = data;
        if (searchText) {
            filtered = filtered.filter((item) =>
                item?.firstname?.toLowerCase().includes(searchText.toLowerCase()) ||
                item?.email?.toLowerCase().includes(searchText.toLowerCase()) ||
                item?.phone?.toString().includes(searchText));
        }
        setFilteredData(filtered);
    };

    useEffect(() => {
        handleSearch();
    }, [searchText, data]);

    useEffect(() => {
        setPagination((prev) => ({
            ...prev,
            totalPages: totalPageCount || 1,
        }));
    }, [totalPageCount]);

    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }

    return (
        <>
            {(localStorage.getItem("roleId") == ROLE_ID && checkStatus("active")) ? (
                <div style={{ padding: "10px" }}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "15px 0",
                        }}
                    >
                        <Typography variant="h6" component="div" sx={{ fontWeight: 600, px: 2 }}>
                            User List ({totalCount})
                        </Typography>
                    </Box>
                    <div className={style["filters-container"]}>
                        <div className={style["filters-wrapper"]}>
                            <div className={style['search-baar']}>
                                <SearchBar
                                    className={style['search-baar']}
                                    placeholder={"Search Name / Email / Phone"}
                                    handleChange={(e) => setSearchText(e.target.value)}
                                />
                            </div>
                            <div className={style['selectable-button']}>
                                <SelectableButton
                                    trailingIcon={R.ic_arrow_down}
                                    containerStyle={style["selectable-button-container-style"]}
                                    handleChange={(e) => {
                                        setFilter({ ...filter, isActive: e.target.value });
                                    }}
                                    items={[
                                        { label: "Active", value: "1" },
                                        { label: "Inactive", value: "0" },
                                    ]}
                                    hint={"status"}
                                    value={filter.isActive || ""}
                                />
                            </div>
                            <div className={style['selectable-button']}>
                                <SelectableButton
                                    trailingIcon={R.ic_arrow_down}
                                    containerStyle={style["selectable-button-container-style"]}
                                    handleChange={(e) => {
                                        setFilter({ ...filter, user_role_id: e.target.value });
                                    }}
                                    items={roleList?.map((res) => ({
                                        label: res?.role_name,
                                        value: res?.id,
                                    }))}
                                    hint={"role name"}
                                    value={filter.user_role_id || ""}
                                />
                            </div>

                            <PrimaryButton
                                containerStyle={style["add-new-car-button-container-style"]}
                                name={" Filter"}
                                fontSize={"1rem"}
                                onClick={applyFilter}
                            />

                            <PrimaryButton
                                containerStyle={style["add-new-car-button-container-style"]}
                                name={"Reset"}
                                fontSize={"1rem"}
                                onClick={resetFilter}
                            />
                        </div>
                        <div className={style["action-buttons-wrapper"]}>
                            <PrimaryButton
                                containerStyle={style["add-blogs-button"]}
                                leadingIcon={R.ic_add_white}
                                name={"Add New User"}
                                fontSize={"1rem"}
                                linkTo={"/create-user"}
                                onClick={() => navigate("/create-user")}
                            />
                        </div>
                    </div>
                    <UserTable data={filteredData} />

                    <Stack
                        spacing={2}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        marginTop={"20px"}
                    >
                        <Pagination
                            count={pagination.totalPages}
                            page={pagination.pageIndex}
                            onChange={(event, page) => {
                                setPagination((prev) => ({
                                    ...prev,
                                    pageIndex: page,
                                }));
                            }}
                            variant="outlined"
                            shape="rounded"
                        />
                    </Stack>
                </div>
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>
            )}
        </>
    );
};

