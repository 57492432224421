import {
  call,
  customerCalls,
  dealerappUrl,
  selerLead,
  csvList,
  saveCsvList,
  preferenceCall,
  saveCsvFileData,
  updateCsvStatus,
  brandcall,
  dealerfetchCall,
  dealerSubUser,
  enquiryList
} from ".";
import { dealerId } from "../constants/resources";

class BuyerApi {
  updateCsvStatus(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await updateCsvStatus(
            "get",
            `dealer-app/buyer-lead/updateCsvStatus?sheetId=${data}`,
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  getBuyerLeadStatus() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await updateCsvStatus(
            "get",
            "dealer-app/buyer-lead/get-buy-lead-status",
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  getEnquiryType(dealerId) {
    console.log("/dealerService/dealerService ", dealerId);
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await enquiryList(
            "get",
            `dealerService?dealerId=${dealerId.dealerId}`, 
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }


  getLeadPrefrence(param) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await updateCsvStatus(
            "get",
            `dealer-app/buyer-lead/lead-details/${param}`,
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  postCsvList(formData) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await saveCsvList(
            "post",
            "dealer-app/buyer-lead/uploadCsv",
            formData,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  getLeadCsvList(params) {
    const token = localStorage.getItem("accessToken");
    // const token = '29c49219-6846-4b04-8784-ab5dfe63d43f';
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const response = await csvList(
            "get",
            "dealer-app/buyer-lead/getCsvList",
            params,
            null,
            token
          );
          resolve({
            // type: GET_LEAD_CSV_LIST,
            payload: {
              data: response?.data,
              currentPage: response?.data.currentPage,
              totalPageCount: response?.data?.totalPageCount,
              totalCount: response?.data?.totalCount,
              hasNextPage: response?.hasNextPage,
            },
          });
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  saveCsvFileData(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await saveCsvFileData(
            "post",
            "dealer-app/buyer-lead/processRawDealerLeadCsv",
            data,
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  saveLeadPrefrence(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await saveCsvFileData(
            "post",
            "dealer-app/buyer-lead/lead-preferences",
            data,
            token
          );
          resolve(res);
        } catch (err) {
          console.log("err", err);
          reject(err);
        }
      })();
    });
  }

  editBuyerLead(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("put", "admin/buyerLead", null, data, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getBuyerLead(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerappUrl(
            "get",
            `dealer-app/buyer-lead/get-lead`,
            params,
            token
          );
          // console.log("resres ", res.data);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getInqueryLead(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await selerLead(
            "get",
            `admin/inqueryLeadList?dealerId=${dealerId}`,
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getInqueryType(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await selerLead(
            "get",
            `admin/get-Inquire-List`,
            null,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  updateInquireLead(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await selerLead(
            "put",
            `admin/edit-Inquire-List`,
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getBrand() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await call("get", "brands", null, null, token);
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  // brandcall

  getModels(params) {
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await brandcall(
            "get",
            "inventoryService/public/get-model",
            params,
            null,
            null
          );
          resolve(res?.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }
  getCustomerCommentHistory(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await preferenceCall(
            "get",
            "dealer-app/buyer-lead/get-lead-history",
            params,
            token
          );
          resolve(res?.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  addUpdateCustomerInfo(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await customerCalls(
            "post",
            "dealer-app/buyer-lead/update-lead",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

   getCustomerInfo(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await customerCalls(
            "get",
            "dealer-app/buyer-lead/get-lead",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  getDealerSubUser(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await dealerSubUser(
            "get",
            "dealer-app/get-dealer-user",
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      })();
    });
  }

  assignUser(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await customerCalls(
            "post",
            "dealer-app/buyer-lead/assignUser",
            null,
            data,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }
}

export const buyerApi = new BuyerApi();
