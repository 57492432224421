import { SAVE_USERS, GET_USERS, GET_USER_PERMISSION } from "../types";


const initialState = {
    userList: [],
    userPermission: []
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_USERS:
            return {
                ...state,
                userList: [payload, ...(Array.isArray(state.userList) ? state.userList : [])],
            };
        case GET_USERS:
            return {
                ...state,
                userList: payload?.data
            };
        case GET_USER_PERMISSION:
            return {
                ...state,
                userPermission: payload
            };

        default:
            return state;
    }
}
