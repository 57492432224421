import {DOCUMENT_TYPE_LIST, UPLOAD_DEALER_DOCUMENT_LIST, GET_TEMPLATE_LIST } from "../types";
import documentvalue from "../../api/documentvalue";

export const fetchDealerDocument = (param) => (dispatch) => {
    return documentvalue.getDealerUploadDocument(param).then(
        (response) => {
            dispatch({
                type: UPLOAD_DEALER_DOCUMENT_LIST,
                payload: response
            })
            return Promise.resolve(response);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}


export const fetchDealerTypeDropdown = (param) => (dispatch) => {
    return documentvalue.masterDocument(param).then(
        (response) => {
            dispatch({
                type: DOCUMENT_TYPE_LIST,
                payload: response || []
            })
            return Promise.resolve(response);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

export const motorpediaTemplateList = (param) => (dispatch) => {
    return documentvalue.motorpediaTemplate(param).then(
        (response) => {
            dispatch({
                type: GET_TEMPLATE_LIST,
                payload: response
            })
            return Promise.resolve(response);
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}
