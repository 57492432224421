import { EDIT_BUYER_LEAD, GET_BUYER_LEAD } from "../types";
import { buyerApi } from "../../api/buyerLeadApi";

export const editBuyerDetails = (payload) => (dispatch) => {
  return buyerApi.editBuyerLead(payload).then(
    (data) => {
      dispatch({
        type: EDIT_BUYER_LEAD,
        payload: payload,
      });
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const getLeadList = (params) => {  
  return buyerApi.getInqueryLead(params)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.error("Error fetching buyer lead:", error);
      throw error;
    });
};


export const getBuyerList = (params) => (dispatch) => {

  return buyerApi.getBuyerLead(params).then(
    (response) => {
      dispatch({
        type: GET_BUYER_LEAD,
        payload: {
          data: response?.data,
          total: response?.total,
          totalRecords: response?.totalRecords,
          hasNext : response?.hasNext
        },
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};



