import {DOCUMENT_TYPE_LIST, UPLOAD_DEALER_DOCUMENT_LIST, GET_TEMPLATE_LIST } from "../types";

const initalState = {
    documentCategory: [],
    documentList: [],
    templateList : []
}

export default function (state = initalState, action) {
    const { type, payload } = action;
    
    switch (type) {
        case DOCUMENT_TYPE_LIST:
            return { ...state, documentCategory: payload }

        case UPLOAD_DEALER_DOCUMENT_LIST:
            return { ...state, documentList: payload }
        
        case GET_TEMPLATE_LIST :
            return { ...state, templateList : payload }

        default:
            return state;

    }
}