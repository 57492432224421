import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
// import "./multiSelect.module.scss";

const MultiSelectTwo = ({
  items,
  selectedItems,
  handleChange,
  containerStyle,
  name,
  placeholder,
  isMulti,
  isSearchable,
  selectClass,
}) => {
  // Updated to include both value and value2
  const options = items?.map((item) => ({
    label: item.label,
    value: item.value, // Original value
    value2: item.value2, // New value2
  }));

  // Handle selectedItems whether it's single or multiple selections
  const selectedOptions = Array.isArray(selectedItems)
    ? selectedItems.map((item) => ({
        label: item.label,
        value: item.value,
        value2: item.value2, // Ensure value2 is passed in the selected items
      }))
    : options.find((item) => item.value === selectedItems);

  // Updated handleChange to pass both value and value2
  const handleChangeMultiSelect = (selectedOptions) => {
    if (isMulti) {
      // Return both value and value2 for multi-selection
      handleChange(
        selectedOptions.map((option) => ({
          label: option.label,
          value: option.value,
          value2: option.value2,
        }))
      );
    } else {
      // Return both value and value2 for single selection
      handleChange({
        label: selectedOptions.label,
        value: selectedOptions.value,
        value2: selectedOptions.value2,
      });
    }
  };

  return (
    <div className={containerStyle}>
      <Select
        isMulti={isMulti}
        name={name}
        options={options}
        value={selectedOptions}
        onChange={handleChangeMultiSelect}
        classNamePrefix="select"
        placeholder={placeholder}
        isSearchable={isSearchable}
        className={selectClass}
      />
    </div>
  );
};

MultiSelectTwo.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.oneOfType([
    PropTypes.array, // For multi-selection
    PropTypes.object, // For single selection
  ]).isRequired,
  handleChange: PropTypes.func.isRequired,
  containerStyle: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isMulti: PropTypes.bool,
  isSearchable: PropTypes.bool,
  selectClass: PropTypes.string,
};

export default MultiSelectTwo;
