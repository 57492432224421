import { Box, Typography, Stack, Pagination } from "@mui/material";
import style from "./style.module.scss";
import { R } from "../../constants/resources";
import MultiSelect from "../../components/buttons/multiSelect";
import { useEffect, useState } from "react";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchDealerDocument, fetchDealerTypeDropdown } from "../../store/documentUpload/documentAction";
import DocumentTable from "./table/table";
import SearchBar from "../../components/search-bar/SearchBar";
function UploadList() {
    const { documentCategory, documentList } = useSelector((state) => state?.documentReducers || []);

    const [documentType, setDocumentType] = useState({
        docType: "",
        docSubType: "",
        vehicle_no: "",
        other_doc: "",
    })
    const [appliedFilter, setAppliedFilter] = useState(null);
    const [pagination, setPagination] = useState({
        pageIndex: 1,
        pageSize: 10,
        totalPages: documentList?.totalPageCount || 1,
    });



    const [filteredSubTypes, setFilteredSubTypes] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchDealerTypeDropdown());
    }, [])

    useEffect(() => {
        if (appliedFilter !== null) {
            dispatch(fetchDealerDocument({ ...appliedFilter, page: pagination.pageIndex }));
        } else {
            dispatch(fetchDealerDocument({ page: pagination.pageIndex }));
        }
    }, [dispatch, pagination.pageIndex, appliedFilter])


    const documentSubTypeCategory = (value) => {
        const findData = documentCategory?.data?.find(
            (category) => category.document_type === value
        )
        setFilteredSubTypes(findData?.document_sub_type || [])
    }

    const applyFilter = async () => {
        setAppliedFilter(documentType)
        const payload = {
            page: pagination.pageIndex,
            ...documentType
        }
        await dispatch(fetchDealerDocument(payload));
    }

    const resetFilter = async () => {
        setAppliedFilter(null);
        await dispatch(fetchDealerDocument());
        setDocumentType({
            docType: "",
            docSubType: "",
            vehicle_no: "",
            other_doc: "",
        });
    }

    const refreshData = () => {
        dispatch(fetchDealerDocument({ page: pagination.pageIndex }));
    };


    return (
        <div className={style["container"]}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    backgroundColor: "#fff",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "15px 0",
                }}
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{ fontWeight: 600, px: 2 }}
                >
                    Document Vault {`(${documentList?.totalCount})`}
                </Typography>
                <Box sx={{ display: "flex", gap: "10px" }}>
                    <PrimaryButton
                        containerStyle={style["add-new-car-button-container-style"]}
                        leadingIcon={R.ic_arrow_down}
                        name={"Download Template"}
                        fontSize={"1rem"}
                        linkTo={"/template-download"}
                        onClick={() => navigate("/template-download")}
                    />
                    <PrimaryButton
                        containerStyle={style["add-new-car-button-container-style"]}
                        leadingIcon={R.ic_add_white}
                        name={"Document Upload"}
                        fontSize={"1rem"}
                        linkTo={"/upload-document"}
                        onClick={() => navigate("/upload-document")}
                    />
                </Box>

            </Box>

            <div className={style["filters-container"]}>
                <div className={style["filters-wrapper"]}>
                    <div className={style["filter-header"]}>
                        <div style={{ width: '300px' }}>
                            <MultiSelect
                                selectedItems={documentType?.docType || ""}
                                name="document_type"
                                items={documentCategory?.data?.map((item) => ({
                                    label: item.document_type,
                                    value: item.document_type,
                                }))}
                                handleChange={(e) => {
                                    setDocumentType(prev => ({ ...prev, docType: e.value }))
                                    documentSubTypeCategory(e.value)
                                }
                                }
                                hint={"Documents category"}
                            />
                        </div>
                        <div style={{ width: "300px" }}>
                            <MultiSelect
                                selectedItems={documentType?.docSubType || ""}
                                items={filteredSubTypes.map((subType) => ({
                                    label: subType.name,
                                    value: subType.name,
                                }))}
                                name="document_sub_type"
                                handleChange={(e) => {
                                    setDocumentType(prev => ({ ...prev, docSubType: e.value }))
                                }}
                                hint={"Documents sub category"}
                            />
                        </div>
                        <div style={{ width: '300px' }}>
                            <SearchBar
                                value={documentType.vehicle_no}
                                placeholder={"Search Vehicle no"}
                                handleChange={(e) => {
                                    const formattedValue = e.target.value.toUpperCase();
                                    setDocumentType(prev => ({
                                        ...prev, vehicle_no: formattedValue
                                    }))
                                }}
                                length={10}
                            />
                        </div>
                        <div style={{ width: '300px' }}>
                            <SearchBar
                                value={documentType.other_doc}
                                placeholder={"Search document code"}
                                handleChange={(e) => {
                                    const formattedValue = e.target.value.toUpperCase();
                                    setDocumentType(prev => ({
                                        ...prev, other_doc: formattedValue

                                    }))
                                }}
                                length={10}
                            />
                        </div>
                    </div>

                    <div style={{display: "flex", flexDirection: "row", gap: '10px' }}>
                        <PrimaryButton
                            containerStyle={style["add-new-car-button-container-style"]}
                            name={" Filter"}
                            fontSize={"1rem"}
                            onClick={applyFilter}
                        />

                        <PrimaryButton
                            containerStyle={style["add-new-car-button-container-style"]}
                            name={"Reset"}
                            fontSize={"1rem"}
                            onClick={resetFilter}
                        />
                    </div>
                </div>
            </div>
            <DocumentTable
                data={documentList?.data || []}
                refreshData={refreshData}
            />
            <Stack
                spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center"
                marginTop={"20px"}
            >
                <Pagination
                    count={documentList?.totalPageCount}
                    page={pagination.pageIndex}
                    onChange={(event, page) => {
                        setPagination((prev) => ({
                            ...prev,
                            pageIndex: page
                        }));
                    }}
                    variant="outlined"
                    shape="rounded"
                />
            </Stack>
        </div>
    )
}

export default UploadList;