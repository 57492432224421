import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import InputField from "../../../components/input-field/InputField";
import style from "./style.module.scss";
import { IoCloudUploadSharp, IoArrowBack } from "react-icons/io5";
import { TiDelete } from "react-icons/ti";
import { useSelector, useDispatch } from "react-redux";
import { showToast } from "../../../components/toaster/toaster";
import { get_url_extension } from "../../../utils/helpar";
import { documentUpload, fetchDealerTypeDropdown } from "../../../store/documentUpload/documentAction";
import MultiSelect from "../../../components/buttons/multiSelect";
import { Box, Typography, Button } from "@mui/material";
import BackButton from "../../../components/buttons/BackButton";
import documentvalue from "../../../api/documentvalue";
import validation from "../../../components/validation/validated";
import ErrorMessage from "../../../components/input-field/ErrorMessage";

function UploadDocument() {
  const { documentCategory } = useSelector((state) => state?.documentReducers || []);
  const [filteredSubTypes, setFilteredSubTypes] = useState([]);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [documentCounts, setDocumentCounts] = useState({});

  const initialValues = {
    document_type: "",
    document_sub_type: "",
    vehicle_no: "",
    remarks: "",
  };

  const handleUpload = (e, setFieldValue, values) => {
    const files = Array.from(e.target.files);
    if (files.length > 0 && values?.document_sub_type) {
      const subTypeCount = documentCounts[values?.document_sub_type] || 0;
      const newDocuments = files.map((file, index) => {
        const docName = filteredSubTypes.filter(item => item?.id == values?.document_sub_type);
        const fileUrl = URL.createObjectURL(file);
        const extension = get_url_extension(file.name);
        return {
          name: file.name,
          url: fileUrl,
          file,
          extension,
          subType: docName[0]?.name,
          subTypeId: values.document_sub_type,
          index: subTypeCount + index + 1,
        };
      });

      setUploadedDocuments((prevDocs) => [...prevDocs, ...newDocuments]);
      setDocumentCounts((prevCounts) => ({
        ...prevCounts,
        [values?.document_sub_type]: subTypeCount + files.length,
      }));

      setFieldValue("documents", [...uploadedDocuments, ...newDocuments]);
    }
  };

  const handleRemove = (name, subType, setFieldValue) => {
    const updatedDocuments = uploadedDocuments.filter(
      (doc) => doc.name !== name
    );
    setUploadedDocuments(updatedDocuments);
    setDocumentCounts((prevCounts) => ({
      ...prevCounts,
      [subType]: prevCounts[subType] - 1,
    }));
    setFieldValue("documents", updatedDocuments);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    uploadedDocuments.forEach((doc) => {
      formData.append("documents", doc.file);
      formData.append("document_sub_types", doc.subTypeId);
      formData.append("status", 1);
    });

    if (values?.remarks) {
      formData.append("remarks", values.remarks);
    }

    if (values?.vehicle_no) {
      formData.append("vehicle_no", values?.vehicle_no);
    }

    if (values?.other_doc) {
      formData.append("other_doc", values?.other_doc);
    }
    try {
      const response = await documentvalue.dealerDocumentUpload(formData);
      console.log("Upload Response:", response);
      showToast("success", response?.data?.message);
      navigate("/document-upload-list")
      setUploadedDocuments([]);
    } catch (error) {
      console.log("error", error?.response?.data?.message);
      showToast("error", error?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };


  useEffect(() => {
    dispatch(fetchDealerTypeDropdown());
  }, [])


  const documentSubTypeCategory = (value) => {
    const findData = documentCategory?.data?.find(
      (category) => category.document_type === value
    )
    setFilteredSubTypes(findData?.document_sub_type || [])
  }




  return (
    <>
      <div className={style["container"]}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            backgroundColor: "#fff",
            borderRadius: "5px",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            alignItems: "center",
            marginBottom: "20px",
            padding: "15px ",
          }}
        >
          <BackButton
            onClick={() => {
              navigate("/document-upload-list")
            }}
          />
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 600, px: 2 }}
          >
            Document Vault
          </Typography>
        </Box>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validation.documentValut}>
          {({
            values,
            setFieldValue,
            handleBlur,
            handleChange,
            touched,
            errors,
            isSubmitting,
          }) => {
            console.log(errors);
            return (
              <Form className={style["form-container"]}>

                <div className={style["wrapper"]}>
                  <div className={style["select-wrapper"]}>
                    <div className={style["text"]}>Documents category </div>
                    <MultiSelect
                      selectedItems={values?.document_type || ""}
                      name="document_type"
                      items={documentCategory?.data?.map((item) => ({
                        label: item.document_type,
                        value: item.document_type,
                      }))}
                      handleChange={(e) => {
                        setFieldValue("document_type", e.value);
                        documentSubTypeCategory(e.value)
                      }
                      }
                      hint={"Documents category"}
                    />
                    <div>
                      <span style={{ fontSize: "13px", color: "red" }} >{errors?.document_type}</span>
                    </div>


                  </div>

                  <div className={style["input-wrapper"]}>
                    <div className={style["text"]}>Documents sub category</div>
                    <MultiSelect
                      selectedItems={values?.document_sub_type}
                      items={filteredSubTypes.map((subType) => ({
                        label: subType.name,
                        value: subType.id,
                      }))}
                      name="document_sub_type"
                      handleChange={(e) => {
                        setFieldValue("document_sub_type", e.value);
                      }}
                      hint={"Documents sub category"}
                    />
                    <div>
                      <span style={{ fontSize: "13px", color: "red" }} >{errors?.document_sub_type}</span>
                    </div>

                  </div>

                  <div className={style["remarks"]}>
                    <div className={style["remark-text"]}>Remarks </div>
                    <textarea
                      id="remarks"
                      type="text"
                      name="remarks"
                      placeholder=" Enter Rejection Remark"
                      className={style["remark-box"]}
                      value={values?.remarks}
                      onChange={handleChange}
                      maxLength={20}
                    />
                  </div>

                </div>
                {values?.document_type !== "KYC Docs" && (
                  <div className={style["wrapper"]}>
                    <div className={style["select-wrapper"]}>
                      <div className={style["text"]}>Vehicle No</div>
                      <InputField
                        type="text"
                        name="vehicle_no"
                        value={values.vehicle_no?.toUpperCase()}
                        handleChange={(e) => {
                          const formattedValue = e.target.value.toUpperCase();
                          console.log("formattedValue", formattedValue)
                          setFieldValue("vehicle_no", formattedValue);
                        }}
                        onBlur={handleBlur}
                        placeholder="Vehicle No"
                        className={style["select-filter"]}
                        required
                        length={10}
                      />
                      <div>
                        <span style={{ fontSize: "13px", color: "red" }}>{errors?.vehicle_no}</span>
                      </div>
                    </div>

                    <div className={style["select-wrapper"]}>
                      <div className={style["text"]}>Document code</div>
                      <InputField
                        type="text"
                        name="other_doc"
                        value={values.other_doc}
                        handleChange={(e) => {
                          const formattedValue = e.target.value.toUpperCase();
                          setFieldValue("other_doc", formattedValue);
                        }}
                        onBlur={handleBlur}
                        placeholder="document code"
                        length={10}
                        error={touched.other_doc && errors.other_doc}
                        className={style["select-filter"]}
                      />
                      <div>
                        <span style={{ fontSize: "5px", color: "red" }} >{errors?.other_doc}</span>
                      </div>
                    </div>
                    <div className={style["select-wrapper"]}>
                    </div>
                  </div>
                )}

                {values?.document_sub_type && (
                  <div className={style["upload-browse"]}>
                    <div className={style["box"]}>
                      <div className={style["input-bx"]}>
                        <div>
                          <input
                            type="file"
                            id={`upload-${values?.document_sub_type}`}
                            accept=".png, .jpeg, .pdf, .mp4"
                            hidden
                            multiple
                            onChange={(e) => handleUpload(e, setFieldValue, values)}
                          />
                          <label
                            htmlFor={`upload-${values?.document_sub_type}`}
                            className={style["uploadlabel"]}
                          >
                            <span>
                              <IoCloudUploadSharp className={style["upload"]} />
                            </span>
                            <p>Click To Upload</p>
                            <p>support file : .pdf .jpeg .png</p>
                          </label>
                        </div>
                      </div>

                      <div className={style["uploaded-file-wrappper"]}>
                        <h3 className={style["Uploaded"]}>Uploaded Documents</h3>
                        {uploadedDocuments.length > 0 && (
                          <div className={style["fileWrapper"]}>
                            {uploadedDocuments.map((doc, index) => (
                              <div className={style["showfilebox"]} key={index}>
                                <div className={style["left"]}>
                                  <span>{index + 1}.</span>
                                  <span className={style["filetype"]}>upload</span>
                                  <h3>{`${doc.subType} Document ${doc.index}`}</h3>
                                </div>
                                <div className={style["right"]}>
                                  <span
                                    onClick={() =>
                                      handleRemove(
                                        doc.name,
                                        doc.subType,
                                        setFieldValue
                                      )
                                    }
                                  >
                                    <TiDelete />
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}


                <div className={style["action-container"]}>
                  <div className={style["action-wrapper"]}>
                    <div className={style["action-back"]}>
                    </div>
                  </div>
                  <div className={style["action-wrapper"]}>

                    <Button
                      sx={{
                        backgroundColor: "#121b5b",
                        color: "#fff",
                        "&:hover": {
                          backgroundColor: "#121b5b",
                          color: "#fff",
                        },
                      }}
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={style["form-published"]}
                      disabled={isSubmitting}
                    >
                      submit
                    </Button>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </div>
    </>
  );
}

export default UploadDocument;
