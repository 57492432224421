import React, { useEffect, useState } from "react";
import moment from "moment";
import style from "./inqiryTable.module.scss";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import Paper from "@mui/material/Paper";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  List,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { R } from "../../../../constants/resources";
import { useNavigate } from "react-router-dom";
import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import EditIcon from "@mui/icons-material/Edit";
import { ROLE_ID } from "../../../../config";

export default function InqueryTable({ data, hasPermission, checkStatus }) {
  const columns = [
    { accessorKey: "inquiry_name", header: "Enquiry Type" },
    { accessorKey: "name", header: "Name" },
    { accessorKey: "phone", header: "Phone No." },
    { accessorKey: "email", header: "Email" },
    // { accessorKey: "description", header: "description" },
    {
      accessorKey: "created_at",
      header: "Lead Date",
      cell: ({ getValue }) => {
        const date = getValue();
        return moment(date).format("YYYY-MM-DD");
      },
    },

    { accessorKey: "action", header: "Action" },
  ];

  const navigate = useNavigate();

  const [view, setView] = useState(window.innerWidth > 1200 ? "list" : "grid");

  const handleViewChange = () => {
    setView(view === "grid" ? "list" : "grid");
  };

  const table = useReactTable({
    data, // Use filtered data for the table
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const Status = ({ value }) => {
    let backgroundColor;
    let color;
    switch (value) {
      case "BOOKED":
        backgroundColor = "#00B63E";
        color = "#00952A";
        break;
      case "ALREADY BOUGHT":
        backgroundColor = "#FCFF5A";
        color = "#E3BF00";
        break;
      case "NOT INTERESTED":
        backgroundColor = "#D40000";
        color = "#FF0000";
        break;
      case "LOAN NOT APPROVED":
        backgroundColor = "#D40000";
        color = "#FF0000";
        break;
      case "CONFIRMED":
        backgroundColor = "#FCFF5A";
        color = "#E3BF00";
        break;
      case "PENDING":
        backgroundColor = "#FCFF5A";
        color = "#E3BF00";
        break;
      case "Done":
        backgroundColor = "#00B63E";
        color = "#0000000";
        break;
      default:
        backgroundColor = "transparent";
        color = "#000";
    }

    const statusStyle = {
      backgroundColor: backgroundColor,
      padding: "5px 10px",
      borderRadius: "20px",
      color: color,
      display: "inline-block",
    };

    return (
      <div className={`${style["status"]} exportable-cell`}>
        <p style={statusStyle}>{value}</p>
      </div>
    );
  };


  const onEditClick = (data) => {
    navigate("/edit-inquire-lead", { state: data });
  };

  const Action = (data) => (
    <div className={style["action-container"]}>
      {(hasPermission("Edit") || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) && (
        <Tooltip title="Edit">
          <IconButton onClick={() => onEditClick(data, "edit-car-details")}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );


  return (
    <>
      <IconButton
        aria-label="toggle view"
        onClick={handleViewChange}
        sx={{
          ml: "auto",
          display: "flex",
          justifyContent: "flex-end",
          display: { xs: "none", md: "block" },
        }}
      >
        {view === "grid" ? <ViewListIcon /> : <ViewModuleIcon />}
      </IconButton>
      <div
        className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
      >
        <>
          {view === "grid" ? (
            <Grid
              container
              spacing={1}
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {data?.map((car, index) => (
                <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                  <Card
                    sx={{
                      margin: 1,
                      ":hover": {
                        cursor: "pointer",
                        backgroundColor: "#f5f5f5",
                      },
                    }}
                  >
                    <CardActionArea>
                      <CardContent>
                        <Grid container justifyContent="space-between">
                          <Grid
                            sx={{
                              height: "50px",
                              overflow: "hidden",
                            }}
                            item
                          >
                            <Typography
                              variant="body1"
                              component="div"
                              gutterBottom
                              sx={{ fontWeight: "bold" }}
                            >
                              {car?.name}
                            </Typography>
                          </Grid>
                          <Grid
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                            item
                          >
                            <Typography variant="body2" color="h6">
                              <Action data={car} />
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Inquiry Type
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.inquiry_name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "5px",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Email
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.email}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "5px",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Phone
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {car?.phone}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginTop: "5px",
                          }}
                        >
                          <Typography variant="body2" color="textSecondary">
                            Date
                          </Typography>
                          <Typography variant="body2" color="h6">
                            {moment(car?.created_at).format("YYYY-MM-DD")}
                          </Typography>
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <List>
              <TableContainer
                component={Paper}
                sx={{ width: "99%", margin: "1px 10px 5px 6px" }}
              >
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "#f5f5f5",
                      "& .MuiTableCell-root": {
                        fontWeight: "bold",
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell align="left">Enquiry Type</TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Phone No.</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">Lead Date</TableCell>
                      <TableCell align="left">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.map((car, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:hover": {
                            cursor: "pointer",
                            backgroundColor: "#f5f5f5",
                          },
                        }}
                      >
                        <TableCell sx={{ ml: 2 }}>
                          {car?.inquiry_name}
                        </TableCell>
                        <TableCell>{car?.name}</TableCell>
                        <TableCell>{car?.phone}</TableCell>
                        <TableCell>{car?.email}</TableCell>
                        <TableCell>
                          {moment(car?.created_at).format("YYYY-MM-DD")}
                        </TableCell>
                        <TableCell>
                          <Action data={car} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </List>
          )}
        </>
      </div>
    </>
  );
}
