import { insecptionService } from "../../api/insecptionApi";

export const getDoorStep = async (params) => {
  try {
    const response = await insecptionService.getDSList(params);
    return response;
  } catch (error) {
    console.error("Error fetching door step data:", error);
    throw error;
  }
};

export const getUserDropdown = async (params) => {
  try {
    const response = await insecptionService.getUsersDropdown(params);
    return {
      data: response?.data || [],
      total: response?.data?.length || 0,
    };
  } catch (error) {
    console.error("Error fetching user dropdown:", error);
    throw error; // Rethrow error for handling in the calling function
  }
};

export const addDoorStep = async (payload) => {
  try {
    const data = await insecptionService.createDSRequest(payload);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const editDoorStep = async (payload) => {
  try {
    const data = await insecptionService.editDSRequest(payload);
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
