import style from "./style.module.scss";
import { Form, Formik } from "formik";
import { R } from "../../../constants/resources";
import { Link, useLocation, useNavigate } from "react-router-dom";

// import {
//   isImage,
//   get_url_extension,
//   generateServicePrice,
// } from "../../../../../util/General";
// import LocationSearch from "../../../../../components/LocationInput/LocationInput";
import { useEffect, useState, useRef } from "react";
import validationSchema from "../../../constants/validations.js";
import { useDispatch, useSelector } from "react-redux";
import {
  editDoorStep,
  getUserDropdown,
} from "../../../store/inspection/inspectionService.js";
import moment from "moment";
import { LoadingModal } from "../../../components/LoadingModal/index.jsx";
import { insecptionService } from "../../../api/insecptionApi.js";
import { showToast } from "../../../components/toaster/toaster.js";
import debounce from "lodash/debounce";
import { publicApi } from "../../../api/publicApi.js";
// import { Button as MuiButton } from '@mui/material/Button';
import axios from "axios";
import InputField from "../../../components/input-field/InputField.jsx";
import { Box, Button, Grid, Typography, Tooltip } from "@mui/material";
import MultiSelect from "../../../components/buttons/multiSelect.jsx";
import BackButton from "../../../components/buttons/BackButton.jsx";
import { carApi } from "../../../api/carApi.js";
import { styled } from "@mui/material/styles";
import { buyerApi } from "../../../api/buyerLeadApi";
import { ROLE_ID } from "../../../config.js";
import usersApi from "../../../api/usersApi.js";
import { UserDealerPermission } from "../../../components/permissions/index.js";

const EditDoorService = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [serviceList, setServiceList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [serviceId, setService] = useState("");
  const [userListDropdown, setUserListDropdown] = useState([]);
  const [brandsList, setBrandList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [slotList, setSlotList] = useState([]);
  const [pdfUrl, setpdfUrl] = useState("");
  const [storeList, setStoreList] = useState([]);
  const { state } = useLocation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "manage_inspection");
  const { checkStatusPermisssion, error } = UserDealerPermission(15);

  const brands = async () => {
    try {
      const res = await publicApi.brandList();
      setBrandList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };


  const store = async () => {
    try {
      const res = await carApi.dealerAddress();
      setStoreList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    store();
  }, []);

  useEffect(() => {
    const fetchUserDropdown = async () => {
      try {
        const response = await buyerApi.getDealerSubUser({ roleId: 66 });
        setUserListDropdown(response.data);
      } catch (error) {
        console.error("Error fetching user dropdown:", error);
      }
    };

    fetchUserDropdown();
  }, [serviceId]);

  const models = async (makeId) => {
    try {
      const res = await publicApi.modelList({
        make: makeId,
      });
      setModelList(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!pdfUrl && state?.service_report) {
      setpdfUrl(state.service_report);
    }
  }, [pdfUrl, state?.service_report]);

  useEffect(() => {
    if (state?.make_id) {
      console.log("Initial Make ID:", state?.make_id);
      models(state?.make_id); // Populate model list on load
    }
  }, [state?.make_id]);

  const initialValues = {
    status: state?.status || "",
    estCasesNo: state?.estCasesNo || undefined,
    showroom_id: state?.showroom_id || "",
    remarks: state?.remarks || "",
    service_report: state?.service_report || "",
    pocName: state?.pocName || "",
    pocMobile: state?.pocMobile || "",
    prefDateTime: state?.prefDateTime
      ? moment(state?.prefDateTime).format("YYYY-MM-DD")
      : null,
    schDateTime: state?.schDateTime
      ? moment(state?.schDateTime).format("YYYY-MM-DD")
      : null,
    schSlotId: state?.schSlotId || null,
    prefSlotId: state?.prefSlotId || null,
    admin_assigned: state?.admin_assigned || undefined,
    make_id: state?.make_id || undefined,
    model_id: state?.model_id || undefined,
    assign_to_evaluator: state?.assign_to_evaluator || "",
    vehicle_no: state?.vehicle_no || "",
    regAddress: {
      address: state?.address || "",
      pincode: state?.pincode || "",
      state: state?.state || "",
      city: state?.city || "",
    },

  };

  const handleDelete = () => {
    setpdfUrl(null);
    console.log("pdfUrl ", pdfUrl);
  };

  useEffect(() => {
    getUserDropdown(
      state?.ds_service_type?.id == 3 ? { role: 51 } : { pageSize: 100 }
    );

    getServiceList();
    getStatusList();
    getSlotList();
  }, []);

  const getSlotList = async () => {
    const res = await publicApi.getSlots();
    setSlotList(res?.data);
  };

  const getServiceList = async () => {
    const res = await insecptionService.getDSServiceList();
    setServiceList(res?.data);
  };

  const getStatusList = async () => {
    const res = await insecptionService.getDSStatusFilter();
    setStatusList(res?.data);
  };

  const editPickupRequest = (values, { setSubmitting }) => {

    if (parseInt(values?.agreed_amount) < parseInt(values?.amount)) {
      showToast("error", "Agreed Amount should be greater than fee amount");
      return;
    }
    if (values?.status == 3 && state?.ds_service_id == 3) {
      if (!pdfUrl) {
        showToast("error", "Please upload a PDF report.");
        return;
      }
    }

    setLoading(true);
    const schDateTime = new Date(values.schDateTime);
    const selectedSchTime = new Date(`1970-01-01T${values?.schTime}`);
    schDateTime.setHours(selectedSchTime.getHours());
    schDateTime.setMinutes(selectedSchTime.getMinutes());

    const prefDateTime = new Date(values?.prefDateTime);
    const selectedPrefTime = new Date(`1970-01-01T${values?.prefTime}`);
    prefDateTime.setHours(selectedPrefTime.getHours());
    prefDateTime.setMinutes(selectedPrefTime.getMinutes());

    delete values?.prefTime;
    delete values?.schTime;

    editDoorStep({
      reqId: state?.id,
      updateDetails: {
        ...values,
        schDateTime: values.schDateTime,
        prefDateTime: values.prefDateTime,
        service_report: values.service_report,
        ds_service_id: 3
      },
    })
      .then((res) => {
        setSubmitting(false);
        setLoading(false);
        navigate("/inspection-service");
      })
      .catch((err) => {
        showToast(
          "error",
          err?.response?.data?.error || err?.response?.data?.message
        );
        setSubmitting(false);
        setLoading(false);
      });
  };

  const [locationData, setLocationData] = useState();
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    brands();
  }, []);

  useEffect(() => {
    getLocationData();
  }, [state?.dealer_id]);

  const getLocationData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_DEALER_URL}dealer-app/get-dealer-address?dealer_id=${state?.dealer_id}`,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res?.data?.status) {
        setLocationData(res?.data?.data);
      }
    } catch (err) {
      console.log(err);
    }
  };


  const uploadPdFile = async (file, setFieldValue) => {
    try {
      setUploading(true);
      if (!file || !state?.id) {
        throw new Error("Missing file or user ID");
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("id", state?.id);

      formData.forEach((value, key) => {
        console.log(`${key}: ${value}`);
      });

      const response = await insecptionService.uploadPdfFile(formData);

      console.log("File uploaded successfully:", response.fileUrl);
      setFieldValue("service_report", response.fileUrl);
      // setpdfUrl(response.fileUrl);

      setUploading(false);
    } catch (error) {
      console.error("Error in uploadPdFile:", error.message);
      throw error;
    }
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
  };

  const fileInputRef = useRef(null);

  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action));
  };
  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }



  const res = async ()=> {
    try {
      const res = await usersApi.permissionRoles(); 
      setPermission(res); 
    } catch (error) {
      console.error("Error fetching user dropdown:", error);
    }
  }

  useEffect(() => {
    res();
  }, []);

  return (
    <>
      {((hasPermission("Add") || hasPermission("Edit"))|| (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["containerMain"]}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

              alignItems: "center",

              padding: "15px ",
            }}
          >
            <BackButton
              onClick={() => {
                navigate("/inspection-service");
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Edit Inspection
            </Typography>
          </Box>
          <div className={style["container"]}>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema.editPickupRequest}
              onSubmit={editPickupRequest}
            >
              {({
                values,
                errors,
                setFieldValue,
                touched,
                handleBlur,
                handleSubmit,
              }) => (
                <Form className={style["form-container"]} onSubmit={handleSubmit}>
                  <div className={style["wrapper"]}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <InputField
                            type={"text"}
                            label={"DSR Number"}
                            placeholder={"Door Service Request Number"}
                            value={state?.dsCode}
                            disabled
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Location <span style={{ color: "red" }}>*</span>
                          </p>
                          <MultiSelect
                            name="Store"
                            handleChange={(selectedOption) => {
                              setFieldValue("showroom_id", selectedOption.value);
                              // const selectedStore = storeList?.find((ele) => ele.id == selectedOption.value);
                              // console.log("selectedStore ", selectedStore);
                            }}
                            items={storeList?.map((ele) => ({
                              label: ele?.store_name,
                              value: ele?.id,
                            }))}
                            selectedItems={values?.showroom_id}
                            placeholder={"Select Location"}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div className={style["input-wrapper"]}>
                          <InputField
                            type={"date"}
                            name={"schDateTime"}
                            label={"Date of Creation of Request"}
                            placeholder={"Select Date"}
                            value={
                              moment(state?.created_at).format("YYYY-MM-DD") || null
                            }
                            onBlur={handleBlur}
                            disabled
                            onChange={(value) =>
                              setFieldValue("schDateTime", value)
                            }
                            error={touched?.created_at && errors?.created_at}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <div
                          style={{ marginTop: "40px;" }}
                          className={style["input-wrapper"]}
                        >
                          <InputField
                            value={values.vehicle_no}
                            handleChange={(e) =>
                              setFieldValue(
                                "vehicle_no",
                                e.target.value.toUpperCase()
                              )
                            }
                            onBlur={handleBlur}
                            label={"Vehicle Number"}
                            placeholder={"Enter Vehicle Number"}
                            name={"vehicle_no"}
                            length={10}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <div>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Make <span style={{ color: "red" }}>*</span>
                          </p>

                          <MultiSelect
                            name="make_id"
                            handleChange={(selectedOption) => {

                              setFieldValue("make_id", selectedOption.value);
                              models(selectedOption.value);
                            }}
                            items={brandsList?.map((ele) => ({
                              label: ele?.makeName,
                              value: ele?.makeId,
                            }))}
                            selectedItems={values?.make_id || ""}
                            placeholder={"Select Make"}
                            isSearchable={true}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                            required={true}
                            onBlur={() => handleBlur("make_id")}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <div>
                          <p
                            style={{
                              marginBottom: "5px",
                              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                            }}
                          >
                            Model <span style={{ color: "red" }}>*</span>
                          </p>

                          <MultiSelect
                            name="model_id"
                            handleChange={(selectedItems) =>
                              setFieldValue("model_id", selectedItems.value)
                            }
                            items={modelList?.map((ele) => ({
                              label: ele?.modelName,
                              value: ele?.modelId,
                            }))}
                            selectedItems={values?.model_id || ""} // Use the value from Formik's state
                            placeholder={"Select Model"}
                            isSearchable={true}
                            containerStyle={
                              style["selectable-button-container-style"]
                            }
                            selectClass={style["filterDropDown"]}
                            required={true}
                            onBlur={handleBlur}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={style["line"]}></div>
                  <p className={style["label"]}>POC Details</p>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={""}
                          label={"Name"}
                          placeholder={"Enter Name"}
                          value={values.pocName}
                          onBlur={handleBlur}
                          handleChange={(e) =>
                            setFieldValue("pocName", e.target.value)
                          }
                          error={touched?.pocName && errors?.pocName}
                          name={"pocName"}
                          required
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          label={"Mobile Number"}
                          placeholder={"Enter Mobile Number"}
                          value={values.pocMobile}
                          handleChange={(e) =>
                            setFieldValue("pocMobile", e.target.value)
                          }
                          onBlur={handleBlur}
                          length={10}
                          error={touched?.pocMobile && errors?.pocMobile}
                          name={"pocMobile"}
                          required
                        />
                      </div>
                    </Grid>
                  </Grid>
                  <div className={style["line"]}></div>

                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={"date"}
                          name={"prefDateTime"}
                          value={values?.prefDateTime}
                          handleChange={(e) => {
                            const value = e.target.value;
                            if (
                              moment(value).isBefore(moment().format("YYYY-MM-DD"))
                            ) {
                              setFieldValue(
                                "prefDateTime",
                                moment().format("YYYY-MM-DD")
                              );
                            } else {
                              setFieldValue("prefDateTime", value);
                            }
                          }}
                          onBlur={handleBlur}
                          label={"Preferred Date"}
                          placeholder={"Select Date"}
                          error={touched?.prefDateTime && errors?.prefDateTime}
                          required
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <p
                        style={{
                          marginBottom: "5px",
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        }}
                      >
                        Preferred Slot <span style={{ color: "red" }}>*</span>
                      </p>
                      <MultiSelect
                        name="prefSlotId"
                        handleChange={(selectedItems) => {
                          setFieldValue("prefSlotId", selectedItems?.value);
                        }}
                        items={slotList?.map((ele) => ({
                          label: ele?.slot,
                          value: ele?.id,
                        }))}
                        selectedItems={values?.prefSlotId}
                        placeholder="Select Preferred Slots"
                        isSearchable={true}
                        containerStyle={style["selectable-button-container-style"]}
                        selectClass={style["filterDropDown"]}
                        required={true}
                        onBlur={handleBlur}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={"date"}
                          name={"schDateTime"}
                          value={values?.schDateTime}
                          handleChange={(e) => {
                            const value = e.target.value;
                            if (
                              moment(value).isBefore(moment().format("YYYY-MM-DD"))
                            ) {
                              setFieldValue(
                                "schDateTime",
                                moment().format("YYYY-MM-DD")
                              );
                            } else {
                              setFieldValue("schDateTime", value);
                            }
                          }}
                          onBlur={handleBlur}
                          label={"Date of Schedule"}
                          placeholder={"Select Date"}
                          error={touched?.schDateTime && errors?.schDateTime}
                          required
                          min={moment().format("YYYY-MM-DD")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <p
                          style={{
                            marginBottom: "5px",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }}
                        >
                          Scheduled Slot <span style={{ color: "red" }}>*</span>
                        </p>
                        <MultiSelect
                          name="schSlotId"
                          handleChange={(selectedItems) => {
                            setFieldValue("schSlotId", selectedItems?.value);
                          }}
                          items={
                            slotList?.map((ele) => ({
                              label: ele?.slot,
                              value: ele?.id,
                            })) || []
                          }
                          selectedItems={values?.schSlotId}
                          placeholder="Select Scheduled Slots"
                          isSearchable={true}
                          containerStyle={
                            style["selectable-button-container-style"]
                          }
                          selectClass={style["filterDropDown"]}
                          required={true}
                          onBlur={() => handleBlur("schSlotId")}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        {" "}
                        <p
                          style={{
                            marginBottom: "5px",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }}
                        >
                          Evaluator <span style={{ color: "red" }}>*</span>
                        </p>
                        <MultiSelect
                          name="assign_to_evaluator"
                          handleChange={(selectedItems) => {
                            setFieldValue(
                              "assign_to_evaluator",
                              selectedItems?.value
                            );
                          }}
                          items={userListDropdown?.map((status) => ({
                            label: status.firstName,
                            value: status.id,
                          }))}
                          selectedItems={values?.assign_to_evaluator || ""}
                          placeholder="Enter Evaluator Name"
                          isSearchable={true}
                          containerStyle={
                            style["selectable-button-container-style"]
                          }
                          selectClass={style["filterDropDown"]}
                          error={
                            touched?.assign_to_evaluator &&
                            errors?.assign_to_evaluator
                          }
                          onBlur={handleBlur}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <p
                          style={{
                            marginBottom: "5px",
                            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          }}
                        >
                          Stauts <span style={{ color: "red" }}>*</span>
                        </p>
                        <MultiSelect
                          name="Status"
                          handleChange={(selectedOption) => {
                            console.log("Selected Option:", selectedOption);
                            setFieldValue("status", selectedOption?.value);
                          }}
                          items={statusList?.map((status) => ({
                            label: status.name,
                            value: status.id,
                          }))}
                          selectedItems={values?.status}
                          placeholder={"Select Status"}
                          containerStyle={
                            style["selectable-button-container-style"]
                          }
                          selectClass={style["filterDropDown"]}
                          error={touched?.status && errors?.status}
                          onBlur={handleBlur}
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <div className={style["input-wrapper"]}>
                        <InputField
                          type={""}
                          label={"Remarks"}
                          placeholder={"Enter Remarks"}
                          value={values.remarks}
                          onBlur={handleBlur}
                          handleChange={(e) =>
                            setFieldValue("remarks", e.target.value)
                          }
                          error={touched?.remarks && errors?.remarks}
                          required
                        />
                      </div>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      {/* <Box>
            <div className={style["headar-upload-container"]}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <VisuallyHiddenInput
                  type="file"
                  accept=".csv"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                />
                <Button
                  variant="outlined"
                  sx={{
                    color: "#121b5b",
                    border: "1px solid #121b5b",
                  }}
                  onClick={() =>
                    fileInputRef.current && fileInputRef.current.click()
                  }
                >
                  {selectedFile ? (
                    <Tooltip title={selectedFile.name}>
                      <Typography
                        noWrap
                        variant="body1"
                        sx={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedFile.name.split("").slice(0, 10).join("")}...
                      </Typography>
                    </Tooltip>
                  ) : (
                    "Choose File"
                  )}
                </Button>
                {selectedFile && (
                  <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
            
                    <IconButton
                      size="small"
                      onClick={() => setSelectedFile(null)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                )}
              </Box>
  
              <PrimaryButton
                containerStyle={style["add-new-car-button-container-style"]}
               
                name={"Submit"}
                fontSize={"1rem"}
                onClick={handleUpload}
              />
            </div>
                  </Box> */}
                    </Grid>
                  </Grid>
                  <div className={style["wrapper"]}>
                    {/* 
                <div className={style["file-input"]}>
                  <label htmlFor="pdf-file">Attach PDF File</label>
                  <input
                    id="pdf-file"
                    type="file"
                    accept="application/pdf"
                    onChange={(event) => {
                      console.log("Native event: ", event);
                      const file = event?.currentTarget?.files?.[0];
                      console.log("Selected file: ", file);
                      uploadPdFile(file)
                    }}
                    onBlur={handleBlur}
                  />
                </div> */}
                  </div>

                  <div className={style["action-container"]}>
                    <Link
                      to="/Services/DoorServices"
                      className={style["cancel-container"]}
                    >
                      Cancel
                    </Link>

                    <Button
                      sx={{
                        backgroundColor: "#121b5b",
                        "&:hover": {
                          backgroundColor: "#121b5b",
                        },
                      }}
                      variant="contained"
                      type="submit"
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>

      )}
    </>

  );
};

export default EditDoorService;
