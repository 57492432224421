import * as Yup from 'yup';

const validation = {
    documentValut: Yup.object().shape({
        vehicle_no: Yup.string()
            .trim()
            .max(20, "Name contains 20 characters only"),
        other_doc: Yup.string()
            .trim()
            .max(20, "Name contains 20 characters only"),
            document_type : Yup.string().required("document category is required"),    
            document_sub_type : Yup.string().required("document sub category is required"),    
    }).test(
        "at-least-one-field",
        "Either Vehicle No or Document Code is required",
        function (values) {
            if (!values.vehicle_no?.trim() && !values.other_doc?.trim()) {
                return this.createError({
                    path: "vehicle_no",
                    message: "Vehicle No or Document Code is required",
                });
            }
            return true;
        }
    )
};

export default validation;
