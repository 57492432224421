import React, { useState, useEffect } from "react";
import style from "./sellerLead.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import SelectableButton from "../../components/buttons/SelectableButton";
import { R } from "../../constants/resources";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import SellerTable from "./components/table/SellerTable";
import { useDispatch, useSelector } from "react-redux";
import { getSellerList } from "../../store/sellerLead/sellerLeadAction";
import { Box, Pagination, Stack, Typography } from "@mui/material";
import MultiSelect from "../../components/buttons/multiSelect";
import { ROLE_ID } from "../../config";
import usersApi from "../../api/usersApi";
import { UserDealerPermission } from "../../components/permissions";

export default function SellerLead() {
  const [filter, setFilter] = useState({
    status: "1",
    dealerId: localStorage.getItem("dealerId"),
  });
  const [reset, setReset] = useState(false);
  const [permission, setPermission] = useState([]);
  const { checkStatusPermisssion, loading, error } = UserDealerPermission(17);
  const manageLead = permission?.filter(item => item?.slug === "manage_lead");
  const {
    sellerLeadList,
    currentPage,
    totalPageCount,
    totalCount,
    hasNextPage,
    total,
  } = useSelector((state) => state.sellerLead);
  const [currentPageNew, setCurrentPageNew] = useState(1);

  const [searchText, setSearchText] = useState("");
  const [status, setStatus] = useState("");
  console.log("status -->", status);

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 0,
  });

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const [data, setData] = useState(sellerLeadList || []);

  useEffect(() => {
    dispatch(getSellerList());
  }, []);

  // useEffect(() => {
  //   setData(sellerLeadList);
  // }, [sellerLeadList]);

  useEffect(() => {
    setCurrentPageNew(pagination.pageIndex);
  }, []);

  const handlePageChange = (page) => {
    setCurrentPageNew(page);
  };

  const exportToExcel = () => {
    const dataWithoutAction = data.map(({ action, ...rest }) => rest);
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(dataWithoutAction);
      const workbook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      xlsx.writeFile(workbook, "seller_data.xlsx");
    });
  };

  const applyFilter = () => {
    if (Object.values(filter).some((value) => value !== "")) {
      getSellerList();
    }
  };

  const resetFilter = () => {
    setFilter({
      status: "1",
      dealerId: localStorage.getItem("dealerId"),
    });

    setReset(true);
  };

  useEffect(() => {
    if (reset) getSellerList();
  }, [reset]);

  useEffect(() => {
    getSellerList();
  }, []);

  useEffect(() => {
    dispatch(
      getSellerList({ page: pagination.pageIndex, status, search: searchText })
    );
  }, [pagination, status, searchText, dispatch]);

  const handleNextPage = () => {
    if (hasNextPage) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex + 1 }));
    }
  };

  const handlePreviousPage = () => {
    if (pagination.pageIndex > 0) {
      setPagination((prev) => ({ ...prev, pageIndex: prev.pageIndex - 1 }));
    }
  };

  const hasRolePermission = (action) => {
    return manageLead?.some((perm) => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }


  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    res();
  }, []);

  return (
    <>
      {((hasRolePermission("Add") || hasRolePermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["container"]}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Seller Lead ({totalCount})
            </Typography>
          </Box>

          <div className={style["filters-container"]}>
            <div className={style["filters-wrapper"]}>
              <SearchBar
                placeholder={"Seller Name or Phone"}
                handleChange={handleChange}
              />
              <MultiSelect
                containerStyle={style["selectable-button-container-style"]}
                trailingIcon={R.ic_arrow_down_blue}
                items={[
                  { label: "Booked", value: "booked" },
                  { label: "Already Bought", value: "already_bought" },
                  { label: "Not Interested", value: "not_interested" },
                  { label: "Loan Not Approved", value: "loan_not_approved" },
                  { label: "Pending", value: "PENDING" },
                  { label: "Done", value: "done" },
                  { label: "Active", value: "ACTIVE" },
                ]}
                handleChange={(e) => {
                  const newValue = e?.value || ""; // Handle undefined values gracefully
                  console.log("Selected status:", newValue);
                  setStatus(newValue);
                }}
                selectedItems={status}
                hint={"All Status"}
                fontSize="1rem"
              />
              <PrimaryButton
                containerStyle={style["add-new-car-button-container-style"]}
                name={" Filter"}
                fontSize={"1rem"}
                onClick={() => applyFilter()}
              />

              <PrimaryButton
                containerStyle={style["add-new-car-button-container-style"]}
                name={"Reset"}
                fontSize={"1rem"}
                onClick={() => resetFilter()}
              />
            </div>

            <div className={style["action-buttons-wrapper"]}>
              <PrimaryButton
                containerStyle={style["excel-button-container-style"]}
                leadingIcon={R.ic_excel}
                name={"Export Excel"}
                onClick={exportToExcel}
              />
            </div>
          </div>
          <SellerTable
            data={sellerLeadList}
            pagination={pagination}
            setPagination={setPagination}
            total={sellerLeadList?.length || 0}
          />

          <Stack
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            marginTop={"20px"}
          >
            <Pagination
              count={totalPageCount}
              page={currentPage}
              onChange={(event, page) =>
                page === 1 ? handlePreviousPage() : handleNextPage(page)
              }
              variant="outlined"
              shape="rounded"
            />
          </Stack>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>
      )}
    </>
  );
}
