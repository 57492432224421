import { GET_ROLE, ADD_ROLE, EDIT_ROLE, GET_PERMISSION, GET_ROLE_PERMISSION, GET_PARENT_ROLE } from "../types";
import { roleApi } from "../../api/roleApi";

export const fetchDealerRoles = (params) => (dispatch) => {
  return roleApi.getRoles(params).then(
    (response) => {
      dispatch({
        type: GET_ROLE,
        payload: response
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const addDealerRole = (payload) => (dispatch) => {
  return roleApi.addRole(payload).then(
    (response) => {
      dispatch({
        type: ADD_ROLE,
        payload: {
          data: response?.data
        }
      })
      return Promise.resolve(response)
    },
    (error) => {
      return Promise.reject(error);
    }
  )
}

export const editRole = (payload) => (dispatch) => {
  return roleApi.editRole(payload).then(
    (response) => {
      dispatch({
        type: EDIT_ROLE,
        payload: {
          data: response?.data
        }
      })
      return Promise.resolve(response)
    },
    (error) => {
      return Promise.reject(error);
    }
  )
}

export const getPermissions = (params) => (dispatch) => {
  return roleApi.getPermissions(params).then(
    (response) => {
      dispatch({
        type: GET_PERMISSION,
        payload: response?.data
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);

      return Promise.reject(error);
    }
  );
};

export const getRolePermissions = (data) => (dispatch) => {
  return roleApi.getRolePermissions(data).then(
    (response) => {
      dispatch({
        type: GET_ROLE_PERMISSION,
        payload: response?.data
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};


export const fetchParentRole = (params) => (dispatch) => {
  return roleApi.parentRole(params).then(
    (response) => {
      dispatch({
        type: GET_PARENT_ROLE,
        payload: response?.data
      });
      return Promise.resolve(response);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};