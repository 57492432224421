import style from "./style.module.scss";

export const CheckBox = ({ checked, onChange, label, disabled = false }) => {
  return (
    <div className={style["container"]}>
      <input type={"checkbox"} checked={checked} onChange={onChange} disabled={disabled} />
      <label htmlFor="">{label}</label>
    </div>
  );
};
