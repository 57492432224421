
export const getFinalHostName = (hostname) => {
  const parts = hostname.split('.');
  if (parts.length > 2) {
    return parts.slice(-3).join('.');
  }
  return hostname;
};

export const get_url_extension = (url) => {
  return url?.split(/[#?]/)[0].split(".").pop().trim();
};

export const  downloadFile = async (url, extension) => {
  const response = await fetch(url, { mode: "no-cors" });
  const blob = await response.blob();
  const fileUri = URL.createObjectURL(blob);

  const anchor = document.createElement("a");
  anchor.href = fileUri;
  anchor.target = "_blank";
  anchor.download = `file.${extension}`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

