import { testimonials } from ".";

class TestimonialApi {
  saveTestimonials(data) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await testimonials(
            "post",
            'dealer-app-auth/save-testimonials',
            null,
            data,
            token
          );
          resolve(res);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  dropTestimonials(id) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await testimonials(
            "put",
            'dealer-app-auth/drop-testimonials',
            null,
            id,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }


  getTestimonials(params) {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await testimonials(
            "get",
            'dealer-app-auth/get-dealer-testimonials',
            params,
            null,
            token
          );
          resolve(res.data);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

  getDealerPermission() {
    const token = localStorage.getItem("accessToken");
    return new Promise((resolve, reject) => {
      (async () => {
        try {
          const res = await testimonials(
            "get",
            'dealer-app/dealer-permission',
            null,
            null,
            token
          );          
          resolve(res?.data?.result);
        } catch (err) {
          console.log(err);
          reject(err);
        }
      })();
    });
  }

}

export const testimonialsApi = new TestimonialApi();
