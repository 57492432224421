import {
    IconButton,
    List,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import style from "../style.module.scss";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CustomizedDialogs } from "../../../components/tables/DetailTable";
import { useState } from "react";
import documentvalue from "../../../api/documentvalue";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { downloadFile, get_url_extension } from "../../../utils/helpar";

export default function DocumentTable({ data, refreshData }) {
    const [open, setOpen] = useState(false);
    const [dialogBox, setDialogBox] = useState({
        id: "",
        titleMessage: "",
        confirmMessage: "",
        showField: "",
        handleConfirm: () => { },
    });

    const handleClose = () => {
        setOpen(false);
    }

    const handleDelete = async (data) => {
        let payload = {
            id: data?.document_id,
            document_sub_type: data?.document_sub_type
        }
        try {
            await documentvalue.dropDocument(payload);
            refreshData();
            handleClose();
        } catch (error) {
            console.log(error);
        }
    }

    const deleteDocument = (data) => {
        setDialogBox({
            id: data?.document_id,
            titleMessage: "! Warning",
            confirmMessage: "Are you sure you want to delete this document",
            handleConfirm: () => handleDelete(data),
        });
        setOpen(true);
    }

    const handleDownload = async (data) => {
        const url = data?.document_path;
        const extension = get_url_extension(data?.document_path);
        await downloadFile(url, extension);
    };


    const ActionButtons = ({ data }) => {
        return (
            <div className={style["action-container"]}>
                <Stack direction="row" spacing={1} justifyContent="center">
                    <Tooltip title="view" onClick={() => window.open(data?.document_path, "_blank")} >
                        <IconButton>
                            <VisibilityIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Delete" onClick={() => deleteDocument(data)}>
                        <IconButton>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Download" onClick={() => handleDownload(data)} >
                        <IconButton>
                            <FileDownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </div>
        );
    };

    return (
        <div>
            <List>
                <TableContainer
                    component={Paper}
                    sx={{ width: "99%", margin: "0px 10px 0px 6px" }}
                >
                    <Table>
                        <TableHead
                            sx={{
                                backgroundColor: "#f5f5f5",
                                "& .MuiTableCell-root": {
                                    fontWeight: "bold",
                                },
                            }}
                        >
                            <TableRow>
                                <TableCell align="left">Vehicle No</TableCell>
                                <TableCell align="left" >Document Code</TableCell>
                                <TableCell align="left" >Document Category</TableCell>
                                <TableCell align="left">Document Sub Category</TableCell>
                                <TableCell align="left">Created Date</TableCell>
                                <TableCell align="center" >Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.map((car, index) => (
                                <TableRow
                                    key={index}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer",
                                            backgroundColor: "#f5f5f5",
                                        },
                                    }}
                                >
                                    <TableCell>{car?.vehicle_no ? car?.vehicle_no : "N/A"}</TableCell>
                                    <TableCell>{car?.other_doc ? car?.other_doc : "N/A"}</TableCell>
                                    <TableCell>
                                        {car?.document_type}
                                    </TableCell>
                                    <TableCell>{car?.document_sub_type}</TableCell>
                                    <TableCell>
                                        {car?.create_at
                                            ? new Date(car?.create_at).toLocaleDateString()
                                            : "-"}
                                    </TableCell>
                                    <TableCell align="center">
                                        <ActionButtons data={car} />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </List>
            <CustomizedDialogs
                open={open}
                handleClose={handleClose}
                rowSkuId={dialogBox.id}
                titleMessage={dialogBox.titleMessage}
                confirmMessage={dialogBox.confirmMessage}
                showField={dialogBox.showField}
                handleConfirm={dialogBox.handleConfirm}
            />

        </div>
    )
}