import { adminApiCall, dealerAppAuth } from ".";
class RoleApi {
    addRole(data) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await adminApiCall(
                        "post",
                        "dealer/roles/create-roles",
                        null,
                        data,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    editRole(data) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await adminApiCall("put", "dealer/roles/edit-roles", null, data, token);
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    getRoles(params) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app-auth/dealer-user-roles",
                        params,
                        null,
                        token
                    );
                    resolve(res);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    getPermissions(params) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await adminApiCall(
                        "get",
                        "dealer/roles/get-all-permissions",
                        params,
                        null,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    getRolePermissions(params) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await adminApiCall(
                        "get",
                        "dealer/roles/get-role-permissions",
                        params,
                        null,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    parentRole() {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app-auth/dealer-user-parent-role",
                        null,
                        null,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    isActive() {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "put",
                        "dealer-app-auth/isActive-status",
                        {},
                        null,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

    defaultPermissions(params) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await adminApiCall(
                        "get",
                        "dealer/roles/get-parent-role-permissions",
                        params,
                        null,
                        token
                    );
                    resolve(res.data);
                } catch (err) {
                    console.log(err);
                    reject(err);
                }
            })();
        });
    }

}

export const roleApi = new RoleApi();