import { useState, useRef, useEffect } from "react";
import React from "react";
import { Form, Formik } from "formik";
import style from "./create-testimonial.module.scss";
import {
  Grid,
  Box,
  Button,
  Typography,
  TextField,
  Link,
  IconButton,
} from "@mui/material";
import { IoCloseSharp, IoEyeSharp } from "react-icons/io5";
import { testimonialsApi } from "../../../api/testimonials";
import JoditEditor from "jodit-react";
import PrimaryHeading from "../../../components/primary-heading/PrimaryHeading";
import BackButton from "../../../components/buttons/BackButton";
import InputField from "../../../components/input-field/InputField";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ROLE_ID } from "../../../config";
import usersApi from "../../../api/usersApi";
import { UserDealerPermission } from "../../../components/permissions";

const CreateTestimonials = () => {
  const dealer_id = localStorage.getItem("dealerId");
  const dispatch = useDispatch();
  const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);
  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "testimonials");

  const location = useLocation();
  const { data } = location.state || {};
  const testimonials_id = data?.id;
  const initialValues = {
    testimonials_id: data?.id || "",
    descriptions: data?.descriptions || "",
    customer_name: data?.customer_name || "",
    status: data?.status || "1",
    image: data?.image || ""
  };


  const navigate = useNavigate();

  const editor = useRef(null);

  const handleSubmit = async (values) => {
    try {
      const res = await testimonialsApi.saveTestimonials(values);
      navigate("/testimonials");
    } catch (error) {
      console.log("error", error);
    }
  };

  const UploadDocument = async (e, setFieldValue) => {
    try {
      const token = localStorage.getItem("accessToken");
      if (e.target.files) {
        const formData = new FormData();
        formData.append("file", e.target.files[0]);
        formData.append("container", "testimonials");
        formData.append("id", dealer_id);

        const res = await axios.post(
          `${process.env.REACT_APP_SERVICE_HISTORY_URL}app/uploadFiles`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (res?.data?.status) {
          setFieldValue("image", res?.data?.fileUploadedLinks[0]);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleRemoveFile = (setFieldValue) => {
    setFieldValue('image', null);
  };

  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }

  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    res();
  }, []);

  return (
    <>
      {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style.container1}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              alignItems: "center",
              padding: "15px ",
            }}
          >
            <BackButton
              onClick={() => {
                navigate("/testimonials");
              }}
            />
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Edit Testimonial
            </Typography>
          </Box>

          <Grid
            spacing={2}
            sx={{
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
              marginTop: "10px",
              padding: "20px",
            }}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Formik
                initialValues={initialValues} // Set initialValues here
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Grid item xs={12}>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label="Customer Name"
                          name="customer_name"
                          size="small"
                          variant="outlined"
                          onChange={handleChange}
                          error={errors.customer_name}
                          touched={touched.customer_name}
                          value={values.customer_name}
                        />
                      </Grid>
                    </Grid>


                    <Grid marginTop={2} container spacing={2}>
                      <Grid item xs={6}>
                        <Typography variant="h6">Testimonial</Typography>
                        <JoditEditor
                          ref={editor}
                          name="descriptions"
                          value={values.descriptions}
                          onBlur={(newContent) =>
                            setFieldValue("descriptions", newContent)
                          }
                          config={{
                            placeholder: testimonials_id ? "" : "write content",
                            disablePlugins: "paste",
                            style: {
                              height: "300px",
                              overflowY: "auto",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            height: "400px",
                            marginTop: "30px",
                            width: "100%",
                            border: "2px dashed #ccc",
                            borderRadius: "5px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: "#f9f9f9",
                            position: "relative",
                            transition: "all 0.3s",
                            "&:hover": {
                              backgroundColor: "#f1f1f1",
                              borderColor: "#007bff",
                            },
                          }}
                        >
                          {values?.image ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <Box
                                component="img"
                                src={values?.image}
                                alt="File Preview"
                                sx={{
                                  maxHeight: "150px",
                                  maxWidth: "100%",
                                  borderRadius: "5px",
                                  objectFit: "contain",
                                }}
                              />

                              <Box mt={2} display="flex" gap={1}>
                                <IconButton
                                  component="a"
                                  href={values?.image}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  sx={{
                                    backgroundColor: "#007bff",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#0056b3" },
                                  }}
                                >
                                  <IoEyeSharp size={20} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleRemoveFile(setFieldValue)}
                                  sx={{
                                    backgroundColor: "#dc3545",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#c82333" },
                                  }}
                                >
                                  <IoCloseSharp size={20} />
                                </IconButton>
                              </Box>
                            </Box>
                          ) : (
                            <>
                              <label
                                htmlFor="fileUrlInput"
                                style={{ cursor: "pointer", textAlign: "center" }}
                              >
                                <Typography
                                  variant="h6"
                                  color="textSecondary"
                                  sx={{ mb: 1 }}
                                >
                                  Upload File
                                </Typography>
                                <Button
                                  variant="contained"
                                  component="span"
                                  sx={{
                                    backgroundColor: "#121b5b",
                                    color: "#fff",
                                    "&:hover": { backgroundColor: "#121b5b" },
                                  }}
                                >
                                  Browse File
                                </Button>
                              </label>
                              <input
                                id="fileUrlInput"
                                type="file"
                                onChange={(e) =>
                                  UploadDocument(e, setFieldValue)
                                }
                                name="image"
                                style={{
                                  display: "none",
                                }}
                              />
                            </>
                          )}
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      sx={{ marginTop: "10px" }}
                      item
                      xs={12}
                      container
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Grid item>
                        <Button
                          sx={{
                            backgroundColor: "#121b5b",
                            color: "#fff",
                            "&:hover": {
                              backgroundColor: "#121b5b",
                              color: "#fff",
                            },
                          }}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className={style["form-published"]}
                        >
                          Save & Publish
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>

      )}
    </>
  );
};

export default CreateTestimonials;
