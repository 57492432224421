
import { Formik, Form } from "formik";
import {
    Grid,
    Box,
    Button,
    Typography,
    TextField,
    Link,
    IconButton,
} from "@mui/material";
import BackButton from "../../../components/buttons/BackButton";
import { useLocation, useNavigate } from "react-router-dom";
import style from "./user.module.scss";
import InputField from "../../../components/input-field/InputField";
import SelectableButton from "../../../components/buttons/SelectableButton";
import { R } from "../../../constants/resources";
import usersApi from "../../../api/usersApi";
import { useEffect, useState } from "react";
import { saveDealerUsers } from "../../../store/users/userAction";
import { useDispatch, useSelector } from "react-redux";
import { fetchDealerAddress } from "../../../store/car/carAction";
import validationSchema from "../../../components/validation/validationSchema";
import { RiErrorWarningLine } from "react-icons/ri";
import { fetchDealerRoles } from "../../../store/roles/roleAction";
import { ROLE_ID } from "../../../config";
import { UserDealerPermission } from "../../../components/permissions";

function UserManagement() {

    const { dealerAddress } = useSelector((state) => state?.car || []);
    const roleList = useSelector((state) => state?.roleReducers?.roleList?.data || []);
    const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);

    const navigate = useNavigate();
    const location = useLocation();
    const state = location?.state || "";
    const dispatch = useDispatch();
    const [permission, setPermission] = useState([]);
    const findData = permission?.filter(item => item?.slug === "user");


    const initalvalues = {
        id: state?.id || null,
        firstname: state?.firstname || "",
        lastname: state?.lastname || "",
        email: state?.email || "",
        phone: state?.phone || "",
        status: state?.status || "",
        password: "",
        user_role_id: state?.user_role_id || "",
        store_id: state?.store_id || "",
        emp_id: state?.emp_id || ""
    }

    const addUser = (values) => {
        const findRole = roleList.find(item => item?.id == values?.user_role_id);
        const payloadValue = {
            ...values,
            roleId: findRole.parent_role_id
        }
        const payload = Object.fromEntries(Object.entries(payloadValue).filter(([Key, value]) => value !== undefined && value !== null && value !== ""));
        dispatch(saveDealerUsers(payload)).then(res => {
            console.log("res ===>", res);
            navigate("/user-list")
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        const role_type = 1;
        dispatch(fetchDealerAddress())
        dispatch(fetchDealerRoles({ role_type }));
    }, [dispatch])



    const checkStatus = (action) => {
        if (!checkStatusPermisssion) { return false }
        return checkStatusPermisssion.status === action
    }



    return (
        <>
            {(localStorage.getItem("roleId") == ROLE_ID && checkStatus("active")) ? (
                <div className={style['heading']}>
                    <Box
                        sx={{
                            width: "100%",
                            display: "flex",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                            alignItems: "center",
                            padding: "15px ",
                        }}
                    >
                        <BackButton
                            onClick={() => {
                                navigate("/user-list");
                            }}
                        />
                        <Typography
                            variant="h6"
                            component="div"
                            sx={{ fontWeight: 600, px: 2 }}
                        >
                            {state?.id ? "Edit Users" : "Create Users"}
                        </Typography>
                    </Box>

                    <div style={{ marginTop: "10px" }}>
                        <Formik
                            initialValues={initalvalues}
                            validationSchema={validationSchema.userManagement(state?.id || "")}
                            enableReinitialize
                            onSubmit={addUser}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                setFieldValue,
                                isSubmitting,

                            }) => (
                                <Form>
                                    <div className={style['header-wrapper']}>
                                        <div className={style["first-heading"]}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Employee Code</label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                type={"text"}
                                                                name="emp_id"
                                                                handleChange={handleChange}
                                                                value={values.emp_id}
                                                                onBlur={handleBlur}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Role <span className={style['errors']}>*</span> </label>
                                                        <div className={style['sel-wrapper']}>
                                                            <SelectableButton
                                                                trailingIcon={R.ic_arrow_down}
                                                                containerStyle={style["selectable-button-container-style"]}
                                                                handleChange={(e) => {
                                                                    setFieldValue("user_role_id", +e.target.value);
                                                                }}
                                                                items={roleList?.filter(res => res?.status !== 0)?.map(res => ({
                                                                    label: res?.role_name,
                                                                    value: res?.id
                                                                }))}
                                                                value={values?.user_role_id}
                                                                hint={"select role"}
                                                            />
                                                            {errors?.user_role_id && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.user_role_id}</span>}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}></Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>First Name</label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                type={"text"}
                                                                name="firstname"
                                                                handleChange={handleChange}
                                                                value={values.firstname}
                                                                onBlur={handleBlur}
                                                                autoComplete="off"
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Last Name <span className={style['errors']}>*</span> </label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                type={"text"}
                                                                name="lastname"
                                                                handleChange={handleChange}
                                                                value={values.lastname}
                                                                onBlur={handleBlur}
                                                                autoComplete="off"
                                                            />
                                                        </div>

                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Store</label>
                                                        <div className={style['sel-wrapper']}>
                                                            <SelectableButton
                                                                trailingIcon={R.ic_arrow_down}
                                                                containerStyle={style["selectable-button-container-style"]}
                                                                handleChange={(e) => {
                                                                    setFieldValue("store_id", e.target.value);
                                                                }}
                                                                items={dealerAddress?.filter(item => item?.id && item?.store_name)?.map(res => ({
                                                                    label: res?.store_name,
                                                                    value: res?.id
                                                                }))}
                                                                hint={"select store"}
                                                                value={values?.store_id}
                                                            />
                                                            {errors?.store_id && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.store_id}</span>}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Email<span className={style['errors']}>*</span></label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                type={"text"}
                                                                name="email"
                                                                handleChange={handleChange}
                                                                value={values.email}
                                                                autoComplete="off"
                                                                onBlur={handleBlur}
                                                            />
                                                            {errors?.email && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.email}</span>}
                                                        </div>
                                                    </div>

                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Password<span className={style['errors']}>*</span></label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                type={"text"}
                                                                name="password"
                                                                handleChange={handleChange}
                                                                value={values.password}
                                                                onBlur={handleBlur}
                                                                autoComplete="off"
                                                            />
                                                            {errors?.password && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.password}</span>}
                                                        </div>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Mobile <span className={style['errors']}>*</span></label>
                                                        <div className={style['sel-wrapper']}>
                                                            <InputField
                                                                name="phone"
                                                                handleChange={handleChange}
                                                                value={values?.phone}
                                                                length={10}
                                                                regexPattern={/^[\d,]+$/}
                                                                autoComplete="off"
                                                            />
                                                            {errors?.phone && <span className={style['errors']}><RiErrorWarningLine className={style['blank-error']} />{errors.phone}</span>}
                                                        </div>
                                                    </div>
                                                </Grid>

                                                <Grid item xs={12} sm={6} md={4}>
                                                    <div className={style['select-wraapper']}>
                                                        <label>Status</label>
                                                        <div className={style['sel-wrapper']}>
                                                            <SelectableButton
                                                                trailingIcon={R.ic_arrow_down}
                                                                containerStyle={style["selectable-button-container-style"]}
                                                                handleChange={(e) => {
                                                                    setFieldValue("status", e.target.value);
                                                                }}
                                                                items={[
                                                                    { label: "Active", value: "1" },
                                                                    { label: "Inactive", value: "0" }
                                                                ]}
                                                                hint={"status"}
                                                                value={values?.status}
                                                            />
                                                        </div>
                                                    </div>
                                                </Grid>
                                            </Grid>

                                        </div>

                                        <div className={style['action-wrapper']}>
                                            <div>
                                                {/* <Button
                                                sx={{
                                                    backgroundColor: "#121b5b",
                                                    color: "#fff",
                                                    "&:hover": {
                                                        backgroundColor: "#121b5b",
                                                        color: "#fff",
                                                    },
                                                }}
                                                type="button" // isko button rakhein kyunki ye submit nahi hai
                                                variant="contained"
                                                color="primary"
                                                className={style["form-published"]}
                                                onClick={() => navigate("/user-list")} // Navigate back
                                            >
                                                Back
                                            </Button> */}
                                            </div>
                                            <div>
                                                <Button
                                                    sx={{
                                                        backgroundColor: "#121b5b",
                                                        color: "#fff",
                                                        "&:hover": {
                                                            backgroundColor: "#121b5b",
                                                            color: "#fff",
                                                        },
                                                    }}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className={style["form-published"]}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </div>

                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            ) : (
                <Typography
                    sx={{
                        textAlign: "center",
                        marginTop: "20px",
                        fontSize: "18px",
                        color: "red",
                        fontWeight: "bold",
                    }}
                >
                    You do not have permission to view this content.
                </Typography>

            )}
        </>
    )
}

export default UserManagement;
