import style from "../../screens/buyer-lead/buyerLead.module.scss";
import {
    Card,
    CardContent,
    Grid,
    IconButton,
    List,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
    Stack
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import moment from "moment/moment";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { ToggleSwitch } from "../toogleSwitch";
import { fetchParentRole } from "../../store/roles/roleAction";
import { useDispatch, useSelector } from "react-redux";
import usersApi from "../../api/usersApi";

export default function RoleTable({ data }) {
    const [userData, setUserData] = useState([]);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { parentRoleList } = useSelector((state) => state?.roleReducers || []);

    const onEditClick = (data) => {
        navigate("/create-role", { state: data })
    }

    const handleStatusChange = async (userId, newStatus) => {
        try {
            await usersApi.isActiveStatus({ id: userId, newActiveState: newStatus });
            setUserData((prevData) =>
                prevData.map((user) =>
                    user.id === userId ? { ...user, status: newStatus } : user
                )
            );
        } catch (error) {

        }
        console.log(`User ${userId} status changed to ${newStatus}`);
    }

    useEffect(() => {
        dispatch(fetchParentRole())
    }, [])

    useEffect(() => {
        setUserData(data);
    }, [data]);



    const ActionButtons = ({ data, onStatusChange }) => {
        return (
            <Stack direction="row" spacing={1}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => onEditClick(data)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>

                <ToggleSwitch
                    enabled={data.status === 1}
                    onChange={() => onStatusChange(data.id, data.status === 1 ? 0 : 1)}
                    className={style["toggle-action"]}
                />

            </Stack>
        );
    };


    const StatusLabel = ({ status }) => {

        let label;
        let backgroundColor;

        switch (status) {
            case 1:
                label = "Active";
                backgroundColor = "#E6FFD9";
                break;
            case 0:
                label = "Inactive";
                backgroundColor = "#FFE3E1";
                break;
            default:
                label = "Unknown";
                backgroundColor = "#F0F0F0";
        }
        return (
            <span
                style={{
                    backgroundColor: backgroundColor,
                    padding: "0px 10px",
                    borderRadius: "20px",
                    color: "black",
                    textAlign: "center",
                    marginBottom: "0px",
                }}
            >
                {label}
            </span>
        );
    };

    const ParentRoleName = (id) => { return parentRoleList.find((role) => role.id === id)?.name || "N/A"; }


    const roleId = localStorage.getItem("roleId");


    return (
        <>
            
                <div
                    className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
                >
                    <TableContainer
                        component={Paper}
                        sx={{ marginTop: '20px' }}
                    >
                        <Table>
                            <TableHead
                                sx={{
                                    backgroundColor: "#f5f5f5",
                                    "& .MuiTableCell-root": {
                                        fontWeight: "bold",
                                    },
                                }}
                            >
                                <TableRow>
                                    <TableCell align="left">S.No</TableCell>
                                    <TableCell align="left">Role Name</TableCell>
                                    <TableCell align="left">Parent Role</TableCell>
                                    <TableCell align="left">Created On</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell align="center">Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userData?.map((user, index) => (
                                    <TableRow key={user.id}>
                                        <TableCell align="left">{index + 1}</TableCell>
                                        <TableCell align="left">{user?.role_name || "N/A"}</TableCell>
                                        <TableCell align="left">{ParentRoleName(user?.parent_role_id) || "N/A"}</TableCell>
                                        <TableCell align="left">  {user?.create_at ? moment(user.create_at).format("DD-MM-YYYY") : "N/A"}</TableCell>
                                        <TableCell align="left">
                                            <StatusLabel status={user?.status} />
                                        </TableCell>
                                        <TableCell align="left" style={{ display: "flex", justifyContent: "center" }}>
                                            <ActionButtons data={user} onStatusChange={handleStatusChange} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>
        </>
    )
}