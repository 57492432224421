import React, { useState, useEffect } from "react";
import style from "./inspectionTable.module.scss";
import { CustomizedMenus } from './Item.jsx';
import moment from "moment";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import { FaUser, FaPhone, FaCalendarAlt, FaCar } from "react-icons/fa";
import { R } from "../../../constants/resources";
import { buyerApi } from "../../../api/buyerLeadApi";
import {
  Checkbox,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Chip } from "@mui/material";
import { toast } from "react-toastify";
import { editDoorStep } from "../../../store/inspection/inspectionService.js";
import { MP_DEALER_ID } from "../../../../src/config.js";




export default function SellerTable({ data }) {
  const navigate = useNavigate();
  const [showMoreTooltip, setShowMoreTooltip] = useState(false);
  const [checkedRows, setCheckedRows] = useState({});
  const [inspectionQaList, setInspectionQa] = useState([]);
  const [evaltorList, setEvaluator] = useState([]);
  const [assignedEvaluator, setAssignedEvaluator] = useState({});
  const [assignedInspectionQa, setAssignedInspectionQa] = useState({});

  const isMPDealer = (serviceDealerId) => {
    return serviceDealerId === MP_DEALER_ID;
  };

  const handleParentCheckboxChange = () => {
    const nonMPDealerRows = data?.filter(row => !isMPDealer(row.service_dealer_id));
    
    if (nonMPDealerRows.length > 0) {
      const newCheckedState = !nonMPDealerRows.every(row => checkedRows[row.id]);
      const newCheckedRows = {...checkedRows};
      
      nonMPDealerRows.forEach((row) => {
        newCheckedRows[row.id] = newCheckedState;
      });
      
      setCheckedRows(newCheckedRows);
    }
  };

  const handleRowCheckboxChange = (id, serviceDealerId) => {
    if (!isMPDealer(serviceDealerId)) {
      setCheckedRows((prev) => ({
        ...prev,
        [id]: !prev[id],
      }));
    }
  };

  const Status = ({ value }) => {
    let color;
    let label;
    switch (value) {
      case 1:
        color = "black";
        label = "Pending";
        break;
      case 2:
        color = "#FFA500";
        label = "InProgress";
        break;
      case 3:
        color = "#1c4d1c";
        label = "Completed";
        break;
      case 4:
        color = "#F44336";
        label = "Cancelled";
        break;
      case 5:
        color = "#ADD8E6";
        label = "Under QA";
        break;
      case 6:
        color = "#FFD700";
        label = "Approve";
        break;
      case 7:
        color = "#F08080";
        label = "Draft";
        break;
      default:
        color = "transparent";
        label = "NAN";
    }

    return (
      <Chip
        label={label}
        sx={{
          backgroundColor: "transparent",  
          color: color, 
          borderRadius: "15px",
          fontWeight: "500",
          padding: "4px",
          paddingTop: "6px",
          display: "inline-block",
          textAlign: "center",
        }}
      />
    );
  };

  const onEditClick = (data, link) => {
    if (!isMPDealer(data.service_dealer_id)) {
      if (link === "upload-images") {
        navigate(`/${link}`, { state: data?.carSkuId });
      } else {
        navigate(`/${link}`, { state: data });
      }
    }
  };

  const ActionButtons = ({ data }) => {
    const disabled = isMPDealer(data.service_dealer_id);
    
    return (
      <div className={style["action-container"]}>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Tooltip title={disabled ? "Action not available for motorpedia inspection" : "Edit"}>
            <span>
              <IconButton
                onClick={() => onEditClick(data, "edit-inspection-service")}
                disabled={disabled}
                style={{ 
                  opacity: disabled ? 0.5 : 1,
                  cursor: disabled ? "not-allowed" : "pointer"
                }}
              >
                <img
                  src={R.ic_preference_logo}
                  alt="ic_edit"
                  style={{ width: 24, height: 24 }}
                />
              </IconButton>
            </span>
          </Tooltip>
          
          
            <span>
              <CustomizedMenus data={data} />
            </span>
          
        </Stack>
      </div>
    );
};

  const handleAssignedToSelect = async () => {
    const getDealerId = localStorage.getItem("dealerId");
    try {
      const res = await buyerApi.getDealerSubUser();
      if (res?.status) {
        const inspectionQa = res?.data.filter((user) => user.parentRoleId == 67);
        const evaluattor = res?.data.filter((user) => user.parentRoleId == 66);

        setInspectionQa(inspectionQa);
        setEvaluator(evaluattor);
      }
    } catch (error) {
      console.error("Error in get-dealers-sub-user:", error);
    }
  };

  const handleEvaluatorChange = async (id, value, serviceDealerId) => {
    if (isMPDealer(serviceDealerId)) return;

    setAssignedEvaluator((prev) => ({
      ...prev,
      [id]: value,
    }));
  
    const reqBody = {
      reqId: id,
      updateDetails: {
        assign_to_evaluator: value,
      },
    };

    try {
      const response = await editDoorStep(reqBody);
      if (response?.status == 1) {
        toast.success("Evaluator assigned successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while assigning the evaluator.");
      console.error(error);
    }
  };

  const handleInspectionQaChange = async (id, value, serviceDealerId) => {
    if (isMPDealer(serviceDealerId)) return;

    setAssignedInspectionQa((prev) => ({
      ...prev,
      [id]: value,
    }));
  
    const reqBody = {
      reqId: id,
      updateDetails: {
        assign_to_inspection_qa: value,
      },
    };
  
    try {
      const response = await editDoorStep(reqBody);
      if (response?.status == 1) {
        toast.success("Inspection QA assigned successfully!");
      }
    } catch (error) {
      toast.error("An error occurred while assigning the Inspection QA.");
      console.error(error);
    }
  };

  useEffect(() => {
    handleAssignedToSelect();
  }, []);

  const allMPDealer = data?.every(row => isMPDealer(row.service_dealer_id));

  return (
    <div className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}>
      <TableContainer component={Paper} sx={{ width: "99%", margin: "1px 10px 5px 6px" }}>
        <Table>
          <TableHead
            sx={{
              backgroundColor: "#f5f5f5",
              "& .MuiTableCell-root": {
                fontWeight: "bold",
                height: "75px",
                textAlign: "left",
              },
            }}
          >
            <TableRow>
              <TableCell sx={{ width: "50px", textAlign: "center" }}>
                <Checkbox
                  checked={
                    data?.length > 0 &&
                    Object.values(checkedRows).every((val) => val)
                  }
                  indeterminate={
                    Object.values(checkedRows).some((val) => val) &&
                    !Object.values(checkedRows).every((val) => val)
                  }
                  onChange={handleParentCheckboxChange}
                  disabled={allMPDealer}
                />
              </TableCell>
              <TableCell sx={{ width: "120px", textAlign: "left" }}>
                DSSR. No
              </TableCell>
              <TableCell sx={{ width: "200px", textAlign: "left" }}>
                Service POC Details
              </TableCell>
              <TableCell sx={{ width: "150px", textAlign: "left" }}>
                Vehicle Details
              </TableCell>
              <TableCell sx={{ width: "180px", textAlign: "left" }}>
                Scheduled Date
              </TableCell>
              <TableCell sx={{ width: "180px", textAlign: "left" }}>
                Preferred Date
              </TableCell>
              <TableCell sx={{ width: "180px", textAlign: "left" }}>
                Evaluator
              </TableCell>
              <TableCell sx={{ width: "180px", textAlign: "left" }}>
                Inspection QA
              </TableCell>
              <TableCell sx={{ width: "100px", textAlign: "left" }}>
                Status
              </TableCell>
              <TableCell sx={{ width: "100px", textAlign: "center" }}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableRow-root": {
                borderBottom: "1px solid #e0e0e0",
              },
              "& .MuiTableCell-root": {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                borderBottom: "none",
                textAlign: "left",
              },
            }}
          >
            {data?.map((car) => {
              const disabled = isMPDealer(car.service_dealer_id);
              
              return (
                <TableRow
                  key={car.id}
                  sx={{
                    "&:hover": {
                      cursor: "pointer",
                      backgroundColor: "#f5f5f5",
                    },
                  }}
                >
                  <TableCell sx={{ width: "120px", textAlign: "left" }}>
                    <Checkbox
                      checked={checkedRows[car.id] || false}
                      onChange={() => handleRowCheckboxChange(car.id, car.service_dealer_id)}
                      disabled={disabled}
                    />
                  </TableCell>
                  <TableCell sx={{ width: "120px", textAlign: "left" }}>
                    {car?.dsCode}
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      textAlign: "left",
                    }}
                  >
                    <div className={style["poc-details"]}>
                      <div className={style["row"]}>
                        <FaUser />
                        <span>{car?.pocName}</span>
                      </div>
                      <div className={style["row"]}>
                        <FaPhone />
                        <span>{car?.pocMobile}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      width: "200px",
                      textAlign: "left",
                    }}
                  >
                    <div className={style["car-details"]}>
                      <div className={style["row"]}>
                        <FaCar />
                        <span>{car?.vehicle_no || ""}</span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: "180px", textAlign: "left" }}>
                    <div className={style["poc-details"]}>
                      <div className={style["row"]}>
                        <FaCalendarAlt />
                        <span>
                          {moment(car?.schDateTime).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: "180px", textAlign: "left" }}>
                    <div className={style["sch-date"]}>
                      <div className={style["row"]}>
                        <FaCalendarAlt />
                        <span>
                          {moment(car?.prefDateTime).format("DD-MM-YYYY")}
                        </span>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell sx={{ width: "180px", textAlign: "left" }}>
                    <select
                      className={style["telecaller-select"]}
                      value={assignedEvaluator[car.id] || car?.assign_to_evaluator || ""}
                      onChange={(e) => handleEvaluatorChange(car.id, e.target.value, car.service_dealer_id)}
                      disabled={disabled}
                      style={{
                        cursor: disabled ? "not-allowed" : "pointer",
                        opacity: disabled ? 0.7 : 1,
                        backgroundColor: disabled ? "#f5f5f5" : "white"
                      }}
                    >
                      <option value="" disabled>
                        Select Evaluator
                      </option>
                      {evaltorList.map((exec) => (
                        <option key={exec.id} value={exec.id}>
                          {exec.firstName}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell sx={{ width: "180px", textAlign: "left" }}>
                    <select
                      className={style["telecaller-select"]}
                      value={assignedInspectionQa[car.id] || car?.assign_to_inspection_qa || ""}
                      onChange={(e) => handleInspectionQaChange(car.id, e.target.value, car.service_dealer_id)}
                      disabled={disabled}
                      style={{
                        cursor: disabled ? "not-allowed" : "pointer",
                        opacity: disabled ? 0.7 : 1,
                        backgroundColor: disabled ? "#f5f5f5" : "white"
                      }}
                    >
                      <option value="" disabled>
                        Select QA
                      </option>
                      {inspectionQaList.map((exec) => (
                        <option key={exec.id} value={exec.id}>
                          {exec.firstName}
                        </option>
                      ))}
                    </select>
                  </TableCell>
                  <TableCell sx={{ width: "100px", textAlign: "left" }}>
                    <Status value={car?.status} />
                  </TableCell>
                  <TableCell sx={{ width: "100px", textAlign: "left" }}>
                    <ActionButtons data={car} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}