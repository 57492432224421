import style from "./style.module.scss";
import tableStyle from "../../theme/table.module.scss";
import {
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useCallback, useEffect, useState } from "react";
import { R } from "../../constants/resources.js";
import { useNavigate } from "react-router-dom";
import { getDoorStep } from "../../store/inspection/inspectionService.js";
import { insecptionService } from "../../api/insecptionApi.js";
import debounce from "lodash/debounce";
import { MP_DEALER_ID } from "../../../src/config.js";
import MultiSelect from "../../components/buttons/multiSelect";
import {
  Box,
  Pagination,
  Stack,
  IconButton,
  Modal,
  Typography,
  Checkbox,
  MenuItem,
  Button
} from "@mui/material";
import PrimaryButton from "../../components/buttons/PrimaryButton.jsx";
import InspectionTable from "../../screens/inspection-service/table/InspectionTable.jsx";
import CloseIcon from "@mui/icons-material/Close";
import { buyerApi } from "../../api/buyerLeadApi";
import SearchBar from "../../components/search-bar/SearchBar.jsx";

export function InspectionService() {

  const [serviceList, setServiceList] = useState([]);
  const [advanceFilter, setAdvanceFilter] = useState(false);
  const [req_id, setReqId] = useState("");
  const [dealer_info, setDealerInfo] = useState("");
  const [dealer_phone, setDealerPhone] = useState("");
  const [start_date, setStartDate] = useState("");
  const [end_date, setEndDate] = useState("");
  const [status, setStatus] = useState("");
  const [serviceId, setService] = useState("");
  const [evaluatorId, setEvaluator] = useState("")
  const [qaId, setQaId] = useState("")
  const [filterStatus, setFilterStatus] = useState([]);
  const [userListDropdown, setUserListDropdown] = useState([]);
  const [isAssignedTo, setIsAssignedTo] = useState("");
  const [assignedToData, setAssignedToData] = useState([]);
  const [assignedModel, setAssignedModel] = useState(false);
  const [usersRoleName, setUsersRoleName] = useState();
  const [checkbox, setCheckbox] = useState({});
  const [loading, setLoading] = useState(false);
  const [pickUpRequestList, setPickUpRequestList] = useState([]);
  const [total, setTotal] = useState(0);
  const [inspectionQaList, setInspectionQa] = useState([]);
  const [evaltorList, setEvaluatorList] = useState([]);
  const [servcieDealerId, setServiceDealerId] = useState("");

  const allowedToViewTable = true;
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 10,
  });

  const [filter, setFilter] = useState({
    searchString: "",
    leadRating: "",
    leadStatus: "",
    startDate: "",
    endDate: "",
    salesPoc: "",
    talecaller: "",
    user: "",
  });

  useEffect(() => {
    const dealerId = localStorage.getItem("dealerId") || "";
    if (dealerId) {
      setServiceDealerId(dealerId);
    }
  }, []);
  



  
  const getServiceList = async () => {
    const res = await insecptionService.getDSServiceList();
    setServiceList(res?.data);
  };

  useEffect(() => {
    getServiceList();
  }, []);


  useEffect(() => {
    const fetchUserDropdown = async () => {
      try {
        const response = await buyerApi.getDealerSubUser();
        if (response?.status) {
          const inspectionQa = response.data.filter((user) => user.parentRoleId === 67);
          const evaluator = response.data.filter((user) => user.parentRoleId === 66);
  
          setInspectionQa(inspectionQa);
          setEvaluatorList(evaluator);
        }
      } catch (error) {
        console.error("Error fetching user dropdown:", error);
      }
    };
  
    fetchUserDropdown();
  }, [serviceId]);
  

  const debouncedChangeHandler = useCallback(
    debounce((e) => {
      let { value, id } = e.target;
      if (id === "req_id") {
        setReqId(value);
        handelPagination();
      }
      if (id === "dealer_info") {
        setDealerInfo(value);
        handelPagination();
      }
      if (id === "dealer_phone") {
        setDealerPhone(value);
        handelPagination();
      }
    }, 700),
    []
  );


  const onGetDoorStep = async () => {
    setLoading(true);
    try {
      const response = await getDoorStep({
        page: pagination.pageIndex ,
        pagesize: pagination.pageSize,
        req_id: req_id || undefined,
        dealer_info: dealer_info || undefined,
        dealer_phone: dealer_phone || undefined,
        start_date: start_date || undefined,
        end_date: end_date || undefined,
        status: status || undefined,
        serviceId: 3,
        evaluatorId: evaluatorId || undefined,
        qaId: qaId || undefined,
        service_dealer_id: servcieDealerId || undefined,
      });

      setPickUpRequestList(response?.data || []);
      setTotal(response?.totalPageCount);

    } catch (error) {
      console.error("Error fetching door step data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = (value) => {
    if (value === "inspected") {
      const dealerId = localStorage.getItem("dealerId") || "";
      setServiceDealerId(dealerId);
  
      setPagination((prev) => ({
        ...prev,
        pageIndex: 1, 
      }));
    } else if (value === "motorpedia") {
      setServiceDealerId(MP_DEALER_ID);
  
      setPagination((prev) => ({
        ...prev,
        pageIndex: 1,
      }));
    }
  };
  



  const handelPagination = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 1,
    }));
  };

  useEffect(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 1,
    }));
  }, [req_id, dealer_info, dealer_phone, start_date, end_date, status]);

  useEffect(() => {
    onGetDoorStep();
  }, [pagination, servcieDealerId]);

  useEffect(() => {}, [pickUpRequestList]);

  const getDSStatus = async () => {
    const res = await insecptionService.getDSStatusFilter();
    setFilterStatus(res?.data);
  };

  useEffect(() => {
    if (allowedToViewTable) getDSStatus();
  }, []);

  const isSomeSelected = 0;

  const handleAssignedTo = () => {};

  const handleAssignedToBtn = async (btnName) => {
    let role = btnName  == "Evaluator" ? 66 : 67;
    setIsAssignedTo(btnName);
    try {
      const res = await buyerApi.getDealerSubUser({ roleId: role });

      if (res?.status) {
        setAssignedToData(res?.data);
        setAssignedModel(true);
        setUsersRoleName(res?.data[0].roleName);
      }
    } catch (error) {
      console.error("Error in get-dealers-sub-user:", error);
    }
  };

  const pageLimitOptions = [
    { label: "15", value: "15" },
    { label: "30", value: "30" },
    { label: "50", value: "50" },
    { label: "100", value: "100" },
    { label: "200", value: "200" },
  ];

  const dateOptions = [
    { label: "Today", value: "today" },
    { label: "Yesterday", value: "yesterday" },
    { label: "Last 2 days", value: "last2days" },
    { label: "Last week", value: "lastweek" },
  ];

  const handlePageLimitChange = (selectedValue) => {
    if (selectedValue) {
      setPagination((prev) => ({
        ...prev,
        pageSize: parseInt(selectedValue, 10), 
      }));
     
    }
  };

  
  const columns = [
    {
      accessorKey: "check",
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
          inputProps={{ "aria-label": "Select all rows" }}
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          inputProps={{ "aria-label": `Select row ${row.id}` }}
        />
      ),
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      accessorKey: "firstName",
      header: "First Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "lastName",
      header: "Last Name",
      cell: (info) => info.getValue(),
    },
  ];
  
  
  
    const table = useReactTable({
      data: assignedToData,
      columns,
      getCoreRowModel: getCoreRowModel(),
    });

    const handlePageChange = (page) => {
     
      setPagination((prev) => ({
        ...prev,
        pageIndex: page,
      }));
    }
    
  

  return (
    <div className={style["container"]}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          backgroundColor: "#fff",
          borderRadius: "5px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{ fontWeight: 600, px: 2 }}
        >
          Inspection Service
        </Typography>

        <Box>
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            leadingIcon={R.ic_add_white}
            name={"Add New Inspection"}
            fontSize={"1rem"}
            linkTo={"/add-new-inspection"}
            onClick={() => {}}
          />
        </Box>
      </Box>

      <div className={style["filters-container"]}>
        <div className={style["filters-wrapper"]}>
          <div className={tableStyle["input-field"]}>
            {/* <input
              type={"text"}
              placeholder={"Request No"}
              id={"req_id"}
              onChange={(e) => debouncedChangeHandler(e)}
              style={{ backgroundImage: `url(${R.ic_search})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'left center',paddingLeft:"30px" }}
            /> */}
            <SearchBar
              id={"req_id"}
              placeholder={"Request No"}
              handleChange={(e) => debouncedChangeHandler(e)}
            />
          </div>
          <MultiSelect
            name="evaluator"
            handleChange={(selected) => {
              setEvaluator(selected?.value);
              handelPagination();
            }}
            items={evaltorList?.map((ele) => ({
              label: ele.firstName,
              value: ele.id,
            }))}
            selectedItems={evaluatorId}
            placeholder={"Evaluator"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />
          <MultiSelect
            name="inspectionQA"
            handleChange={(selected) => {
              setQaId(selected?.value);
              handelPagination();
            }}
            items={inspectionQaList?.map((ele) => ({
              label: ele.firstName,
              value: ele.id,
            }))}
            selectedItems={qaId}
            placeholder={"Inspection QA"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />

          <MultiSelect
            name="status"
            handleChange={(selected) => {
              console.log("selected", selected);
              setStatus(selected?.value);
              handelPagination();
            }}
            items={filterStatus?.map((ele) => ({
              label: ele.name,
              value: ele.id,
            }))}
            selectedItems={status}
            placeholder={"Status"}
            isSearchable={true}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["filterDropDown"]}
          />

          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={" Filter"}
            fontSize={"1rem"}
            // onClick={() => customer(filter)}
          />
          <PrimaryButton
            containerStyle={style["add-new-car-button-container-style"]}
            name={"Reset"}
            fontSize={"1rem"}
            onClick={() => {
              setFilter({
                searchString: "",
                leadRating: "",
                leadStatus: "",
                startDate: "",
                endDate: "",
                salesPoc: "",
                talecaller: "",
              });
              // customer();
            }}
          />
          <div className={style["advance-filter-button-container"]}>
            <a
              href="#"
              style={{
                fontSize: "0.8rem",
                textDecoration: "none",
                // color: isDropdownDisabled ? "gray" : "blue", // Gray for disabled state
                // cursor: isDropdownDisabled ? "not-allowed" : "pointer", // Not-allowed cursor when disabled
              }}
              onClick={(e) => {
                e.preventDefault(); // Prevent default behavior
                setAdvanceFilter(!advanceFilter); // Toggle only if not disabled
              }}
            >
              Advance Filter
            </a>
          </div>
        </div>

        <div
          style={{ height: advanceFilter ? "auto" : "0px", overflow: "hidden" }}
        >
          <div style={{ marginTop: "10px" }}>
            <div className={style["filters-wrapper"]}>
              <div className={tableStyle["input-field"]}>
                <select
                  onChange={(e) => {
                    setEvaluator(e?.target?.value);
                    handelPagination();
                  }}
                >
                  <option value="">Evaluator</option>
                  {userListDropdown?.map((ele, index) => {
                    return (
                      <option key={index} value={ele?.id}>
                        {ele.firstname}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={style["asssign-container-second"]}>
        <div className={style["inspected-container"]}>
          <p>Inspected at</p>
          <label className={style["radio-button-label"]}>
            <input
              type="radio"
              name="inspectionOption"
              value="inspected"
              checked={servcieDealerId === localStorage.getItem("dealerId")}
              onChange={(e) => handleFilter(e.target.value)}
            />
            Self
          </label>
          <label className={style["radio-button-label"]}>
            <input
              type="radio"
              name="inspectionOption"
              value="motorpedia"
              checked={servcieDealerId === MP_DEALER_ID}
              onChange={(e) => handleFilter(e.target.value)}
            />
            Motorpedia
          </label>
        </div>

        <div className={style["assign-wrapper-between"]}>
          <button
            className={style["assign-action-button"]}
            onClick={() => handleAssignedToBtn("Inspection QA")}
          >
            Assign Inspection QA
          </button>

          <button
            className={style["assign-action-button"]}
            onClick={() => handleAssignedToBtn("Evaluator")}
          >
            Assign Evaluator
          </button>

          <MultiSelect
            name="pageLimit"
            handleChange={(selected) => {
              handlePageLimitChange(selected?.value);
            }}
            items={pageLimitOptions}
            selectedItems={pagination?.pageSize}
            placeholder={"Page Limit"}
            isSearchable={false}
            containerStyle={style["selectable-button-container-style"]}
            selectClass={style["page-limit-dropdown"]}
          />
        </div>
      </div>

      <InspectionTable
        data={pickUpRequestList}
        // pagination={pagination}
        // setPagination={setPagination}
        // total={pickUpRequestList?.length || 0}
      />

      <Modal
        open={assignedModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            height: "500px",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {usersRoleName}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setAssignedModel(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <div
            className={`${style["vehicle-table-container"]} table-responsive-md table-responsive-sm`}
            style={{
              maxHeight: assignedToData.length > 5 ? "370px" : "auto",
              overflowY: assignedToData.length > 5 ? "auto" : "hidden",
              border: "1px solid #ddd",
            }}
          >
            <table
              className={`${style["table-wrapper"]} table table-border table-hover`}
              cellSpacing="5px"
            >
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup?.id}
                    className={style["table-header-wrapper"]}
                  >
                    {headerGroup?.headers?.map((header) => (
                      <th
                        key={header?.id}
                        className={style["table-header"]}
                        style={{ paddingBottom: "30px !important" }}
                      >
                        {flexRender(
                          header?.column?.columnDef?.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {assignedToData.length > 0 ? (
                  table?.getRowModel()?.rows?.map((row) => (
                    <tr key={row?.id} className={style["table-row"]}>
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id}>
                          {flexRender(
                            cell?.column?.columnDef?.cell,
                            cell?.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={columns.length}
                      className={style["no-data-row"]}
                    >
                      No data found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <Button
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
            onClick={() => handleAssignedTo(isAssignedTo)}
          >
            Submit
          </Button>
        </Box>
      </Modal>

      <Stack
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        marginTop={"20px"}
      >
        <Pagination
          count={total}
          page={pagination.pageIndex}
          onChange={(event, page) => handlePageChange(page)}
          variant="outlined"
          shape="rounded"
        />
      </Stack>
    </div>
  );
}
