import {
  CREATE_CAR_DETAIL,
  EDIT_CAR_DETAIL,
  GET_CAR_LIST,
  GET_DEALER_ADDRESS,
  GET_DEALER_USERS,
  GET_ALL_DEALERS,
  GET_ALL_SOURCE,
  GET_ALL_PURCHESAR,
} from "../types";
import { carApi } from "../../api/carApi";

export const postCarDetails = (payload) => {
  return carApi.postCarDetails(payload).then(
    (data) => {
      // Dispatch is no longer needed since you're bypassing Redux
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const SavePnLDetails = (payload) => {
  return carApi.savePnlData(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const GetPnlData = (data) => {
  return carApi.getDealerPnL(data).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

// export const getCarList = (params) => (dispatch, getState) => {
//   //   const { buyerLeadList } = getState().buyerLead;
//   return carApi.getCarList(params).then(
//     (response) => {
//       // console.log('response-carlist -->', response?.totalPageCount)
//       dispatch({
//         type: GET_CAR_LIST,
//         payload: {
//           data: response?.data,
//           currentPage : response?.currentPage,
//           totalPageCount : response?.totalPageCount,
//           totalCount : response?.totalCount,
//           hasNextPage : response?.hasNextPage
//           // // buyerLeadList && params?.page > 1
//           // //   ? [...buyerLeadList, ...response?.data]
//           // //   : response?.data,
//           // total: response?.total,
//         },
//       });
//       return Promise.resolve(response);
//     },
//     (error) => {
//       console.log(error);
//       return Promise.reject(error);
//     }
//   );
// };

export const getCarList = (params) => {
 // console.log("params nveneenenenenen ", params);
  return carApi.getCarList(params).then(
    (response) => {
      // console.log("checkRespnse checkRespnse checkRespnse", response.data);
      // console.log("paginationpagination ", response.pagination)
      return {
        type: GET_CAR_LIST,
        payload: {
          data: response?.data,
          currentPage: response?.pagination.cur_page,
          totalPageCount: response?.pagination.total_page,
          totalCount: response?.pagination.total,
          hasNextPage: response?.pagination.next_page,
          hasPrevPage: response?.pagination.prev_page,
        },
      };
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const getLeadCarList = (params) => {
 // console.log("params nveneenenenenen ", params);
  return carApi.getLeadCarList(params).then(
    (response) => {
      // console.log("checkRespnse checkRespnse checkRespnse", response.data);
      // console.log("paginationpagination ", response.pagination)
      return {
        type: GET_CAR_LIST,
        payload: {
          data: response?.data,
          
        },
      };
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const editCarDetails = async (payload) => {
  try {
    // Directly send the payload as a JSON object in the request body
    const response = await carApi.editCarDetails(payload);
    return Promise.resolve(response);
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchDealerAddress = () => async (dispatch) => {
  const dealer_id = localStorage.getItem("dealerId");
  try {
    const response = await carApi.dealerAddress({ dealer_id });
    dispatch({
      type: GET_DEALER_ADDRESS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchDealerUsers = () => async (dispatch) => {
  const dealer_id = localStorage.getItem("dealerId");
  try {
    const response = await carApi.dealerUsers({ dealer_id });
    dispatch({
      type: GET_DEALER_USERS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchAllDealers = () => async (dispatch) => {
  const dealer_id = localStorage.getItem("dealerId");
  try {
    const response = await carApi.allDealers({ dealer_id });
    dispatch({
      type: GET_ALL_DEALERS,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchSource = () => async (dispatch) => {
  const dealer_id = localStorage.getItem("dealerId");
  try {
    const response = await carApi.source({ dealer_id });
    dispatch({
      type: GET_ALL_SOURCE,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
};

export const fetchPurchesar = () => async (dispatch) => {
  const dealer_id = localStorage.getItem("dealerId");
  try {
    const response = await carApi.purchesar({ dealer_id });
    dispatch({
      type: GET_ALL_PURCHESAR,
      payload: response.data,
    });
  } catch (error) {
    console.log(error);
    return Promise.reject(error);
  }
}

export const fetchEnhancedInventryImages = (payload) => (dispatch) => {
  return carApi.fetchEnhancedInventryImages(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};

export const enhancedInventryImages = (payload) => (dispatch) => {
  return carApi.enhancedInventryImages(payload).then(
    (data) => {
      return Promise.resolve(data);
    },
    (error) => {
      console.log(error);
      return Promise.reject(error);
    }
  );
};
