import React, { useState, useEffect } from "react";
import style from "./testimonials.module.scss";
import PrimaryHeading from "../../components/primary-heading/PrimaryHeading";
import SearchBar from "../../components/search-bar/SearchBar";
import PrimaryButton from "../../components/buttons/PrimaryButton";
import { R } from "../../constants/resources";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import BlogTable from "./table/TestimonialList";
import { Box, Typography } from "@mui/material";
import { showToast } from "../../components/toaster/toaster";
import { ROLE_ID } from "../../config";
import usersApi from "../../api/usersApi";
import { UserDealerPermission } from "../../components/permissions";

function Testimonials() {
  const { checkStatusPermisssion, loading, error } = UserDealerPermission(20);

  const [permission, setPermission] = useState([]);
  const findData = permission?.filter(item => item?.slug === "testimonials");

  const [value, setValues] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleSearch = (e) => {
    let filterd = value;

    if (searchText) {
      filterd = filterd.filter((item) =>
        item.customer_name.toLowerCase().includes(searchText.toLowerCase())
      );
    }

    setFilterData(filterd);
  };

  useEffect(() => {
    handleSearch();
  }, [searchText, value]);



  const hasPermission = (action) => {
    return findData?.some((perm) => perm?.actions?.split(",").includes(action));
  };

  const checkStatus = (action) => {
    if (!checkStatusPermisssion) { return false }
    return checkStatusPermisssion.status === action
  }
  const handleAddClick = () => {
    if ((hasPermission("Add") || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active")))) {
      navigate("/create-testinomial");
    } else {
      showToast("error", "You do not have permission to add a testimonial.");
    }
  };

  const res = async () => {
    try {
      const res = await usersApi.permissionRoles();
      setPermission(res);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    res();
  }, []);


  return (
    <>
      {((hasPermission("Add") || hasPermission("Edit")) || (localStorage.getItem("roleId") == ROLE_ID && checkStatus("active"))) ? (
        <div className={style["container"]}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              backgroundColor: "#fff",
              borderRadius: "5px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",

              alignItems: "center",
              justifyContent: "space-between",
              padding: "15px 0",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: 600, px: 2 }}
            >
              Testimonials ({totalCount})
            </Typography>
          </Box>

          <div className={style["filters-container"]}>
            <div className={style["filters-wrapper"]}>
              <SearchBar
                placeholder={"Search Name or Phone"}
                handleChange={handleChange}
              />
            </div>
            <div className={style["action-buttons-wrapper"]}>
              <PrimaryButton
                containerStyle={style["add-new-car-button-container-style"]}
                leadingIcon={R.ic_add_white}
                name={"Add New Testimonial"}
                fontSize={"1rem"}
                onClick={handleAddClick}
              />
            </div>
          </div>

          <BlogTable
            value={filterData}
            hasPermission={hasPermission}
            setValues={setValues}
            totalCount={totalCount} // Pass total count to BlogTable
            setTotalCount={setTotalCount} // Pass setTotalCount to update from table
            checkStatus={checkStatus}
          />
        </div>
      ) : (
        <Typography
          sx={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "18px",
            color: "red",
            fontWeight: "bold",
          }}
        >
          You do not have permission to view this content.
        </Typography>

      )}
    </>
  );
}

export default Testimonials;
