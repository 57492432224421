import { dealerAppAuth } from "."


class userApi {
    getUsers(param) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app-auth/get-user",
                        param,
                        null,
                        token
                    )
                    resolve(res);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }


    getDealerRole(param) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app-auth/dealer-user-roles",
                        param,
                        null,
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }



    saveUsers(data) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "post",
                        "dealer-app-auth/add-user",
                        null,
                        data,
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }

    isActive(param) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "put",
                        "dealer-app-auth/isActive-status",
                        param,
                        {},
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }


    isActiveStatus(param) {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "put",
                        "dealer-app-auth/isActive-role",
                        param,
                        {},
                        token
                    )
                    resolve(res?.data);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }

    permissionRoles() {
        const token = localStorage.getItem("accessToken");
        return new Promise((resolve, reject) => {
            (async () => {
                try {
                    const res = await dealerAppAuth(
                        "get",
                        "dealer-app/dealer-module-permission",
                        null,
                        null,
                        token
                    )
                    resolve(res?.data?.result);
                } catch (error) {
                    reject(error);
                }
            })();
        })
    }



}
export default new userApi();
