import { combineReducers } from "redux";
import auth from "./auth/authReducer";
import buyerLead from "./buyerLead/buyerLeadReducer";
import sellerLead from "./sellerLead/sellerLeadReducer";
import car from "./car/carReducer";
import rtoCasesReducer from "./rto-services/rtoCases/rtoCasesReducer";

import inquireLeadReducers from "./inquiryLead/inquireLeadReducers";
import faqsReducers from "./faqs/faqsReducers";
import userReducers from "./users/userReducers";
import blogsReducers from "./blogs/blogsReducers";
import roleReducers from "./roles/roleReducers";
import documentReducers from "./documentUpload/documentReducers";
export default combineReducers({
  auth,
  buyerLead,
  sellerLead,
  car,
  rtoCasesReducer,
  inquireLeadReducers,
  faqsReducers,
  userReducers,
  blogsReducers,
  roleReducers,
  documentReducers
});
